import React from "react";
import { Link } from "react-router-dom";

import StepsProcpectiveStudentMagystracyMenu from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/StepsProcpectiveStudentMagystracyMenu";

// import IndividualAchievements from "../../components/ComponentsProspeciveStudentPage/IndividualAchievements";
import KCPMagistracy from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/KCPMagistracy";
import TuitionFeesMagistracy from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/TuitionFeesMagistracy";
import EntranceTestsMagistracy from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/EntranceTestsMagistracy";
// import Scholarship from "../../components/ComponentsProspeciveStudentPage/Scholarship";
// import EnergyLevels from "../../components/ComponentsProspeciveStudentPage/EnergyLevels";
// import GrantRectora from "../../components/ComponentsProspeciveStudentPage/GrantRectora";
import PaymentMethods from "../../components/ComponentsProspeciveStudentPage/PaymentMethods";
import CalendarMagistracy from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/CalendarMagistracy";
// import Dormitory from "../../components/ComponentsProspeciveStudentPage/Dormitory";
import EducationalLoan from "../../components/ComponentsProspeciveStudentPage/EducationalLoan";

export const stepsProcpectiveStudentMagistracy = [
  {
    title: "Календарь",
    anchor: "select-1",
    text: (
      <div className="step-procpective-student__padding">
        <CalendarMagistracy />
      </div>
    ),
  },
  {
    title: "Этапы поступления",
    anchor: "select-2",
    text: (
      <div className="step-procpective-student__padding">
        <div className="step-procpective-student__row">
          <div className="step-procpective-student__column">
            <span className="step-procpective-student__column-title">
              Этапы поступления
            </span>
            <StepsProcpectiveStudentMagystracyMenu />
          </div>
          <div className="step-procpective-student__column">
            <span className="step-procpective-student__column-title">
              Полезные ссылки
            </span>
            <div className="question-content__item-link-block">
              <Link to="/" className="question-content__link-button">
                Список образовательных программ
              </Link>
              <Link to="/" className="question-content__link-button">
                Правила приема
              </Link>
              <Link to="/" className="question-content__link-button">
                Вступительные испытания
              </Link>
              <Link to="/" className="question-content__link-button">
                Подача согласия на зачисление
              </Link>
              <Link to="/contacts" className="question-content__link-button">
                Контакты
              </Link>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   title: "Учет индивидуальных достижений",
  //   anchor: "select-3",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <IndividualAchievements />
  //     </div>
  //   ),
  // },
  {
    title: "Контрольные цифры приема",
    anchor: "select-3",
    text: (
      <div className="step-procpective-student__padding">
        <KCPMagistracy />
      </div>
    ),
  },
  {
    title: "Стоимость обучения",
    anchor: "select-4",
    text: (
      <div className="step-procpective-student__padding">
        <TuitionFeesMagistracy />
      </div>
    ),
  },
  // {
  //   title: "Заселение в общежитие",
  //   anchor: "select-5",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <Dormitory />
  //     </div>
  //   ),
  // },
  {
    title: "Вступительные испытания",
    anchor: "select-6",
    text: (
      <div className="step-procpective-student__padding">
        <EntranceTestsMagistracy />
      </div>
    ),
  },
  // {
  //   title: "Стипендия (бюджет и платная основа)",
  //   anchor: "select-7",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <Scholarship />
  //     </div>
  //   ),
  // },
  // {
  //   title: "Бонусы для высокобальников",
  //   anchor: "select-8",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <EnergyLevels />
  //     </div>
  //   ),
  // },
  // {
  //   title: "Грант Pектора на обучение",
  //   anchor: "select-9",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <GrantRectora />
  //     </div>
  //   ),
  // },
  {
    title: "Образовательный кредит",
    anchor: "select-10",
    text: (
      <div className="step-procpective-student__padding">
        <EducationalLoan />
      </div>
    ),
  },
  {
    title: "Способы оплаты",
    anchor: "select-11",
    text: (
      <div className="step-procpective-student__padding">
        <PaymentMethods />
      </div>
    ),
  },
];
