import React from "react";
// import { Link } from 'react-router-dom';

import Container from "../../Container";

import man from "../../../assets/images/MainPage/CategoryAbiturientovMan.png";

// import { menuItems } from "../../Constants";

import "./CategoryAbiturientov.css";

function CategoryAbiturientov() {

    return (
        <React.Fragment>
            <Container>
                <div className="category-abiturientov one">
                    <div className="category-abiturientov__img">
                        <img src={man} alt="" />
                    </div>
                    <span className="category-abiturientov__title">Категории поступающих</span>
                    <div className="category-abiturientov__text-block">
                        <div className="category-abiturientov__text-column">
                            <a href="/after-collage" className="category-abiturientov__text">Поступление после колледжа</a>
                            <a href="/categories-of-applicants" className="category-abiturientov__text three">Поступление после школы</a>
                            <a href="/without-introductory" className="category-abiturientov__text">Без вступительных испытаний</a>
                        </div>
                        <div className="category-abiturientov__text-column">
                            <a href="/special-admission-conditions" className="category-abiturientov__text">Особые условия поступления</a>
                            <a href="/pay-study" className="category-abiturientov__text two">Платное обучение</a>
                            <a href="/reception-of-foreign-citizens" className="category-abiturientov__text">Прием иностранных граждан</a>
                        </div>
                    </div>
                </div>
            </Container>

            {/* ----------------------------------от 1040px----------------------------------- */}


            <div className="category-abiturientov two">
                <div className="category-abiturientov__img">
                    <img src={man} alt="" />
                </div>
                <span className="category-abiturientov__title">Категории поступающих</span>
                <div className="category-abiturientov__text-block">
                    <div className="category-abiturientov__text-column">
                        <a href="/after-collage" className="category-abiturientov__text">Поступление после колледжа</a>
                        <a href="/categories-of-applicants" className="category-abiturientov__text three">Поступление после школы</a>
                        <a href="/without-introductory" className="category-abiturientov__text three">Без вступительных испытаний</a>
                    </div>
                    <div className="category-abiturientov__text-column">
                        <a href="/special-admission-conditions" className="category-abiturientov__text">Особые условия поступления</a>
                        <a href="/pay-study" className="category-abiturientov__text two">Платное обучение</a>
                        <a href="/reception-of-foreign-citizens" className="category-abiturientov__text">Прием иностранных граждан</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CategoryAbiturientov;
