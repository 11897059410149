import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";

import Container from "../../components/Container";

import "./PreparatoryCourses.css";

export default function PreparatoryCourses() {
    return (
        <div className="preparatory-courses">
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top">
                    <span className="etap-postupleniya__title">Подготовительные курсы</span>
                </div>
                <div className="preparatory-courses__info-block">
                    <div className="preparatory-courses__text-block">
                        <span className="preparatory-courses__text-block-title">Курсы КГЭУ - это</span>
                        <span className="preparatory-courses__text-block-text">1. Углубленная подготовка по предметам:<br />
                            • математика<br />
                            • русский язык<br />
                            • физика<br />
                            • обществознание<br />
                            2. Малочисленные группы<br />
                            3. Высококвалифицированные преподаватели<br />
                            4. Эффективные методики преподавания<br />
                            5. Возможность прохождения пробного ОГЭ/ЕГЭ<br />
                            6. Знакомство с ВУЗом<br />
                            7. Доступная цена - высокое качество</span>
                    </div>
                    <div className="preparatory-courses__text-block">
                        <span className="preparatory-courses__text-block-subtitle">Оформление и прием документов</span>
                        <span className="preparatory-courses__text-block-text">ул. Красносельсекая, д.51, корпус "В", <br />
                            каб. В-111, В-115 <br />
                            пн-пт: с 09:00 до 17:00.</span>
                        <span className="preparatory-courses__text-block-subtitle">Контакты</span>
                        <span className="preparatory-courses__text-block-text">Эл.почта:
                            <span className="preparatory-courses__text-block-text-blue">cdo@kgeu.ru</span><br />
                            Телефоны: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-24</span></span>
                    </div>
                </div>
                <div className="preparatory-courses__main">
                    <div className="preparatory-courses__main-block">
                        <span className="preparatory-courses__main-block-title">Курс по подготовке 9 классов к ОГЭ</span>
                        <div className="preparatory-courses__main-blocktext">
                            {/* <span className="preparatory-courses__main-block-text">7-месячные подготовительные курсы</span> */}
                            <span className="preparatory-courses__main-block-subtitle">7 200 руб.</span>
                            <span className="preparatory-courses__main-block-text">за один предмет, за весь период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">сентябрь - октрябрь</span>
                            <span className="preparatory-courses__main-block-text">Прием документов</span>
                            <span className="preparatory-courses__text-block-subtitle">ноябрь - апрель</span>
                            <span className="preparatory-courses__main-block-text">Период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">3 раза в неделю по 3 академических часа</span>
                            <span className="preparatory-courses__main-block-text">Занятия</span>
                            <span className="preparatory-courses__text-block-subtitle">Математика   Русский язык   Физика/Обществознание</span>
                            <span className="preparatory-courses__main-block-text">Предметы</span>
                            <span className="preparatory-courses__text-block-subtitle">Заявление   Договор   Квитанция</span>
                            <span className="preparatory-courses__main-block-text">Бланки для оформления</span>
                        </div>
                        <Link to="//docs.google.com/forms/d/e/1FAIpQLSehQixX3qCfanuah1VJFsad0EIwXilTdryoIWyOFAf95ouOwQ/viewform" target="_blank" className="preparatory-courses__main-block-button">Записаться на курс</Link>
                    </div>
                    <div className="preparatory-courses__main-block">
                        <span className="preparatory-courses__main-block-title">Курс по подготовке 10 классов к ЕГЭ</span>
                        <div className="preparatory-courses__main-blocktext">
                            {/* <span className="preparatory-courses__main-block-text">7-месячные подготовительные курсы</span> */}
                            <span className="preparatory-courses__main-block-subtitle">7 200 руб.</span>
                            <span className="preparatory-courses__main-block-text">за один предмет, за весь период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">сентябрь - октрябрь</span>
                            <span className="preparatory-courses__main-block-text">Прием документов</span>
                            <span className="preparatory-courses__text-block-subtitle">ноябрь - апрель</span>
                            <span className="preparatory-courses__main-block-text">Период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">1 раз в неделю по субботам по 6 академических часов</span>
                            <span className="preparatory-courses__main-block-text">Занятия</span>
                            <span className="preparatory-courses__text-block-subtitle">Математика   Физика</span>
                            <span className="preparatory-courses__main-block-text">Предметы</span>
                            <span className="preparatory-courses__text-block-subtitle">Заявление   Договор   Квитанция</span>
                            <span className="preparatory-courses__main-block-text">Бланки для оформления</span>
                        </div>
                        <Link to="//docs.google.com/forms/d/e/1FAIpQLSehQixX3qCfanuah1VJFsad0EIwXilTdryoIWyOFAf95ouOwQ/viewform" target="_blank" className="preparatory-courses__main-block-button">Записаться на курс</Link>
                    </div>
                </div>
                <div className="preparatory-courses__main">
                    <div className="preparatory-courses__main-block">
                        <span className="preparatory-courses__main-block-title">Курс по подготовке 11 классов к ЕГЭ</span>
                        <div className="preparatory-courses__main-blocktext">
                            <span className="preparatory-courses__main-block-text">7-месячные подготовительные курсы</span>
                            <span className="preparatory-courses__main-block-subtitle">8 400 руб.</span>
                            <span className="preparatory-courses__main-block-text">за один предмет, за весь период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">сентябрь - октрябрь</span>
                            <span className="preparatory-courses__main-block-text">Прием документов</span>
                            <span className="preparatory-courses__text-block-subtitle">октябрь - апрель</span>
                            <span className="preparatory-courses__main-block-text">Период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">3 раза в неделю в будние дни по 3 академических часа на каждый предмет</span>
                            <span className="preparatory-courses__main-block-text">Занятия</span>
                            <span className="preparatory-courses__text-block-subtitle">Математика   Русский язык   Физика</span>
                            <span className="preparatory-courses__main-block-text">Предметы</span>
                            <span className="preparatory-courses__text-block-subtitle">Заявление   Договор   Квитанция</span>
                            <span className="preparatory-courses__main-block-text">Бланки для оформления</span>
                        </div>
                        <Link to="//docs.google.com/forms/d/e/1FAIpQLSehQixX3qCfanuah1VJFsad0EIwXilTdryoIWyOFAf95ouOwQ/viewform" target="_blank" className="preparatory-courses__main-block-button">Записаться на курс</Link>
                    </div>
                    <div className="preparatory-courses__main-block">
                        <span className="preparatory-courses__main-block-title">Курс по подготовке 11 классов к ЕГЭ</span>
                        <div className="preparatory-courses__main-blocktext">
                            <span className="preparatory-courses__main-block-text">6-месячные подготовительные курсы</span>
                            <span className="preparatory-courses__main-block-subtitle">7 200 руб.</span>
                            <span className="preparatory-courses__main-block-text">за один предмет, за весь период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">сентябрь - октрябрь</span>
                            <span className="preparatory-courses__main-block-text">Прием документов</span>
                            <span className="preparatory-courses__text-block-subtitle">ноябрь - апрель</span>
                            <span className="preparatory-courses__main-block-text">Период обучения</span>
                            <span className="preparatory-courses__text-block-subtitle">3 раза в неделю в будние дни по 3 академических часа на каждый предмет</span>
                            <span className="preparatory-courses__main-block-text">Занятия</span>
                            <span className="preparatory-courses__text-block-subtitle">Математика   Русский язык   Физика</span>
                            <span className="preparatory-courses__main-block-text">Предметы</span>
                            <span className="preparatory-courses__text-block-subtitle">Заявление   Договор   Квитанция</span>
                            <span className="preparatory-courses__main-block-text">Бланки для оформления</span>
                        </div>
                        <Link to="//docs.google.com/forms/d/e/1FAIpQLSehQixX3qCfanuah1VJFsad0EIwXilTdryoIWyOFAf95ouOwQ/viewform" target="_blank" className="preparatory-courses__main-block-button">Записаться на курс</Link>
                    </div>
                </div>
            </Container>

            <Footer />
        </div>

    );
}

