import React from "react";

import "./TuitionFees.css";

export default function TuitionFees() {
    return (
        <div className="tuition-fees">
            <span className="individual-achivments__title">Стоимость обучения на 2024/25 уч. год</span>
            <span className="individual-achivments__title">Очная форма обучения</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Экономические и гуманитарные направления</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">38.03.01 «Экономика»</span>
                    <span className="tuition-fees__text">38.03.02 «Менеджмент»</span>
                    <span className="tuition-fees__text">39.03.01 «Социология»</span>
                    <span className="tuition-fees__text">42.03.01 «Реклама и связи с общественностью»</span>
                </div>
                <span className="tuition-fees__text-block-summa">143 930 руб.</span>
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Технические направления</span>
            </div>
            <div className="tuition-fees__text-block sub">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">09.03.01 «Информатика и вычислительная техника»</span>
                    <span className="tuition-fees__text">09.03.03 «Прикладная информатика»</span>
                    <span className="tuition-fees__text">11.03.04 «Электроника и наноэлектроника»</span>
                    <span className="tuition-fees__text">12.03.01 «Приборостроение»</span>
                    <span className="tuition-fees__text">13.03.01 «Теплоэнергетика и теплотехника»</span>
                    <span className="tuition-fees__text">13.03.02 «Электроэнергетика и электротехника»</span>
                    <span className="tuition-fees__text">13.03.03 «Энергетическое машиностроение»</span>
                    <span className="tuition-fees__text">15.03.04 «Автоматизация технологических процессов и производств»</span>
                    <span className="tuition-fees__text">15.03.06 «Мехатроника и робототехника»</span>
                    {/* <span className="tuition-fees__text">18.03.01 «Химическая технология»</span> */}
                    <span className="tuition-fees__text">20.03.01 «Техносферная безопасность»</span>
                    <span className="tuition-fees__text">22.03.01 «Материаловедение и технологии материалов»</span>
                    <span className="tuition-fees__text">27.03.04 «Управление в технических системах»</span>
                    <span className="tuition-fees__text">35.03.08 «Водные биоресурсы и аквакультура»</span>
                </div>
                <span className="tuition-fees__text-block-summa">163 160 руб.</span>
            </div>
            <div className="tuition-fees__text-block sub">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">01.03.04 «Прикладная математика»</span>
                </div>
                <span className="tuition-fees__text-block-summa">143 930 руб.</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">14.05.02 «Атомные станции: Проектирование, эксплуатация и инжиниринг»</span>
                </div>
                <span className="tuition-fees__text-block-summa">194 540 руб.</span>
            </div>
            <span className="individual-achivments__title two">Заочная форма обучения</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Гуманитарные направления</span>
                <span className="tuition-fees__text-block-summa">55 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block one">
                <span className="tuition-fees__title">Экономические направления</span>
                <span className="tuition-fees__text-block-summa">55 800 руб.</span>
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Технические и биологические направления</span>
                <span className="tuition-fees__text-block-summa">63 600 руб.</span>
            </div>
        </div>
    );
}
