import React from "react";

import "./OtherKvot.css";

export default function OtherKvot() {
  return (
    <div className="special-admission-conditional">
      <span className="special-admission-conditional__title">
        Отдельная квота
      </span>
      <br />
      <div className="special-admission-conditional__text-block">
        <span className="special-admission-conditional__text">
          В соответствии с частью 5.1 статьи 71 Федерального закона № 273-ФЗ
          право на прием на обучение на места в пределах отдельной квоты имеют:
        </span>
        <span className="special-admission-conditional__teext">
          1) Герои Российской Федерации, лица, награжденные тремя орденами
          Мужества;
        </span>
        <span className="special-admission-conditional__teext">
          2) дети лиц, принимавших участие в специальной военной операции на
          территориях Украины, Донецкой Народной Республики, Луганской Народной
          Республики, Запорожской области и Херсонской области (далее - лица,
          принимавшие участие в специальной военной операции):
        </span>
        <span className="special-admission-conditional__teext sub">
          • военнослужащих (в том числе проходивших военную службу в период
          мобилизации, действия военного положения или по контракту,
          заключенному в соответствии с пунктом 7 статьи 38 Федерального закона
          от 28 марта 1998 г. № 53-ФЗ «О воинской обязанности и военной
          службе»);
        </span>
        <span className="special-admission-conditional__teext sub">
          • лиц, заключивших контракт о добровольном содействии в выполнении
          задач, возложенных на Вооруженные Силы Российской Федерации;
        </span>
        <span className="special-admission-conditional__teext sub">
          • сотрудников федеральных органов исполнительной власти и федеральных
          государственных органов, в которых федеральным законом предусмотрена
          военная служба, сотрудников органов внутренних дел Российской
          Федерации, сотрудников уголовно-исполнительной системы Российской
          Федерации;
        </span>
        <span className="special-admission-conditional__teext">
          3) дети военнослужащих, сотрудников федеральных органов исполнительной
          власти и федеральных государственных органов, в которых федеральным
          законом предусмотрена военная служба, сотрудников органов внутренних
          дел Российской Федерации, направленных в другие государства органами
          государственной власти Российской Федерации и принимавших участие в
          боевых действиях при исполнении служебных обязанностей в этих
          государствах (далее - военнослужащие, сотрудники, направленные в
          другие государства).
        </span>

        <span className="special-admission-conditional__text">
          На места в пределах отдельной квоты принимаются:
        </span>
        <span className="special-admission-conditional__teext">
          1) без проведения вступительных испытаний:
        </span>
        <span className="special-admission-conditional__teext sub">
          • Герои Российской Федерации, лица, награжденные тремя орденами
          Мужества;
        </span>
        <span className="special-admission-conditional__teext sub">
          • дети лиц, принимавших участие в специальной военной операции, дети
          военнослужащих, сотрудников, направленных в другие государства, если
          указанные лица, военнослужащие, сотрудники погибли или получили увечье
          (ранение, травму, контузию) либо заболевание при исполнении
          обязанностей военной службы (служебных обязанностей) в ходе
          специальной военной операции (боевых действий на территориях
          иностранных государств) либо удостоены звания Героя Российской
          Федерации или награждены тремя орденами Мужества;
        </span>
        <span className="special-admission-conditional__teext">
          2) по результатам ЕГЭ или вступительных испытаний, проводимых
          Финансовым университетом самостоятельно, по выбору поступающих - дети
          лиц, принимавших участие в специальной военной операции, дети
          военнослужащих, сотрудников, направленных в другие государства, за
          исключением детей, указанных в абзаце третьем подпункта 1 настоящего
          пункта.
        </span>

        <span className="special-admission-conditional__text">
          Поступающий на места в пределах отдельной квоты:
        </span>
        <span className="special-admission-conditional__teext">
          1) указывает в заявлении о приеме, что он является одним из следующих
          лиц:
        </span>
        <span className="special-admission-conditional__teext sub">
          • Героем Российской Федерации;
        </span>
        <span className="special-admission-conditional__teext sub">
          • лицом, награжденным тремя орденами Мужества;
        </span>
        <span className="special-admission-conditional__teext sub">
          • сыном или дочерью лица, принимавшего участие в специальной военной
          операции, или военнослужащего, сотрудника, направленного в другое
          государство;
        </span>
        <span className="special-admission-conditional__teext">
          2) не позднее дня завершения выставления отметок об оригинале и приема
          оригинала на этапе приоритетного зачисления представляет в Финансовый
          университет оригинал документа, подтверждающего отнесение поступающего
          к числу лиц, указанных в подпункте 1 настоящего пункта.
        </span>
        <br />
        <a href="//kgeu.ru/Document/GetDocument/aa78f1f8-a98a-41c7-a299-5f62f6edbc2a" className="special-admission-conditional__link" target="_blank">• Методические рекомендации о приеме в рамках отдельной квоты</a><br />
        <a href="//kgeu.ru/Document/GetDocument/8c35e520-4534-4da9-8b71-61bbb2e22f7b" className="special-admission-conditional__link" target="_blank">• Указ Президента РФ № 268 в ред. от 05.08.2022</a><br />
        <a href="//kgeu.ru/Document/GetDocument/b1d3ed8a-0b0b-4fb5-b1a5-5a87f4d0b724" className="special-admission-conditional__link" target="_blank">• Форма справки, подтверждающей право гражданина на прием в пределах отдельной квоты</a>
      </div>
    </div>
  );
}
