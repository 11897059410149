import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionMashinostr from "../../../../components/ComponentsUndergraduatePage/ITE/ITEQuestion/QuestionMashinostr";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/plotnikov.jpg";
import doc from "../../../../assets/documents/EducationalPrograms/digitalTechnology.pdf";

import "./DigitalTechnologiesTeacherPage.css";

export default function DigitalTechnologiesTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher ivt">
                    <span className="teacher__title">Цифровые технологии машиностроения
                    </span>
                    <div className="teacher__main-block chich">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                        <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Плотников Владимир Витальевич</span>
                            <span className="teacher__text">к.т.н., доцент кафедры «Автоматизация технологических процессов и производств» (АТПП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">carpenter_wowa@mauk.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-61</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань,</span>
                            <span className="teacher__text">ул. Красносельская, 51</span>
                            <span className="teacher__text">корпус Д</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <Link to=""  className="teacher__button">Учебный план </Link>
                                
                            </div>
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span> */}
                    {/* <QuestionMashinostr /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}