import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Burger.css";

function Burger() {
    const { modal } = useSelector((state) => state);
    const dispatch = useDispatch();
    const burger = useRef(null);

    const handleClickBurger = () => {
        if (modal && modal.visible) {
            burger.current.classList.add("burger--active");
            dispatch({ type: "hideModal" });
        } else {
            burger.current.classList.remove("burger--active");
            dispatch({ type: "showModal" });
        }
    };

    return (
        <button
            className={`burger ${modal && modal.visible && "burger--active"}`}
            ref={burger}
            onClick={handleClickBurger}
        >
            <span className="burger__line"></span>
            <span className="burger__line"></span>
        </button>
    );
}

export default Burger;
