import React from "react";

import "./ListOfDocuments.css";

export default function ListOfDocuments() {

    return (
        <div className="list-of-documents">
            <span className="individual-achivments__title">Перечень документов</span>
            <div className="list-of-documents__block">
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Документ, удостоверяющий личность</span>
                    <span className="list-of-documents__block-text"> — копия паспорта (1 - 2 стр. + прописка)</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Документ об образовании установленного образца</span>
                    <span className="list-of-documents__block-text"> — копия/оригинал аттестата, диплома СПО, НПО и др. (вкладыш и приложение с оценками полностью)</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">4 фотографии</span>
                    <span className="list-of-documents__block-text"> — фотографии в любом цвете, формат 3х4</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Медицинская справка 086-у</span>
                    <span className="list-of-documents__block-text"> — заверенная копия/оригинал справки
                        <span className="list-of-documents__block-subtext"><br />на направления: <br />13.04.01 Теплоэнергетика и теплотехника <br />
                                                                            13.04.02 Электроэнергетика и электротехника<br /> 
                                                                            14.05.02 Атомные станции: Проектирование, эксплуатация и инжиниринг</span>
                    </span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Документы, подтверждающие индивидуальные достижения</span>
                    <span className="list-of-documents__block-text"> — копия/оригинал дипломов, грамот, удостоверений и др. (при наличии)</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Документы, подтверждающие льготу</span>
                    <span className="list-of-documents__block-text"> — оригинал справки при поступлении по особой квоте</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">СНИЛС</span>
                    <span className="list-of-documents__block-text"> — копияя/оригинал СНИЛС</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Документ о смене ФИО</span>
                    <span className="list-of-documents__block-text"> — оригинал/копия документа (при наличии)</span>
                </div>
            </div>
        </div>
    );
}