import React from "react";
import { Link } from "react-router-dom";

import "./SpecialAdmissionConditions.css";

export default function SpecialAdmissionConditions() {

    return (
        <div className="special-admission-conditional">
            <span className="special-admission-conditional__title">Без вступительных испытаний</span>
            <span className="special-admission-conditional__teext last">(победители и призеры олимпиад школьников)</span>
            <div className="special-admission-conditional__text-block">
                <span className="special-admission-conditional__teext">Победителям и призерам олимпиад школьников, с соответствующим профилем олимпиады,
                    проводимых в порядке, устанавливаемом федеральным органом исполнительной власти, предоставляются следующие особые права при приеме на обучение:</span>
                <span className="special-admission-conditional__text">• прием без вступительных испытаний на обучение по программам бакалавриата 
                по направлениям подготовки, соответствующим профилю олимпиады школьников*;</span>
                <span className="special-admission-conditional__text last">• быть приравненными к лицам, набравшим максимальное количество баллов ЕГЭ (100 баллов) 
                по общеобразовательному предмету, соответствующему профилю олимпиады школьников.</span>

                <span className="special-admission-conditional__text">Призёры олимпиад, поступающие в течение 4 лет, следующих за годом проведения 
                имеют право на приём без вступительных испытаний, при условии:</span>
                <span className="special-admission-conditional__teext">• совпадения общеобразовательного предмета и профиля олимпиады с профильными 
                вступительными испытаниями;</span>
                <span className="special-admission-conditional__teext">• совпадения направления подготовки и профиля олимпиады;</span>
                <span className="special-admission-conditional__teext">• балла ЕГЭ по профильному предмету олимпиады не менее 75;</span>
                <span className="special-admission-conditional__teext last">• предоставлении в Приёмную комиссию распечатанной формы диплома 
                (с qr кодом и кодом подтверждения) с <Link to="//diploma.rsr-olymp.ru/2017/" className="special-admission-conditional__link" target="_blank">сайта Российского совета олимпиад школьников</Link>.</span>
                <span className="special-admission-conditional__teext last">В этом году можно использовать результаты олимпиад, проведенных в 2019-2023 годах.</span>
                <span className="special-admission-conditional__teext last">В КГЭУ победителям и призерам олимпиад школьников в течении 2 лет выплачивается стипендия в размере 30000 руб.</span>
                <span className="special-admission-conditional__teext">Перечень олимпиад <Link to="https://kgeu.ru/Document/GetDocument/9fc0ad48-2d2e-47fa-b09c-73886b47271e" className="special-admission-conditional__link" target="_blank">ЗДЕСЬ</Link></span>
            </div>
        </div>
    );
}
