import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/golenishev.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/nano.pdf";


import img1 from "../../../../assets/images/New/MagIEMCTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/MagIEMCTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagIEMCTeacher/info-block/info_block_img3.png";

import img_company2 from "../../../../assets/images/New/MagIEMCTeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagIEMCTeacher/companies/img_company3.png";
import img_company1 from "../../../../assets/images/New/MagIEMCTeacher/companies/img_company1.png";

import "./PromishmTeacherPage.css";



export default function PromishmTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher iemc">
                    <span className="teacher__title">Промышленная электроника и микропроцессорная техника
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Голенищев-Кутузов Александр Вадимович</span>
                            <span className="teacher__text">к.ф-м.н., доцент кафедры <br /> «Промышленная электроника и светотехника» (ПЭС)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">alex.kutuzov@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-42-78</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51 каб. А-407</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            
                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                            Программа направлена на подготовку высококвалифицированных специалистов, умеющих применять математическое и компьютерное моделирование и проектирование, конструирование электронных узлов и блоков, технологию их производства,использование и эксплуатацию материалов в области современной электронной, промышленной, радиоэлектронной, компьютерной и микропроцессорной техники.
                            </span>
                        </div>
                    </div>
                    
                    
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Микропроцессорная обработка данных в устройствах электроники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Организация и управление проектной деятельностью в области электроники и наноэлектроники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">САПР в электронике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Актуальные проблемы современной электроники и наноэлектроники</span>
                                </div>

                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Перспективные материалы электроники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Проектирование встраиваемых систем</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Принципы построения приборов и узлов полупроводниковых лазеров</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Проектирование и разработка интеллектуальных силовых модулей</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Методы и средства контроля параметров материалов электроники и наноэлектроники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Физические принципы неразрушающего контроля</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Инженером-электронщиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Конструктором электронного оборудования</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Конструктором печатных плат</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}