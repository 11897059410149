import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionAcva from "../../../../components/ComponentsUndergraduatePage/ITE/ITEQuestion/QuestionAcva";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/kalaida.jpg";
import doc from "../../../../assets/documents/EducationalPrograms/akvaKul.pdf";

import img1 from "../../../../assets/images/New/AquaTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/AquaTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/AquaTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/AquaTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/AquaTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/AquaTeacher/large-frame-bg2.svg";
import "./AcvaTeacherPage.css";

export default function AcvaTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher aqua">
                    <span className="teacher__title">Аквакультура
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                        <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Калайда Марина Львовна</span>
                            <span className="teacher__text">д.б.н., профессор кафедры<br /> «Водные биоресурсы и аквакультура» (ВБА)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">kalayda4@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-43-53</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. Д-516</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <Link to=""  className="teacher__button">Учебный план </Link>
                                
                            </div>
                        </div>

                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Bio" >Б</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math">М</span>
                                </div>
                                <div className="subject">
                                    <span className="Chem">Х</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="Geo">Г</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">162</span>
                        </div>
                    </div>

                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Биологию, зоологию,<br /> ботанику водных растений</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Планирование деятельности<br /> в области водных биоресурсов<br /> и аквакультуры</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Ихтиологию, сырьевую базу<br /> рыбной промышленности</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Экологическую и<br /> рыбохозяйственную экспертизу</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Биологические основы рыбоводства</span>
                                </div>
                            </div>
                        </div>  

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Инспектором рыбоохраны</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Инженером-рыбоводом</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Гидробиологом</span>
                                </div> 
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img4}/></div>
                                    <span className="text-line">Ихтипатологом</span>
                                </div>
                            </div>
                            
                        </div>  
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">  
                            <img src={bg_left}/>
                            <img src={bg_right}/>
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Применение современных<br /> систем компьютерной<br /> математики, технологий<br /> конечно-элементарного анализа</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Применение наукоемких компьютерных технологий</span> 
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Конструирование газовых<br /> турбин</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Применение современных<br /> CAD/CAM технологий</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Решение ряда теплогидравлических задач<br />
                                     применительно к отдельным элементам<br /> электроустановок</span> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionAcva /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}