import React from "react";

import UndegraduateICTEInfo from "../../components/ComponentsUndergraduatePage/UndegraduateICTEInfo";
import ListOfDocuments from "../../components/ComponentsUndergraduatePage/ListOfDocuments";
import PassingPoints from "../../components/ComponentsUndergraduatePage/PassingPoints";
import Calendar from "../../components/ComponentsProspeciveStudentPage/Calendar";
import Scholarship from "../../components/ComponentsProspeciveStudentPage/Scholarship";
import IndividualAchievements from "../../components/ComponentsProspeciveStudentPage/IndividualAchievements";


export const stepsUndergraduateICTE = [
	{
		title: "Направления",
		id: "speciality",
		anchor: "select-1",
		text: (
			<div className="step-procpective-student__padding">
				<div className="undegraduate-ICTE-info">

					<div className="entrance-tests">
						<span className="individual-achivments__title">
							Контрольные цифры приема
						</span>
						<div className="individual-achivments__top-block-subjects">
							<span className="individual-achivments__top-subjects-text">
								* — очно-заочная форма
							</span>
						</div>
						<br />
						<table className="scroll-table facultets">
							<tr className="undegraduate-ICTE-info">
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Код и наименование направления
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Бюджет очная <br />
										форма
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Бюджет заочная <br />
										форма
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Платные места <br />
										очная форма
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Платные места <br />
										заочная форма
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											01.03.04
										</span>
										<a
											href="/applied-mathematics"
											className="acceptance-checksum__table-text"
										>
											Прикладная математика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										74
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										15
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											09.03.01
										</span>
										<a
											href="/computer-science-and-engineering"
											className="acceptance-checksum__table-text"
										>
											Информатика и вычислительная техника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										120
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										80
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											09.03.03
										</span>
										<a
											href="/applied-computer-science"
											className="acceptance-checksum__table-text"
										>
											Прикладная информатика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										75
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										15
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										30
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											11.03.04
										</span>
										<a
											href="/electronics-and-nanoelectronics"
											className="acceptance-checksum__table-text"
										>
											Электроника и наноэлектроника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										134
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>

							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											12.03.01
										</span>
										<a
											href="/instrumentation"
											className="acceptance-checksum__table-text"
										>
											Приборостроение
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>

							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.01
										</span>
										<a
											href="/heat-power-engineering-and-heat-engineering"
											className="acceptance-checksum__table-text"
										>
											Теплоэнергетика и теплотехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										265
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										50
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										15
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										60
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.02
										</span>
										<a
											href="/electric-power-and-electrical-engineering"
											className="acceptance-checksum__table-text"
										>
											Электроэнергетика и электротехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										427
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										70
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										250
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.03
										</span>
										<a
											href="/energy-power-engineering"
											className="acceptance-checksum__table-text"
										>
											Энергетическое машиностроение
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											14.05.02
										</span>
										<a
											href="/nuclear-power-plants-design-operation"
											className="acceptance-checksum__table-text"
										>
											Атомные станции: проектирование, эксплуатация <br />и
											инжиниринг
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										50
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							< tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two" >
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											15.03.04
										</span>
										<a
											href="/automation-of-technological-processes-and-productions"
											className="acceptance-checksum__table-text"
										>
											Автоматизация
											технологических процессов и производств
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										60
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										10
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										100
									</span>
								</div>
							</tr >
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											15.03.06
										</span>
										<a
											href="/mechatronics-and-robotics"
											className="acceptance-checksum__table-text"
										>
											Мехатроника и робототехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										51
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											20.03.01
										</span>
										<a
											href="/technosphere-safety"
											className="acceptance-checksum__table-text"
										>
											Техносферная безопасность
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										85
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											22.03.01
										</span>
										<a
											href="/materials-science-and-technology"
											className="acceptance-checksum__table-text"
										>
											Материаловедение и технологии материалов
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											27.03.01
										</span>
										<a
											href="/management-in-technical-systems"
											className="acceptance-checksum__table-text"
										>
											Управление в технических системах
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										25
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											35.03.08
										</span>
										<a
											href="/aquatic-bioresources-and-aquaculture"
											className="acceptance-checksum__table-text"
										>
											Водные биоресурсы и аквакульутра
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										30
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											38.03.01
										</span>
										<a
											href="/economy"
											className="acceptance-checksum__table-text"
										>
											Экономика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										130
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										75
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											38.03.02
										</span>
										<a
											href="/management"
											className="acceptance-checksum__table-text"
										>
											Менеджмент
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										50
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										50
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											39.03.01
										</span>
										<a
											href="/sociology"
											className="acceptance-checksum__table-text"
										>
											Социология
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										20
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										5
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										30
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											42.03.01
										</span>
										<a
											href="/advertising-and-public-relations"
											className="acceptance-checksum__table-text"
										>
											Реклама и связи с общественностью
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										—
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										100
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										100
									</span>
								</div>
							</tr>
						</table >
					</div >
				</div >
			</div >
		),
	},

	// --------------------------------------------------------------------------------------------------------------------

	{
		title: "Вступительные испытания",
		id: "tests",
		anchor: "select-2",
		text: (
			<div className="step-procpective-student__padding">
				{/* <UndegraduateICTEInfo /> цветные точки с предметами*/}

				<div className="undegraduate-ICTE-info">
					<div className="entrance-tests">
						<span className="individual-achivments__title">
							Вступительные испытания
						</span>
						<div className="individual-achivments__top-block-subjects">
							<span className="individual-achivments__top-subjects-text">
								* — обязательные предметы для поступления после школы
							</span>
							<div className="acceptance-checksum__table-option">
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects math">
										<span className="acceptance-checksum__subjects">
											М
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— математика
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects math">
										<span className="acceptance-checksum__subjects">
											ПМ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— прикладная математика
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects obsh">
										<span className="acceptance-checksum__subjects">
											О
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— обществознание
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects obsh">
										<span className="acceptance-checksum__subjects">
											ЭТ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— экономическая теория
									</span>
								</div>
							</div>
							<div className="acceptance-checksum__table-option">
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects rus">
										<span className="acceptance-checksum__subjects">
											Р
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— русский язык
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects rus">
										<span className="acceptance-checksum__subjects">
											РЯ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— русский язык
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block">

									<div className="acceptance-checksum__subjects biol">
										<span className="acceptance-checksum__subjects">
											Б
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— биология
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects biol">
										<span className="acceptance-checksum__subjects">
											БЭ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— биология с основами экологии
									</span>
								</div>
							</div>
							<div className="acceptance-checksum__table-option">
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects fiz">
										<span className="acceptance-checksum__subjects">
											Ф
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— физика
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects fiz">
										<span className="acceptance-checksum__subjects">
											ОЭ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— общая энергетика
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block">

									<div className="acceptance-checksum__subjects his">
										<span className="acceptance-checksum__subjects">
											ИС
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— история
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects his">
										<span className="acceptance-checksum__subjects">
											ИР
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— история России
									</span>
								</div>
							</div>
							<div className="acceptance-checksum__table-option">
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects inf">
										<span className="acceptance-checksum__subjects">
											И
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— информатика и икт
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block two">
									<div className="acceptance-checksum__subjects inf">
										<span className="acceptance-checksum__subjects">
											ПИ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— прикладная информатика
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects geo">
										<span className="acceptance-checksum__subjects">
											Г
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— география
									</span>
								</div>
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects him">
										<span className="acceptance-checksum__subjects">
											Х
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— химия
									</span>
								</div>
							</div>
							<div className="acceptance-checksum__table-option">
								<div className="acceptance-checksum__table-option-block">
									<div className="acceptance-checksum__subjects inos">
										<span className="acceptance-checksum__subjects">
											ИЯ
										</span>
									</div>
									<span className="individual-achivments__top-subjects-text">
										— иностранный язык
									</span>
								</div>
							</div>
						</div>
						<br />
						<table className="scroll-table facultets">
							<tr className="undegraduate-ICTE-info">
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Код и наименование направления
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Перечень вступительных <br />
										испытаний после школы
									</span>
								</div>
								<div className="entrance-tests__column">
									<span className="acceptance-checksum__table-title-text">
										Перечень вступительных <br />
										испытаний после колледжа
									</span>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											01.03.04
										</span>
										<a
											href="/applied-mathematics"
											className="acceptance-checksum__table-text"
										>
											Прикладная математика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												ПИ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											09.03.01
										</span>
										<a
											href="/computer-science-and-engineering"
											className="acceptance-checksum__table-text"
										>
											Информатика и<br /> вычислительная техника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												ПИ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											09.03.03
										</span>
										<a
											href="/applied-computer-science"
											className="acceptance-checksum__table-text"
										>
											Прикладная информатика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												ПИ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											11.03.04
										</span>
										<a
											href="/electronics-and-nanoelectronics"
											className="acceptance-checksum__table-text"
										>
											Электроника и<br /> наноэлектроника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											12.03.01
										</span>
										<a
											href="/instrumentation"
											className="acceptance-checksum__table-text"
										>
											Приборостроение
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.01
										</span>
										<a
											href="/heat-power-engineering-and-heat-engineering"
											className="acceptance-checksum__table-text"
										>
											Теплоэнергетика и<br /> теплотехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.02
										</span>
										<a
											href="/electric-power-and-electrical-engineering"
											className="acceptance-checksum__table-text"
										>
											Электроэнергетика и электротехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											13.03.03
										</span>
										<a
											href="/energy-power-engineering"
											className="acceptance-checksum__table-text"
										>
											Энергетическое машиностроение
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											14.05.02
										</span>
										<a
											href="/nuclear-power-plants-design-operation"
											className="acceptance-checksum__table-text"
										>
											Атомные станции:<br /> проектирование, эксплуатация <br />и
											инжиниринг
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											15.03.04
										</span>
										<a
											href="/automation-of-technological-processes-and-productions"
											className="acceptance-checksum__table-text"
										>
											Автоматизация
											технологических процессов и производств
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											15.03.06
										</span>
										<a
											href="/mechatronics-and-robotics"
											className="acceptance-checksum__table-text"
										>
											Мехатроника и робототехника
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											20.03.01
										</span>
										<a
											href="/technosphere-safety"
											className="acceptance-checksum__table-text"
										>
											Техносферная безопасность
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											22.03.01
										</span>
										<a
											href="/materials-science-and-technology"
											className="acceptance-checksum__table-text"
										>
											Материаловедение и технологии материалов
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>

							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											27.03.01
										</span>
										<a
											href="/management-in-technical-systems"
											className="acceptance-checksum__table-text"
										>
											Управление в технических системах
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects fiz">
												Ф
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects fiz">
											<span className="acceptance-checksum__subjects">
												ОЭ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											35.03.08
										</span>
										<a
											href="/aquatic-bioresources-and-aquaculture"
											className="acceptance-checksum__table-text"
										>
											Водные биоресурсы и аквакульутра
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects biol">
											<span className="acceptance-checksum__subjects">
												Б*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects geo">
											<span className="acceptance-checksum__subjects">
												Г
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects biol">
											<span className="acceptance-checksum__subjects">
												БЭ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											38.03.01
										</span>
										<a
											href="/economy"
											className="acceptance-checksum__table-text"
										>
											Экономика
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												О
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects him">
											<span className="acceptance-checksum__subjects">
												Х
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												ЭТ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											38.03.02
										</span>
										<a
											href="/management"
											className="acceptance-checksum__table-text"
										>
											Менеджмент
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												О
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects geo">
											<span className="acceptance-checksum__subjects">
												Г
											</span>
										</div>
										<div className="acceptance-checksum__subjects his">
											<span className="acceptance-checksum__subjects">
												ИС
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												ЭТ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											39.03.01
										</span>
										<a
											href="/sociology"
											className="acceptance-checksum__table-text"
										>
											Социология
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												О*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												М
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
										<div className="acceptance-checksum__subjects his">
											<span className="acceptance-checksum__subjects">
												ИС
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects math">
											<span className="acceptance-checksum__subjects">
												ПМ
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												ЭТ
											</span>
										</div>
									</div>
								</div>
							</tr>
							<tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<span className="acceptance-checksum__table-text">
											42.03.01
										</span>
										<a
											href="/advertising-and-public-relations"
											className="acceptance-checksum__table-text"
										>
											Реклама и связи с общественностью
										</a>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												О*
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												Р*
											</span>
										</div>
									</div>
									<div className="acceptance-checksum__table-title two">
										<div className="acceptance-checksum__subjects his">
											<span className="acceptance-checksum__subjects">
												ИС
											</span>
										</div>
										<div className="acceptance-checksum__subjects inos">
											<span className="acceptance-checksum__subjects">
												ИЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects inf">
											<span className="acceptance-checksum__subjects">
												И
											</span>
										</div>
									</div>
								</div>
								<div className="entrance-tests__column">
									<div className="acceptance-checksum__table-title">
										<div className="acceptance-checksum__subjects his">
											<span className="acceptance-checksum__subjects">
												ИР
											</span>
										</div>
										<div className="acceptance-checksum__subjects rus">
											<span className="acceptance-checksum__subjects">
												РЯ
											</span>
										</div>
										<div className="acceptance-checksum__subjects obsh">
											<span className="acceptance-checksum__subjects">
												ЭТ
											</span>
										</div>
									</div>
								</div>
							</tr>
						</table>
					</div>
				</div>
			</div >
		),
	},

	// ------------------------------------------------------------------------------------------------------------------------------------------

	{
		title: "Перечень документов",
		id: "documents",
		anchor: "select-6",
		text: (
			<div className="step-procpective-student__padding">
				<ListOfDocuments />
			</div>
		),
	},

	// ------------------------------------------------------------------------------------------------------------------------------------------

	{
		title: "Проходные баллы прошлых лет",
		id: "scores",
		anchor: "select-7",
		text: (
			<div className="step-procpective-student__padding">
				<PassingPoints />
			</div>
		),
	},

	// ------------------------------------------------------------------------------------------------------------------------------------------

	{
		title: "Индивидуальные достижения",
		id: "achievements",
		anchor: "select-5",
		text: (
			<div className="step-procpective-student__padding">
				<IndividualAchievements />
			</div>
		),
	},

	// ------------------------------------------------------------------------------------------------------------------------------------------

	{
		title: "Стипендия (бюджет и платная основа)",
		id: "scholarship",
		anchor: "select-4",
		text: (
			<div className="step-procpective-student__padding last">
				<Scholarship />
			</div>
		),
	},
];
