import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/pavlov.jpg";
// import doc from "../../../../assets/documents/EducationalPrograms/pm.pdf";

import "./UHESTeacherPage.css";

export default function UHESTeacherPage() {
  return (
    <React.Fragment>
      <TopBar />
      <TopBarProspectiveStudent />
      <Container>
        <div className="educational-program magistracy teacher">
          <span className="teacher__title">
            Эксплуатация высокоскоростного электроподвижного состава
          </span>
          <div className="teacher__main-block">
            <div className="teacher__main-block-img">
              <img src={img} alt="" />
            </div>
            <div className="teacher__main-block-text">
              <span className="teacher__text">
                Руководитель образовательной программы
              </span>
              <span className="teacher__title">Павлов Павел Павлович</span>
              <span className="teacher__text">
                к.т.н., доцент кафедры «Электротехнические комплексы и системы»
                (ЭТКС)
              </span>
              <div className="teacher__main-block-text-row">
                <span className="teacher__text">e-mail:</span>
                <span className="teacher__text">pavlov2510@mail.ru</span>
              </div>
              <div className="teacher__main-block-text-row">
                <span className="teacher__text">тел.:</span>
                <span className="teacher__text"> 8 (843) 519-43-12</span>
              </div>
              <span className="teacher__text">420066, г. Казань,</span>
              <span className="teacher__text">ул. Красносельская, 51</span>
              <span className="teacher__text">каб. Б-116</span>
              <div className="teacher__main-block-text-row">
                <Link
                  to="//priem.kgeu.ru/user/sign-in/login"
                  className="teacher__button"
                >
                  Подать документы
                </Link>
                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <Link to=""  className="teacher__button">Учебный план </Link>
                <span className="teacher__button" target="_blank">
                  Узнать подробнее
                </span>
              </div>
            </div>
          </div>
          <span className="teacher__sub-block">
            Обучение ведется на русском языке
          </span>
          {/* <QuestionPM /> */}
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
