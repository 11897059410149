import React from "react";
import { Link } from "react-router-dom";

import celevoe from "../../../assets/documents/SpecialAdmission/celevoe-obuchenie56.pdf";
import pravila from "../../../assets/documents/SpecialAdmission/pravila-ustanovleniya-kvoti.pdf";
import tipovaya from "../../../assets/documents/SpecialAdmission/tipovaya-forma-celevogo-obecheniya.pdf";
import formaPpredlocheniyi from "../../../assets/documents/SpecialAdmission/forma-predlocheniyi.pdf";
import formaZayavki from "../../../assets/documents/SpecialAdmission/forma-zayavki.pdf";
import postanovlenie from "../../../assets/documents/SpecialAdmission/postanovlenie.pdf";
import rasporyachenie from "../../../assets/documents/SpecialAdmission/rasporyachenie.pdf";
import statya71 from "../../../assets/documents/SpecialAdmission/statya71.pdf";
import statya56 from "../../../assets/documents/SpecialAdmission/statya56.pdf";
import kcpBac from "../../../assets/documents/SpecialAdmission/kcpBac.pdf";
import kcpMag from "../../../assets/documents/SpecialAdmission/kcpMag.pdf";

import "./AdmissionToTargetedTraining.css";

export default function AdmissionToTargetedTraining() {

    return (
        <div className="special-admission-conditional">
            <span className="special-admission-conditional__title">Прием на целевое обучение</span><br />
            <div className="special-admission-conditional__text-block">
                <span className="special-admission-conditional__teext last">Это прием на места, финансируемые за счет бюджетных ассигнований федерального бюджета, бюджетов субъектов Российской Федерации, местных бюджетов в рамках квоты целевого приема.</span>
                <span className="special-admission-conditional__teext last">КГЭУ осуществляет прием на целевое обучение по образовательным программам высшего образования в пределах установленной квоты по конкурсу, проводимому в соответствии с порядком приема, предусмотренным Федеральным законом от 29.12.2012 №273-ФЗ "Об образовании в Российской Федерации".</span>
                <span className="special-admission-conditional__teext last">КГЭУ устанавливает квоту приема на целевое обучение по образовательным программам высшего образования за счет бюджетных ассигнований федерального бюджета на 2024/25 учебный год в соответствии с Распоряжением Правительства Российской Федерации от 20 февраля 2024 г. №388-р.</span>
                <span className="special-admission-conditional__text">С кем можно заключить договор о целевом обучении:</span>
                <span className="special-admission-conditional__teext">• федеральными государственными органами, органами государственной власти субъектов Российской Федерации, органами местного самоуправления;</span>
                <span className="special-admission-conditional__teext">• государственными и муниципальными учреждениями, унитарными предприятиями;</span>
                <span className="special-admission-conditional__teext">• государственными корпорациями;</span>
                <span className="special-admission-conditional__teext">• государственными компаниями и их дочерними хозяйственными обществами;</span>
                <span className="special-admission-conditional__teext">• организациями, включенными в сводный реестр организаций оборонно-промышленного комплекса, формируемый в соответствии с частью 2 статьи 21 Федерального закона от 31 декабря 2014 года N 488-ФЗ "О промышленной политике в Российской Федерации";</span>
                <span className="special-admission-conditional__teext">• хозяйственными обществами, в уставном капитале которых присутствует доля Российской Федерации, субъекта Российской Федерации или муниципального образования и их дочерними хозяйственными обществами;</span>
                <span className="special-admission-conditional__teext">• акционерными обществами, акции которых находятся в собственности или в доверительном управлении государственной корпорации и их дочерними хозяйственными обществами;</span>
                <span className="special-admission-conditional__teext">• организациями, которые созданы государственными корпорациями или переданы государственным корпорациям в соответствии с положениями федеральных законов об указанных корпорациях;</span>
                <span className="special-admission-conditional__teext last">•  организациями, признанными сельскохозяйственными товаропроизводителями в соответствии с
                    частью 1 статьи 3 Федерального закона от 29 декабря 2006 года N 264-ФЗ "О развитии сельского хозяйства", по направлениям подготовки и специальностям
                    сфер сельского хозяйства, рыболовства и инженерии (при условии нахождения в указанном статусе не менее трех лет);
                </span>
                <span className="special-admission-conditional__teext last">•  организациями, являющимися резидентами территорий опережающего развития, особой экономической зоны, зоны территориального развития, Арктической зоны, свободного порта Владивосток, либо организациями, получившими статус участника проекта на территориях инновационного центра "Сколково", международного медицинского кластера, инновационных научно-технологических центров, а также статус участника Военного инновационного технополиса "Эра" Министерства обороны Российской Федерации (при условии нахождения в соответствующем статусе не менее трех лет).</span>

                <span className="special-admission-conditional__text">Преимущества целевого обучения и поступления в рамках целевой квоты:</span>
                <span className="special-admission-conditional__teext">• Отдельный конкурс на места в рамках целевой квоты;</span>
                <span className="special-admission-conditional__teext">• Зачисление на целевые места происходит до зачисления на основные конкурсные места;</span>
                <span className="special-admission-conditional__teext">• Возможность получения дополнительной стипендии и/или других мер поддержки, при успешном обучении;</span>
                <span className="special-admission-conditional__teext last">• Гарантированное трудоустройство на ведущие предприятия России по окончании вуза.</span>

                <span className="special-admission-conditional__text">Обязательства гражданина, заключившего договор о целевом обучении:</span>
                <span className="special-admission-conditional__teext">• Освоение образовательной программы, указанной в договоре о целевом обучении;</span>
                <span className="special-admission-conditional__teext">• Осуществление трудовой деятельности после завершения освоения образовательной программы в месте, определенном договором о целевом обучении, в течение не менее 3 лет и не более 5 лет в соответствии с полученной квалификацией;</span>
                <span className="special-admission-conditional__teext last">• В случае неисполнения условий договора (отчисление за неуспеваемость, отказ от трудоустройства, отказ от заключения договора о целевом обучении, расторжение договора до первой промежуточной аттестации) гражданин выплачивает федеральный бюджет штраф в размере расходов федерального бюджета, затраченных на его обучение. </span>

                <span className="special-admission-conditional__text">Документы, необходимые для участия в конкурсе:</span>
                <span className="special-admission-conditional__teext last">К пакету документов для участия в конкурсе необходимо приложить заявку на на заключение договора о целевом обучении по образовательной программе высшего образования. Порядок организации и осуществления целевого обучения по образовательным программам высшего образования установлен Постановлением Правительства РФ от 27.04.2024 N 555 "О целевом обучении по образовательным программам среднего профессионального и высшего образования", в котором утверждены прилагаемые регламентирующие документы:</span>

                <a href={celevoe} className="special-admission-conditional__link" target="_blank">• Положение о целевом обучении по образовательным программам среднего профессионального и высшего образования;</a><br />
                <a href={pravila} className="special-admission-conditional__link" target="_blank">• Правила установления квоты приема на целевое обучение по образовательным программам высшего образования за счет бюджетных ассигнований федерального бюджета;</a><br />
                <a href={tipovaya} className="special-admission-conditional__link" target="_blank">• Типовая форма договора о целевом обучении по образовательной программе среднего профессионального или высшего образования;</a><br />
                <a href={formaPpredlocheniyi} className="special-admission-conditional__link" target="_blank">• Форма предложения о заключении договора или договоров о целевом обучении по образовательной программе среднего профессионального или высшего образования;</a><br />
                <a href={formaZayavki} className="special-admission-conditional__link" target="_blank">• Форма заявки на заключение договора о целевом обучении по образовательной программе среднего профессионального или высшего образования.</a>
                <br />
                <br />
                <span className="special-admission-conditional__link title">17 июня</span>
                <span className="special-admission-conditional__teext last">Начало приёма документов для участия в конкурсе</span>
                <span className="special-admission-conditional__link title">20 июля</span>
                <span className="special-admission-conditional__teext last">Окончание приёма документов для лиц, поступающих по результатам ВИ, проводимых КГЭУ самостоятельно</span>
                <span className="special-admission-conditional__link title">25 июля</span>
                <span className="special-admission-conditional__teext last">Окончание приёма документов для лиц, поступающих по результатам ЕГЭ</span>
                <span className="special-admission-conditional__link title">27 июля</span>
                <span className="special-admission-conditional__teext last">Публикация конкурсных списков</span>
                <span className="special-admission-conditional__link title">28 июля</span>
                <span className="special-admission-conditional__teext last">Окончание приёма согласий на зачисление для лиц, поступающих на места в пределах целевой квоты</span>
                <span className="special-admission-conditional__link title">29 июля</span>
                <span className="special-admission-conditional__teext last">Публикация приказа о зачислении на места в рамках квоты целевого приёма</span>

                <span className="special-admission-conditional__text">Нормативные документы:</span>
                <a href={rasporyachenie} className="special-admission-conditional__link" target="_blank">• Распоряжение правительства РФ «Об установлении целевой квоты»</a><br />
                <a href={postanovlenie} className="special-admission-conditional__link" target="_blank">• Постановление Правительства РФ от 27.04.2024 N 555 «О целевом обучении»</a><br />
                <a href={statya71} className="special-admission-conditional__link" target="_blank">• Статья 71.1 «Особенности приема на целевое обучение по образовательным»</a><br />
                <a href={statya56} className="special-admission-conditional__link" target="_blank">• Статья 56 Федерального закона от 29 декабря 2012 г. N 273 ФЗ «Об образовании в Российской Федерации»</a><br />
                <a href={kcpBac} className="special-admission-conditional__link" target="_blank">• Контрольные цифры приема с квотами бакалавриат</a><br />
                <a href={kcpMag} className="special-admission-conditional__link" target="_blank">• Контрольные цифры приема с квотами магистратура</a>
                

            </div>
            
            <a href="//kgeu.ru/Home/Page/211?idShablonMenu=504" target="_blank" className="podrobnee">Подробнее</a>
        </div>
    );
}
