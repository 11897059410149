import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/chichirova.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/technolog.pdf";

import img2 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagTPETeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagTPETeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagTPETeacher/companies/img_company3.png";

import "./TeploTechnikTeacherPage.css";

export default function TeploTechnikTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher tpe">
                    <span className="teacher__title">Технология производства электрической и тепловой энергии
                    </span>
                    <div className="teacher__main-block chich">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Чичирова Наталия Дмитриевна</span>
                            <span className="teacher__text">д.х.н., профессор кафедры <br />«Атомные и тепловые электрические станции» <br />(АТЭС)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">ndchichirova@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-42-12</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. Б-306е</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>

                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                                Программа направлена на подготовку специалистов по оптимизации работы тепловых электрических станций, систем энергообеспечения предприятий, паровых и водогрейных котлов, паровых и газовых турбин, парогазовых и газотурбинных установок, вспомогательного теплотехнического оборудования; тепловых сетей.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Теория и практика научных исследований</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Управление проектами в энергетике</span>
                                </div>

                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Инновационные технологии производства электрической и тепловой энергии</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Принципы эффективного управления технологическими процессами ТЭС</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Централизованное теплоснабжение</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Технико-экономическое обоснование выбора параметров на ТЭС и внедрения нового оборудования</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Выбор и разработка основного и вспомогательного оборудования на ТЭС</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Наилучшие доступные технологии на ТЭС</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Контроль результатов внедрения разработок на ТЭС</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">08</span>
                                    <span className="text-line">Оптимизация режимов работы ТЭС</span>
                                </div>
                            </div>
                        </div>
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                                <span className="info_block-title">Кем я смогу работать?</span>

                                <div className="info_block-text_area">
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img1} /></div>
                                        <span className="text-line">Ведущим инженером</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img2} /></div>
                                        <span className="text-line">Заведующим сектором</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img3} /></div>
                                        <span className="text-line">Научным сотрудником</span>
                                    </div>
                                </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1} />
                                    <img src={img_company2} />
                                    <img src={img_company3} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}