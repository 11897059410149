import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";

import Container from "../Container";
import Burger from "../Burger";

// import { menuItems } from "../../Constants";

import "./TopBar.css";

function TopBar() {

    const dispatch = useDispatch();

    return (
        <div className="top-bar">
            <Container>
                <div className="logo">
                    <a href="/" className="logo__link">
                        <img src={logo} alt="Приемная Кампания" className="logo__img" />
                        <div className="logo__text">
                            <span className="logo__text-title">Приемная кампания</span>
                            <span className="logo__text-subtitle">Казанский государственный<br />Энергетический университет</span>
                        </div>
                    </a>
                </div>
                <nav className="top-bar__navbar">
                    <ul className="top-bar__topmenu">
                        <li className="top-bar__menu"><a href="//priem.kgeu.ru" target="_blank" className="lk">Подать документы</a></li>
                        
                        <li className="top-bar__menu"><a href="/prospective-student" className="blue-span">Абитуриенту</a></li>
                        <li className="top-bar__menu"><a href="/undergraduate">Бакалавриат и специалитет</a>
                            
                        </li>
                        <li className="top-bar__menu"><a href="/undergraduate-magistracy">Магистратура</a>
                            
                        </li>
                        <li className="top-bar__menu"><a href="//kgeu.ru/Home/Page/122?idShablonMenu=1159" target="_blank">Аспирантура</a></li>
                    </ul>
                    
                    <Burger click={() => dispatch({ type: "showModal" })} />
                </nav>
            </Container>
        </div>

    );
}

export default TopBar;
