import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/zatsarinnaya.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/intellectualnEnergSis.pdf";

import img1 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img3.png";

import img_company3 from "../../../../assets/images/New/MagIntellecTeacher/companies/img_company3.png";
import img_company1 from "../../../../assets/images/New/MagIntellecTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagIntellecTeacher/companies/img_company2.png";
import "./IntellecTeacherPage.css";

export default function IntellecTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher intellec">
                    <span className="teacher__title">Интеллектуальные энергетические системы
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Зацаринная Юлия Николаевна</span>
                            <span className="teacher__text">к.т.н., доцент кафедры <br />
                                «Электрические станции им. В. К. Шибанова»<br />
                                (ЭС)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">waysubbota@gmail.com</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-42-70</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />
                                каб. Д-614ё</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                                
                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
							Программа направлена на подготовку высококвалифицированных специалистов, умеющих применять специализированное программное обеспечение и моделировать интеллектуальные энергетические системы, объединяющие достижения в сфере энергетических и информационных технологий.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Микросети, интеллектуальные сети и суперсети</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Искусственный интеллект и машинное обучение</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Оптимизация в интеллектуальных электро-энергетических системах</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Экономика интеллектуальных электроэнергетических систем</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Анализ энергетических систем</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Технологии преобразования энергии в интеллектуальных электроэнергетических системах</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Система управления в электроэнергетике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">08</span>
                                    <span className="text-line">Командная работа по управлению режимами основного электрооборудования.</span>
                                </div>
                                
                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Цифровые технологии для защиты и коммуникации</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Информационно-коммуникационные технологии в интеллектуальных электроэнергетических системах</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Инженером-проектировщиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Инженерно-техническим персоналом</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Оперативно-диспетчерским персоналом</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}