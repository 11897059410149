import React from "react";

import "./TuitionFeesMagistracy.css";

export default function TuitionFeesMagistracy() {
    return (
        <div className="tuition-fees magistracy">
            <span className="individual-achivments__title">Стоимость обучения на 2024/25 уч. год</span>
            <span className="individual-achivments__title">Очная форма обучения</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Экономические и гуманитарные направления</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">38.04.02 «Менеджмент»</span>
                </div>
                <span className="tuition-fees__text-block-summa">155 560 руб.</span>
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Технические направления</span>
            </div>
            <div className="tuition-fees__text-block sub">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">08.04.01 «Строительство»</span>
                    <span className="tuition-fees__text">09.04.01 «Информатика и вычислительная техника»</span>
                    {/* <span className="tuition-fees__text">09.04.03 «Прикладная информатика»</span> */}
                    <span className="tuition-fees__text">11.04.04 «Электроника и наноэлектроника»</span>
                    <span className="tuition-fees__text">12.04.01 «Приборостроение»</span>
                    <span className="tuition-fees__text">13.04.01 «Теплоэнергетика и теплотехника»</span>
                    <span className="tuition-fees__text">13.04.02 «Электроэнергетика и электротехника»</span>
                    <span className="tuition-fees__text">13.04.03 «Энергетическое машиностроение»</span>
                    <span className="tuition-fees__text">15.04.04 «Автоматизация технологических процессов и производств»</span>
                    <span className="tuition-fees__text">15.04.06 «Мехатроника и робототехника»</span>
                    <span className="tuition-fees__text">16.04.01 «Техническая физика»</span>
                    <span className="tuition-fees__text">18.04.02 «Энерго- и ресурсосберегающие процессы в химической технологии, <br />нефтехимии и биотехнологии»</span>
                    <span className="tuition-fees__text">20.04.01 «Техносферная безопасность»</span>
                    {/* <span className="tuition-fees__text">27.04.04 «Управление в технических системах»</span>
                    <span className="tuition-fees__text">35.04.08 «Водные биоресурсы и аквакультура»</span> */}
                </div>
                <span className="tuition-fees__text-block-summa">174 890 руб.</span>
            </div>
            <div className="tuition-fees__text-block">
                <div className="tuition-fees__text-block-title">
                <span className="tuition-fees__text">14.04.01 «Ядерная энергетика и теплофизика»</span>
                </div>
                <span className="tuition-fees__text-block-summa">205 980 руб.</span>
            </div>



            <span className="individual-achivments__title">Заочная форма обучения</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Экономические и гуманитарные направления</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">38.04.02 «Менеджмент»</span>
                </div>
                <span className="tuition-fees__text-block-summa"> 58 800 руб.</span>
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Технические направления</span>
            </div>
            <div className="tuition-fees__text-block sub">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">08.04.01 «Строительство»</span>
                    <span className="tuition-fees__text">09.04.01 «Информатика и вычислительная техника»</span>
                    <span className="tuition-fees__text">13.04.01 «Теплоэнергетика и теплотехника»</span>
                    <span className="tuition-fees__text">13.04.02 «Электроэнергетика и электротехника»</span>
                </div>
                <span className="tuition-fees__text-block-summa">67 500 руб.</span>
            </div>
        </div>
    );
}
