import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/vankov.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/proectir.pdf";

import img2 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagStroitTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagDHPSTeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagDHPSTeacher/companies/img_company3.png";

import "./ProectirovanieTeploTeacherPage.css";

export default function ProectirovanieTeploTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher dhps">
                    <span className="teacher__title">Проектирование теплоэнергетических систем предприятий и ЖКХ
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ваньков Юрий Витальевич</span>
                            <span className="teacher__text">д.т.н., профессор кафедры <br />
                                «Промышленная теплоэнергетика и системы
                                теплоснабжения» (ПТЭ)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">yvankov@kgeu.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-42-12</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51,  каб. Б-306е</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>

                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                                Программа направлена на подготовку высококвалифицированных теплоэнергетиков, способных осуществлять проектирование и оптимизацию современных энергоэффективных систем тепло- и энергоснабжения потребителей.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Современные проблемы теплоэнергетики, теплотехники и теплотехнологий</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Анализ и синтез систем теплоснабжения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Специальные вопросы надежности систем теплоснабжения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Проектирование систем теплоснабжения предприятий и ЖКХ</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Технико-экономическое обоснование энергосберегающих мероприятий</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Топливоиспользование в энергетике</span>
                                </div>

                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Энергетически эффективные технологии и оборудование систем теплоснабжения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Исследование режимов работы источников и систем теплоснабжения</span>
                                </div>
                            </div>
                        </div>
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                                <span className="info_block-title">Кем я смогу работать?</span>

                                <div className="info_block-text_area">
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img1} /></div>
                                        <span className="text-line">Инженером-энергетиком</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img2} /></div>
                                        <span className="text-line">Главным энергетиком</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img3} /></div>
                                        <span className="text-line">Инженером-проектировщиком</span>
                                    </div>
                                </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1} />
                                    <img src={img_company2} />
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}