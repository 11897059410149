import React from "react";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";
import StepsProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import Container from "../../components/Container";

import { stepsSpecialAdmissionConditions } from "../../constants";

import "./SpecialAdmissionConditionsPage.css";

function SpecialAdmissionConditionsPage() {
    return (
        <React.Fragment>
            <div className="special-admission-conditions">
                <TopBar />
                <TopBarProspectiveStudent />
                <Container>
                    <div className="step-prospective-student__top">
                        <span className="etap-postupleniya__title">Особые условия поступления</span>
                    </div>
                </Container>
                <StepsProspectiveStudent items={stepsSpecialAdmissionConditions} />

                <Footer />
            </div>
        </React.Fragment>
    );
}

export default SpecialAdmissionConditionsPage;
