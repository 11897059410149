import React from "react";

import AdmissionAfterSchool from "../../components/ComponentsBacalavriatPage/AdmissionAfterSchool";
import AdmissionAfterCollege from "../../components/ComponentsBacalavriatPage/AdmissionAfterCollege";
import PaidBasis from "../../components/ComponentsBacalavriatPage/PaidBasis";
import ReceptioOfForeignCitizens from "../../components/ComponentsBacalavriatPage/ReceptioOfForeignCitizens";
import CDO from "../../components/ComponentsBacalavriatPage/CDO";

export const stepsCategoriesOfApplicants = [
  {
    title: "Поступление после школы",
    anchor: "select-1",
    text: (
      <div className="step-procpective-student__padding">
        <AdmissionAfterSchool />
      </div>
    ),
  },
  {
    title: "Поступление после колледжа",
    anchor: "select-2",
    text: (
      <div className="step-procpective-student__padding">
        <AdmissionAfterCollege />
      </div>
    ),
  },
  {
    title: "Платное обучение",
    anchor: "select-3",
    text: (
      <div className="step-procpective-student__padding">
        <PaidBasis />
      </div>
    ),
  },
  {
    title: "Прием иностранных граждан",
    anchor: "select-4",
    text: (
      <div className="step-procpective-student__padding">
        <ReceptioOfForeignCitizens />
      </div>
    ),
  },
  {
    title: "Центр довузовской подготовки",
    anchor: "select-6",
    text: (
      <div className="step-procpective-student__padding" id="cdo">
        <CDO />
      </div>
    ),
  },
];
