import React from "react";
import { Link } from "react-router-dom";

import sroki from "../../../assets/documents/Undergraduate/sroki.pdf";
import individualki from "../../../assets/documents/Undergraduate/individualki.pdf";

import "./AdmissionAfterSchool.css";

export default function AdmissionAfterSchool(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="admission-after-school" id="after-school">
            <span className="individual-achivments__title">Поступление после школы</span>
            <span className="payment-methods__subtitle">
                Узнай порядок поступления после школы
            </span>
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">1</span>
                            <span className="question-content__item-title">
                                На базе 11 классов
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            На базе 11 классов – можно поступить на программы высшего образования - программы бакалавриата
                            и специалитета.
                        </span>
                        <span className="question-content__item-text">
                            Поступление в КГЭУ осуществляется по результатам ЕГЭ.
                        </span>
                        <span className="question-content__item-text">
                            Вся информация о поступлении представлена на сайте в разделе «Абитуриенту».
                        </span>
                        <span className="question-content__item-text">
                            Рекомендуем заранее ознакомиться с основными датами календаря абитуриента.
                        </span>
                        <span className="question-content__item-text">
                            К сожалению, мы не можем гарантировать достоверность предоставленной информации сторонними
                            ресурсами (сайтами образовательных организаций и порталов), поэтому настоятельно рекомендуем вам
                            проверять информацию о поступлении на официальном сайте университета, сайте Приемной комиссии или
                            по телефонам контакт-центра Приемной комиссии:
                        </span>
                        <span className="question-content__item-text">
                            тел. <span className="admission-after-school__text blue">8 (843) 519-42-42; 8 (843) 519-43-23</span> <br />
                            e-mail: <span className="admission-after-school__text blue">pk@kgeu.ru</span><br />
                            420066, г. Казань, ул. Красносельская, 51
                        </span>
                        <span className="question-content__item-text">
                            Если Вы являетесь гражданином другого государства, то для понимания порядка зачисления вам необходимо
                            определить свой статус в отделе по работе с иностранными студентами.
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">2</span>
                            <span className="question-content__item-title">
                                Документы
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Подать документы можно электронно через личный кабинет и лично по адресу:
                        </span>
                        <span className="question-content__item-text title-blue">
                            420066, РТ, г. Казань, ул. Красносельская, д. 51, корпус В, каб. 100
                        </span>
                        <span className="question-content__item-text">
                            Сформированное в личном кабинете заявление необходимо распечатать, подписать, отсканировать и в формате .pdf
                            загрузить в Личный кабинет абитуриента в разделе “Загрузить документы”.
                        </span>
                        <span className="question-content__item-text">
                            Для подтверждения ваших баллов ЕГЭ, вам не требуется предоставлять никаких документов. Если вы не знаете или
                            не помните результатов ЕГЭ, вы можете подавать документы, так как все данные подгружаются автоматически из
                            федеральной базы ЕГЭ по вашим паспортным данным (если произошла замена паспорта, а ЕГЭ вы сдавали по старому
                            паспорту, то нужны будут данные старого паспорта).
                        </span>
                        <span className="question-content__item-text">
                            Если ваши баллы за предмет меньше установленного университетом минимума, то вы не можете участвовать в
                            конкурсе на поступление. С минимальными (пороговыми) баллами вы можете ознакомиться по ссылке.
                        </span>
                        <span className="question-content__item-text">
                            Копии документов для подачи в приёмную комиссию заверять не нужно.
                        </span>

                        <span className="question-content__item-text">
                            Даже если вы подаете заявление на несколько направлений в университет, вы всё равно предоставляете только
                            один комплект документов.
                        </span>
                        <span className="question-content__item-text">
                            В рамках университета вы можете подать только на пять направлений по одной образовательной программе в каждом.
                            Однако в рамках каждого направления вы можете выбирать разные формы обучения (очная, очно-заочная, заочная) и
                            способ финансирования обучения (бюджет/ платное).
                        </span>
                        <span className="question-content__item-text">
                            Если вы поступаете на бюджетную форму обучения, то заявление о согласии на зачисление можно переписать только
                            ТРИ РАЗА. Напоминаем, что без заявления на согласие абитуриент не может быть зачислен. Со сроками подачи документов
                            можно ознакомиться <Link to={sroki} className="question-content__item-text title-blue" target="_blank" rel="noreferrer">ЗДЕСЬ</Link>
                        </span>
                        <span className="question-content__item-text">
                            Если ваш документ об образовании выдан в другом государстве, вам необходимо пройти процедуру экспертизы, которая подтверждает
                            уровень вашего образования.
                        </span>
                        <span className="question-content__item-text">
                            Во время подачи документов в приёмную комиссию при поступлении на обучение по направлениям подготовки «Теплоэнергетика и
                            теплотехника» и«Электроэнергетика и электротехника» вам необходимо предоставить медицинскую справку (в форме 086у, оригинал или копию).
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">3</span>
                            <span className="question-content__item-title">
                                Индивидуальные достижения
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Максимальная сумма баллов, которые вы можете получить за индивидуальные достижения - 10 баллов.
                        </span>
                        <span className="question-content__item-text">
                            С перечнем индивидуальных достижений можно ознакомиться <Link to={individualki} className="question-content__item-text title-blue" target="_blank" rel="noreferrer">ЗДЕСЬ</Link>
                        </span>
                        <span className="question-content__item-text">
                            Если вы являетесь победителем/призёром заключительного этапа Всероссийской олимпиады школьников, то вы можете быть
                            зачисленными в университет без вступительных испытаний.
                        </span>
                        <span className="question-content__item-text">
                            Если вы являетесь победителем/призёром олимпиады РСОШ с ЕГЭ не менее 75 баллов, то вы можете быть зачисленными в университет
                            без вступительных испытаний.
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">4</span>
                            <span className="question-content__item-title">
                                Вступительные испытания
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Выпускники школ РФ поступают только по результатам ЕГЭ (срок действия результатов 4 года за годом сдачи ЕГЭ).
                        </span>
                        <span className="question-content__item-text">
                            Для поступления в КГЭУ НЕ НУЖНО сдавать дополнительные вступительные испытания.
                        </span>
                        <span className="question-content__item-text">
                            Если математика является одним из вступительных предметов, то она обязательно является профильной.
                        </span>
                        <span className="question-content__item-text">
                            На большинство направлений подготовки абитуриент может выбрать третий предмет, при этом абитуриенты с разным набором
                            предметов участвуют в общем конкурсе в рамках одной конкурсной группы. С перечнем предметов можно ознакомиться 
                            <Link to="/undergraduate-iee" className="question-content__item-text title-blue">ЗДЕСЬ</Link>
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
