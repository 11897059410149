import React from "react";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";

import Container from "../../components/Container";

import "./ContactsPage.css";

function ContactsPage() {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };
    return (
        <React.Fragment>
            <div className="contacts">
                <TopBar />
                <TopBarProspectiveStudent />
                <Container>
                    <div className="step-prospective-student__top">
                        <span className="etap-postupleniya__title">Контакты</span>
                    </div>
                    <div className="preparatory-courses__info-block">
                        <div className="preparatory-courses__text-block">
                            <div className="preparatory-courses__text-block">
                                <span className="preparatory-courses__text-block-subtitle sub">Приемная комиссия</span>
                                <span className="preparatory-courses__text-block-text">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> pr-kgeu@yandex.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-23</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Институт теплоэнергетики</span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Очное отделение</span>
                                <span className="preparatory-courses__text-block-text sub">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> ite5279234@mail.ru</span>
                                    &#160; &#160;Телефоны: <span className="preparatory-courses__text-block-text-blue">8 (843) 527-92-34 / 519-43-60</span>
                                </span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Заочное отделение</span>
                                <span className="preparatory-courses__text-block-text">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> kgeu.ite@mail.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-43-10</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Институт электроэнергетики и электроники</span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Очное отделение</span>
                                <span className="preparatory-courses__text-block-text sub">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> iee.14@mail.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-81</span>
                                </span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Заочное отделение</span>
                                <span className="preparatory-courses__text-block-text">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> zf-kgeu@mail.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-43-32</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Институт цифровых технологий и экономики</span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Очное отделение</span>
                                <span className="preparatory-courses__text-block-text sub">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> icte@mail.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-43-90 / 519-43-29</span>
                                </span>
                                <span className="preparatory-courses__text-block-subtitle subsub">Заочное отделение</span>
                                <span className="preparatory-courses__text-block-text">Эл.почта:
                                    <span className="preparatory-courses__text-block-text-blue"> icte-zao@mail.ru</span>
                                    &#160; &#160;Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-43-38</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Отдел аспирантуры</span>
                                <span className="preparatory-courses__text-block-text">Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-19</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Повышение квалификации</span>
                                <span className="preparatory-courses__text-block-text">Телефоны: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-43-33 / 527-92-24 / 527-92-37</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Центр довузовского образования</span>
                                <span className="preparatory-courses__text-block-text">Телефоны: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-24 / 519-43-86</span>
                                </span>

                                <span className="preparatory-courses__text-block-subtitle sub">Студенческий отдел кадров</span>
                                <span className="preparatory-courses__text-block-text">Телефон: <span className="preparatory-courses__text-block-text-blue">8 (843) 519-42-26</span>
                                </span>
                            </div>
                            <span className="preparatory-courses__text-block-subtitle">Частые вопросы</span>
                            <nav className="question-content">
                                <ul className="question-content__menu">
                                    <li className="question-content__item" onClick={handleClickQuestion}>
                                        <div className="question-content__item-title-block">
                                            <span className="tuition-fees__title">
                                                Как подать документы?
                                            </span>
                                        </div>
                                        <span className="question-content__item-text">
                                            <div className="grant-rectora__steps">
                                                <span className="question-content__item-title">
                                                    Документы можно подать несколькими способами:
                                                </span>
                                                <span className="question-content__item-text">
                                                    - через личный кабинет поступающего;<br />
                                                    - лично или доверенным лицом;<br />
                                                    - с использованием суперсервиса «Поступление в вуз онлайн» посредством государственной
                                                    информационной системы « Единый портал государственных и муниципальных услуг(функций)»;<br />
                                                    - через операторов почтовой связи.
                                                </span><br />
                                            </div>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="question-content__menu">
                                    <li className="question-content__item" onClick={handleClickQuestion}>
                                        <div className="question-content__item-title-block">
                                            <span className="tuition-fees__title">
                                                Можно ли подать документы, если абитуриент не знает своих баллов ЕГЭ?
                                            </span>
                                        </div>
                                        <span className="question-content__item-text">
                                            <span className="question-content__item-text">
                                                Отсутствие у абитуриента информации о баллах ЕГЭ не является препятствием для подачи документов
                                                в КГЭУ. Все баллы ЕГЭ проверяются через единую базу ФИС ГИА и Приема по паспортным данным.
                                            </span><br />
                                        </span>
                                    </li>
                                </ul>
                                <ul className="question-content__menu">
                                    <li className="question-content__item" onClick={handleClickQuestion}>
                                        <div className="question-content__item-title-block">
                                            <span className="tuition-fees__title">
                                                Могут ли родители абитуриента сдать за него ксерокопии документов и заявление?
                                            </span>
                                        </div>
                                        <span className="question-content__item-text">
                                            <span className="question-content__item-text">
                                                Да, могут подать документы. Но необходимо родителям предъявить свой паспорт и документы копии с подписями от ребенка-абитуриента.
                                            </span><br />
                                        </span>
                                    </li>
                                </ul>
                                <ul className="question-content__menu">
                                    <li className="question-content__item" onClick={handleClickQuestion}>
                                        <div className="question-content__item-title-block">
                                            <span className="tuition-fees__title">
                                                У меня не пройден порог ЕГЭ по одному или нескольким предметам, возможно ли вместо этого сдать вступительные испытания?
                                            </span>
                                        </div>
                                        <span className="question-content__item-text">
                                            <span className="question-content__item-text">
                                                Только в том случае, если вы относитесь к одной из категорий абитуриентов, которые имеют право сдавать внутренние вступительные испытания КГЭУ. Это:
                                                <br />- иностранные граждане;<br />
                                                - абитуриенты, имеющие инвалидность;<br />
                                                - абитуриенты, проходившие обучение на базе профессионального образования;<br />
                                                - абитуриенты с высшим образованием.
                                            </span>
                                            <span className="question-content__item-text">
                                                В остальных случаях прием ведется только по результатам ЕГЭ.
                                            </span><br />
                                        </span>
                                    </li>
                                </ul>
                                <ul className="question-content__menu">
                                    <li className="question-content__item" onClick={handleClickQuestion}>
                                        <div className="question-content__item-title-block">
                                            <span className="tuition-fees__title">
                                                Как подтвердить индивидуальные достижения?
                                            </span>
                                        </div>
                                        <span className="question-content__item-text">
                                            <span className="question-content__item-text">- При подачи электронного заявления через личный кабинет во вкладке Индивидуальные достижения, вам необходимо выбрать из перечня подходящее индивидуальное достижение и приложить фото хорошего качества или скан.
                                                <br />- При личной подаче, вам необходимо иметь оригинал индивидуального достижения.
                                            </span><br />
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="preparatory-courses__text-block">
                            <span className="preparatory-courses__text-block-subtitle">Оформление и прием документов</span>
                            <span className="preparatory-courses__text-block-text">ул. Красносельсекая, д.51, корпус «В», <br />
                                каб. В-100 <br />
                                пн-пт: с 09:00 до 17:00.</span>
                        </div>

                    </div>
                </Container>
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default ContactsPage;
