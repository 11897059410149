import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";
import EducationalProgramPM from "../../../components/ComponentsUndergraduatePage/ComponentsEducationalPrograms/EducationalProgramPM";

import Container from "../../../components/Container";

import "./PricladMathPage.css";

export default function PricladMathPage(props) {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top abiturient">
                    <span className="etap-postupleniya__title">Бакалавриат, специалитет</span>
                </div>
                <div className="step-prospective-student__top undergraduate-ICTE-page educational-program abiturient">
                </div>
                <EducationalProgramPM />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
