import React from "react";
import { Link } from "react-router-dom";

import "./EntranceTests.css";

export default function EntranceTests() {
    return (
        <div className="entrance-tests">
            <span className="individual-achivments__title">Вступительные испытания</span>
            <table className="scroll-table vi">
                <tr className="acceptance-checksum__table-title-block">
                    <th className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Код и наименование направления</span>
                    </th>
                    <th className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Перечень вступительных испытаний   ( */ ** )</span>
                    </th>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <th className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text"></span>
                    </th>
                    <th className="entrance-tests__column">
                        <span className="entrance-tests__table-title-text">Обязательные предметы</span>
                        <span className="entrance-tests__table-title-text">Предметы по выбору</span>
                    </th>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">01.03.04</span>
                            <Link to="/applied-mathematics" className="acceptance-checksum__table-text">Прикладная математика</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">09.03.01</span>
                            <Link to="/computer-science-and-engineering" className="acceptance-checksum__table-text">Информатика и вычислительная техника</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">09.03.03</span>
                            <Link to="/applied-computer-science" className="acceptance-checksum__table-text">Прикладная информатика</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">11.03.04</span>
                            <Link to="/electronics-and-nanoelectronics" className="acceptance-checksum__table-text">Электроника и наноэлектроника</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">12.03.01</span>
                            <Link to="/instrumentation" className="acceptance-checksum__table-text">Приборостроение</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.01</span>
                            <Link to="//heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика и теплотехника</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.02</span>
                            <Link to="/electric-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика и электротехника</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.03</span>
                            <Link to="/energy-power-engineering" className="acceptance-checksum__table-text">Энергетическое машиностроение</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">14.05.02</span>
                            <Link to="/nuclear-power-plants-design-operation" className="acceptance-checksum__table-text">Атомные станции: проектирование, эксплуатация и инжиниринг</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">15.03.04</span>
                            <Link to="/automation-of-technological-processes-and-productions" className="acceptance-checksum__table-text">Автоматизация технологических процессов и производств</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">15.03.06</span>
                            <Link to="/mechatronics-and-robotics" className="acceptance-checksum__table-text">Мехатроника и робототехника</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">20.03.01</span>
                            <Link to="/technosphere-safety" className="acceptance-checksum__table-text">Техносферная безопасность</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <div className="acceptance-checksum__table-title-block one">
                <div className="entrance-tests__column">
                    <div className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27.03.04</span>
                        <Link to="/management-in-technical-systems" className="acceptance-checksum__table-text">Управление в технических системах</Link>
                    </div>
                </div>
                <div className="entrance-tests__column">
                <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects fiz">
                                    Ф
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                </div>
            </div>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">35.03.08</span>
                            <Link to="/aquatic-bioresources-and-aquaculture" className="acceptance-checksum__table-text">Водные биоресурсы и аквакульутра</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects biol">
                                <span className="acceptance-checksum__subjects">
                                    Б*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects geo">
                                <span className="acceptance-checksum__subjects">
                                    Г
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">38.03.01</span>
                            <Link to="/economy" className="acceptance-checksum__table-text">Экономика</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    О
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects him">
                                <span className="acceptance-checksum__subjects">
                                    Х
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">38.03.02</span>
                            <Link to="/management" className="acceptance-checksum__table-text">Менеджмент</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    О
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects geo">
                                <span className="acceptance-checksum__subjects">
                                    Г
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects his">
                                <span className="acceptance-checksum__subjects">
                                    ИС
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39.03.01</span>
                            <Link to="/sociology" className="acceptance-checksum__table-text">Социология</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    О*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    М
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects his">
                                <span className="acceptance-checksum__subjects">
                                    ИС
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">42.03.01</span>
                            <Link to="/advertising-and-public-relations" className="acceptance-checksum__table-text">Реклама и связи с общественностью</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title vi">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    О*
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    Р*
                                </span>
                            </div>
                        </div>
                        <div className="acceptance-checksum__table-title two">
                            <div className="acceptance-checksum__subjects his">
                                <span className="acceptance-checksum__subjects">
                                    ИС
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inos">
                                <span className="acceptance-checksum__subjects">
                                    ИН
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    И
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                {/* <tr className="acceptance-checksum__table-title-block one">
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">46.03.02</span>
                            <Link to="/documentation-and-archival-science" className="acceptance-checksum__table-text">Документоведение <br />и архивоведение</Link>
                        </div>
                    </td>
                    <td className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects his">История</span>
                            <span className="acceptance-checksum__subjects rus">Русский язык</span>
                        </div>
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects obsh">Общество</span>
                            <span className="acceptance-checksum__subjects inf">Информатика</span>
                        </div>
                    </td>
                </tr> */}
            </table>
        </div>
    );
}
