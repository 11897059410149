import React from "react";

import "./InostranieStudentImg.css";

import one from "../../../assets/images/inostr/one.png";
import two from "../../../assets/images/inostr/two.png";
import three from "../../../assets/images/inostr/three.png";
import four from "../../../assets/images/inostr/four.png";
import five from "../../../assets/images/inostr/five.png";
import six from "../../../assets/images/inostr/six.png";
import seven from "../../../assets/images/inostr/seven.png";
import eight from "../../../assets/images/inostr/eight.png";
import vector from "../../../assets/images/inostr/vector.png";
import vectorOne from "../../../assets/images/inostr/vectorOne.png";
import vectorTwo from "../../../assets/images/inostr/vectorTwo.png";



function InostranieStudentImg() {
    return (
        <div className="inostranie-studenti">
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell"></div>
                <div className="inostranie-studenti__cell"><img className="cel__img" src={eight} alt="" /></div>
                <div className="inostranie-studenti__cell three one"><img className="cel__img" src={seven} alt="" /></div>
            </div>
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell"></div>
                <div className="inostranie-studenti__cell">Средства<br />физических лиц</div>
                <div className="inostranie-studenti__cell three one">Обучения за счет<br />бюджета РФ</div>
            </div>
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell"></div>
                <div className="inostranie-studenti__cell"><img className="strelka" src={vector} alt="" /></div>
                <div className="inostranie-studenti__cell three one">
                    <img className="strelka" src={vectorTwo} alt="" />
                    <img className="strelka" src={vectorOne} alt="" />
                </div>
                <div className="inostranie-studenti__cell"></div>
            </div>



            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell border"></div>
                <div className="inostranie-studenti__cell border"><img className="cel__img" src={six} alt="" /></div>
                <div className="inostranie-studenti__cell three border"><img className="cel__img" src={five} alt="" /></div>
                <div className="inostranie-studenti__cell border"><img className="cel__img" src={four} alt="" /></div>
            </div>
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell border-bottom">Варианты<br />обучения</div>
                <div className="inostranie-studenti__cell border-bottom">Обучение <br />по контракту</div>
                <div className="inostranie-studenti__cell three border-bottom">Госстипендия (квота <br />правительства РФ)</div>
                <div className="inostranie-studenti__cell border-bottom">Обучение на равных <br />правах с гражданами РФ<br />(на общих основаниях,<br />конкурсной основе)</div>
            </div>
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell border">Абитуриенты</div>
                <div className="inostranie-studenti__cell border"><img className="cel__img" src={three} alt="" /></div>
                <div className="inostranie-studenti__cell three border"><img className="cel__img" src={two} alt="" /></div>
                <div className="inostranie-studenti__cell border"><img className="cel__img" src={one} alt="" /></div>
            </div>
            <div className="inostranie-studenti__row">
                <div className="inostranie-studenti__cell border"></div>
                <div className="inostranie-studenti__cell border">Из всех стран</div>
                <div className="inostranie-studenti__cell three border">Из стран, которым выделена квота в текущем учебном году, в том числе соотечественником, проживающее за рубежом</div>
                <div className="inostranie-studenti__cell border">1. Некоторые категории иностранцев*<br />2. Соотечественники**, проживающие за рубежом<br />3. Переселенцы***</div>
            </div>

            <div className="inostranci-content">
                <div className="inostranci-text">
                    <span>*Некоторые категории иностранцев</span>
                    <span>
                    <br /><br />Категории иностранных граждан, имеющих право на поступление на бюджетные места:
                    <br />1. Граждане Азербайджанской Республики, Киргизской Республики, Украины, Туркменистана, республик Армения, Белaрусь, Казахстан, Молдова, Таджикистан, Узбекистан, постоянно  проживающие на территории России в момент  поступления в вуз (Соглашение 1992 года).
                    <br />2. Граждане Грузии, постоянно проживающие в России (Соглашение 1994 года)
                    <br />3. Граждане Киргизской республике, республик Беларусь, Казахстан, Таджикистан, проживающие на терриории своих стран (Договор 1996 года)
                </span>
                </div>
                <div className="inostranci-text"><br />
                    <span>**Соотечественники, проживающие за рубежом.</span>
                    <span>
                        <br /><br />Российское законодательство относит к соотечественникам, проживающим за рубежом , следующие категории граждан (Федеральный закон 1999 года):
                    <br />1. Россияне, постоянно проживающие за пределами Российской Федерации.
                    <br />2. Представители народов, исторически проживающих на территории Российской Федерации. К ним, в частности, относятся бывшие граждане СССР.
                    <br />3. Эмигранты из Российского государства, РСФСР, СССР и Российской Федерации, имевшие соответствующее гражданство,а также их потомки.
                    </span>
                </div>
                <div className="inostranci-text"><br />
                    <span>***Переселенцы</span>
                    <span>
                    <br /><br />В данном случае имеются ввиду участники госпрограммы по добровольному переселению в Российскую Федерацию (Указ Президента 2006 года)- совершеннолетние соотечественники, проживающие за рубежом. <br />Для подтверждения статуса им выдают государственные свидетельства установленного образца.<br />Несовершеннолетние переселенцы получают статус члена семьи участника программы, данные о них указываются в его свидетельстве
                    </span>
                </div>
            </div>
        </div>

    );
}

export default InostranieStudentImg;
