import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsTeplotech.css";

const items = [
  {
    subtitle: "160",
    text: (
      <React.Fragment>
        Проходной балл <br /> в 2023 (бюджет)
      </React.Fragment>
    ),
  },
  {
    subtitle: "265; 50",
    text: (
      <React.Fragment>
        Количество бюджетных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "15; 60",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "163 160₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  {
    subtitle: "63 600₽",
    text: "Стоимость платного обучения на заоч/ф",
  },
];

export default function EducationalProgramsTeplotech() {
  return (
    <div className="educational-program">
      <span className="individual-achivments__title">
        Институт теплоэнергетики
      </span>

      <table className="educational-program__scroll-table">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Теплоэнергетика и теплотехника»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет очная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Бюджет заочная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/thermal-power-stations"
              className="educational-program__block-text"
            >
              Тепловые электрические станции
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/energy-of-housing-and-communal-services"
              className="educational-program__block-text one"
            >
              Энергетика жилищно-коммунального хозяйства
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/autonomous-energy-systems"
              className="educational-program__block-text"
            >
              Автономные энергетические системы
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/energy-supply-of-enterprises"
              className="educational-program__block-text one"
            >
              Энергообеспечение предприятий
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/industrial-thermal-power-engineering"
              className="educational-program__block-text"
            >
              Промышленная теплоэнергетика
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/design-of-thermal-power-systems"
              className="educational-program__block-text one"
            >
              Информационные технологии проектирования теплоэнергетических систем
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/economics-and-management-at-a-thermal-power-plant"
              className="educational-program__block-text"
            >
              Экономика и управление на предприятии теплоэнергетики
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
      </table>

      <div className="educational-program__main-block elect">
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
