import React from "react";
import { Link } from "react-router-dom";

import dogovor from "../../../assets/documents/PaidBasis/tipovoiDogovogPlatka2021.41344e4b.pdf";
import kvitanciya from "../../../assets/documents/PaidBasis/kvitanciyaPlatka.68b9e712.pdf";

import "./PaidBasis.css";

export default function PaidBasis(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="paid-basis" id="platka">
            <span className="individual-achivments__title">Платная основа</span>
            <span className="payment-methods__subtitle">
                Узнай порядок поступления на платной основе
            </span>
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">1</span>
                            <span className="question-content__item-title">
                                На базе 11 классов
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Для выпускников школ поступление осуществляется по результатам ЕГЭ.
                        </span>
                        <span className="question-content__item-text">
                            Подать документы → заключить договор и получить квитанцию на оплату обучения → оплатить предоставить копию квитанции в Приемную комиссию → ознакомиться с приказом о зачислении.
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">2</span>
                            <span className="question-content__item-title">
                                На базе профессионального образования
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Для поступающих на базе профессионального образования (диплом колледжа, диплом университета) - осуществляется по результатам
                            ЕГЭ и/или по результатам вступительных испытаний, проводимых КГЭУ самостоятельно.
                        </span>
                        <span className="question-content__item-text title-blue">
                            Подать документы → Пройти вступительные испытания, проводимые КГЭУ самостоятельно → ознакомитесь с результатами вступительных испытаний → заключить
                            договор и получить квитанцию на оплату обучения → оплатить предоставить копию квитанции в Приемную комиссию → ознакомиться с приказом о зачислении.
                        </span>
                    </li>
                </ul>
            </nav>
            <span className="payment-methods__subtitle end">
                Если вы не прошли по конкурсу на бюджетную форму обучения, но набрали по вступительным испытаниям баллы выше минимальных установленных КГЭУ, то ваши результаты
                учитываются для поступления на платную форму обучения. Повторно сдавать вступительные испытания не нужно!
            </span>
            <span className="payment-methods__subtitle end">
                Если ваши баллы за предмет меньше установленного университетом минимума, то вы не можете участвовать в конкурсе на поступление. С минимальными (пороговыми)
                баллами вы можете ознакомиться <Link to="/" className="payment-methods__subtitle link">ЗДЕСЬ</Link>
            </span>
            <span className="payment-methods__subtitle end">
                Обратите внимание, что стоимость обучения может варьироваться в зависимости от направления и формы обучения. Стоимости образовательных программ располагается
                на сайте Приемной комиссии в разделе Абитуриенту — Стоимость обучения.
            </span>

            <span className="payment-methods__subtitle end">
                Со способами оплаты можете ознакомиться <Link to="/" className="payment-methods__subtitle link">ЗДЕСЬ</Link>
            </span>
            <Link to={dogovor} className="payment-methods__subtitle link" target="_blank">
                Типовой договор об оказании платных образовательных услуг в сфере высшего образования
            </Link>
            <Link to={kvitanciya} className="payment-methods__subtitle link" target="_blank">
                Квитанция на оплату
            </Link>
        </div>
    );
}
