import React from "react";
import { Link } from "react-router-dom";

import doc from "../../../assets/documents/Undergraduate/individualki.pdf";

import "./IndividualAchievements.css";

const items = [
    {
        subtitle: "+10",
        text: "Наличие аттестата (диплома) с отличием, медаль"
    },
    {
        subtitle: "≤10",
        text: "Спортивные достижения"
    },
    {
        subtitle: "≤10",
        text: "Олимпиады школьников"
    },
    {
        subtitle: "+2",
        text: "Волонтерство"
    },
]

export default function IndividualAchievements() {
    return (
            <div className="individual-achivments">
                <span className="individual-achivments__title">Учет индивидуальных достижений</span>
                <div className="individual-achivments__main-block">
                    {items.map((item, index) => (
                        <div className="individual-achivments__text-block" key={String(index)}>
                            <span className="individual-achivments__subtitle">{item.subtitle}</span>
                            <span className="individual-achivments__text">{item.text}</span>
                        </div>
                    ))}
                </div>
                <Link to={doc} className="individual-achivments__button" target="_blank">
                    <span className="individual-achivments__text-button">Узнать подробнее</span>
                </Link>
            </div>
    );
}
