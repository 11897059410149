import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsTechBezop.css";

const items = [
    {
        subtitle: "13",
        text: (
            <React.Fragment>
                Количество бюджетных <br />
                мест на оч/ф
            </React.Fragment>
        ),
    },
    {
        subtitle: "5",
        text: (
            <React.Fragment>
                Количество платных <br />
                мест на оч/ф
            </React.Fragment>
        ),
    },
    {
        subtitle: "174 890₽",
        text: "Стоимость платного обучения на оч/ф",
    },
];

export default function EducationalProgramsTechBezop() {

    return (
        <div className="educational-program magistracy">
            <span className="individual-achivments__title">Институт теплоэнергетики</span>


            <table className="educational-program__scroll-table">
                <tr className="educational-program__scroll-table-row one">
                    <div className="educational-program__scroll-table-column one">
                        <span className="educational-program__block-title">
                            Образовательные программы: <br />
                            «Техносферная безопасность»
                        </span>
                    </div>
                    <div className="educational-program__scroll-table-column two">
                        <span className="educational-program__block-title sub">
                            Бюджет очная
                            <br />
                            форма
                        </span>
                    </div>
                    <div className="educational-program__scroll-table-column three">
                        <span className="educational-program__block-title sub">
                            Бюджет заочная
                            <br />
                            форма
                        </span>
                    </div>
                    <div className="educational-program__scroll-table-column four">
                        <span className="educational-program__block-title sub">
                            Платные места
                            <br />
                            очная форма
                        </span>
                    </div>
                    <div className="educational-program__scroll-table-column five">
                        <span className="educational-program__block-title sub">
                            Платные места
                            <br />
                            заочная форма
                        </span>
                    </div>
                </tr>
                <tr className="educational-program__scroll-table-row">
                    <div className="educational-program__scroll-table-column one">
                        <Link to="/industrial-ecology-and-rational-use-of-natural-resources" className="educational-program__block-text">Промышленная экология и рациональное использование прориродных ресурсов</Link>
                    </div>
                    <div className="educational-program__scroll-table-column two">
                        <span className="educational-program__block-text">✓</span>
                    </div>
                    <div className="educational-program__scroll-table-column three">
                        <span className="educational-program__block-text">—</span>
                    </div>
                    <div className="educational-program__scroll-table-column four">
                        <span className="educational-program__block-text">✓</span>
                    </div>
                    <div className="educational-program__scroll-table-column five">
                        <span className="educational-program__block-text">—</span>
                    </div>
                </tr>
            </table>

            <div className="educational-program__main-block elect-magistr">
                {items.map((item, index) => (
                    <div className="individual-achivments__text-block" key={String(index)}>
                        <span className="individual-achivments__subtitle">{item.subtitle}</span>
                        <span className="individual-achivments__text">{item.text}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}