import React from "react";
import { Link } from "react-router-dom";

import StepsProcpectiveStudentMenu from "../../components/ComponentsProspeciveStudentPage/StepsProcpectiveStudentMenu/StepsProcpectiveStudentMenu";
import IndividualAchievements from "../../components/ComponentsProspeciveStudentPage/IndividualAchievements";
import KCP from "../../components/ComponentsProspeciveStudentPage/KCP";
import TuitionFees from "../../components/ComponentsProspeciveStudentPage/TuitionFees";
import EntranceTests from "../../components/ComponentsProspeciveStudentPage/EntranceTests";
import Scholarship from "../../components/ComponentsProspeciveStudentPage/Scholarship";
import EnergyLevels from "../../components/ComponentsProspeciveStudentPage/EnergyLevels";
import GrantRectora from "../../components/ComponentsProspeciveStudentPage/GrantRectora";
import PaymentMethods from "../../components/ComponentsProspeciveStudentPage/PaymentMethods";
import Calendar from "../../components/ComponentsProspeciveStudentPage/Calendar";
import Dormitory from "../../components/ComponentsProspeciveStudentPage/Dormitory";
import EducationalLoan from "../../components/ComponentsProspeciveStudentPage/EducationalLoan";

export const stepsProcpectiveStudent = [
  {
    title: "Календарь",
    anchor: "select-1",
    text: (
      <div className="step-procpective-student__padding">
        <Calendar />
      </div>
    ),
  },
  {
    title: "Этапы поступления",
    anchor: "select-2",
    text: (
      <div className="step-procpective-student__padding">
        <div className="step-procpective-student__row">
          <div className="step-procpective-student__column">
            <span className="step-procpective-student__column-title">
              Этапы поступления
            </span>
            <StepsProcpectiveStudentMenu />
          </div>
          <div className="step-procpective-student__column">
            <span className="step-procpective-student__column-title">
              Полезные ссылки
            </span>
            <div className="question-content__item-link-block">
              <a href="/undergraduate" className="question-content__link-button" target="_blank">
                Список образовательных программ
              </a>
              <a href="//kgeu.ru/Document/GetDocument/e5c4ccb6-f407-45a4-b25d-9eb7a866876f" className="question-content__link-button" target="_blank">
                Правила приема
              </a>
              <a href="/undergraduate" className="question-content__link-button" target="_blank">
                Вступительные испытания
              </a>
              <a href="/contacts" className="question-content__link-button">
                Контакты
              </a>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: "Учет индивидуальных достижений",
    anchor: "select-3",
    text: (
      <div className="step-procpective-student__padding">
        <IndividualAchievements />
      </div>
    ),
  },
  {
    title: "Контрольные цифры приема",
    anchor: "select-4",
    text: (
      <div className="step-procpective-student__padding">
        <KCP />
      </div>
    ),
  },
  {
    title: "Стоимость обучения",
    anchor: "select-5",
    text: (
      <div className="step-procpective-student__padding">
        <TuitionFees />
      </div>
    ),
  },
  {
    title: "Заселение в общежитие",
    anchor: "select-6",
    text: (
      <div className="step-procpective-student__padding">
        <Dormitory />
      </div>
    ),
  },
  {
    title: "Вступительные испытания",
    anchor: "select-7",
    text: (
      <div className="step-procpective-student__padding">
        <EntranceTests />
      </div>
    ),
  },
  {
    title: "Стипендия (бюджет и платная основа)",
    anchor: "select-8",
    text: (
      <div className="step-procpective-student__padding">
        <Scholarship />
      </div>
    ),
  },
  {
    title: "Бонусы для высокобальников",
    anchor: "select-9",
    text: (
      <div className="step-procpective-student__padding">
        <EnergyLevels />
      </div>
    ),
  },
  {
    title: "Грант Pектора на обучение",
    anchor: "select-10",
    text: (
      <div className="step-procpective-student__padding">
        <GrantRectora />
      </div>
    ),
  },
  {
    title: "Образовательный кредит",
    anchor: "select-11",
    text: (
      <div className="step-procpective-student__padding">
        <EducationalLoan />
      </div>
    ),
  },
  {
    title: "Способы оплаты",
    anchor: "select-12",
    text: (
      <div className="step-procpective-student__padding">
        <PaymentMethods />
      </div>
    ),
  },
];
