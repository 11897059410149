import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/pavlov.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/proectiroviecspl.pdf";

import img1 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img3.png";

import img_company3 from "../../../../assets/images/New/MagPEEOTeacher/companies/img_company3.png";
import img_company1 from "../../../../assets/images/New/MagPEEOTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagPEEOTeacher/companies/img_company2.png";

import "./PEEOTeacherPage.css";

export default function PEEOTeacherPage() {
	return (
		<React.Fragment>
			<TopBar />
			<TopBarProspectiveStudent />
			<Container>
				<div className="educational-program magistracy teacher peeo">
					<span className="teacher__title">
						Проектирование и эксплуатация электротехнического оборудования электромобилей, беспилотного транспорта и зарядной инфраструктуры
					</span>
					<div className="teacher__main-block">
						<div className="teacher__main-block-img">
							<img src={img} alt="" />
						</div>
						<div className="teacher__main-block-text">
							<span className="teacher__text">
								Руководитель образовательной программы
							</span>
							<span className="teacher__title">Павлов Павел Павлович</span>
							<span className="teacher__text">
                            к.т.н., доцент кафедры <br />
«Электротехнические комплексы и системы» <br />
(ЭТКС)
							</span>
							<div className="teacher__main-block-text-row">
								<span className="teacher__text">e-mail:</span>
								<span className="teacher__text">pavlov2510@mail.ru</span>
							</div>
							<div className="teacher__main-block-text-row">
								<span className="teacher__text">тел.:</span>
								<span className="teacher__text"> 8 (843) 519-43-12</span>
							</div>
							<span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. Б-116</span>
							<div className="teacher__main-block-text-row">
								<Link
									to="//priem.kgeu.ru/user/sign-in/login"
									className="teacher__button"
								>
									Подать документы
								</Link>
								{/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
							</div>
						</div>
						<div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
							Программа подготовки обеспечивает получение знаний и умений  в области проектирования и эксплуатации электротехнического оборудования электромобильного и беспилотного транспорта, зарядных станций.
							</span>
                        </div>
					</div>
					<div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Техническая эксплуатация электромобильного транспорта и зарядной инфраструктуры</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Системы интеллектуального мониторинга электротехнического оборудования электромобилей и зарядной инфраструктуры</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Интеллектуальные транспортные системы зарядные станции и элементы инфраструктуры для электромобилей</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Проектирование электротехнического оборудования беспилотного электротранспорта</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Автоматизированное проектирование и компьютерное моделирование электромобильных систем</span>
                                </div>
                               
                                
                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Машинное обучение в системах управления электротранспорта</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Электромагнитная совместимость на электротранспорте</span>
                                </div>
								<div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Интеллектуальные транспортные системы</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Разработчикрм гибридного транспорта</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Инженером-разработчиком зарядных станций</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Инженером-электромехаником</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
					{/* <QuestionPM /> */}
					
				</div>
			</Container>
			<Footer />
		</React.Fragment>
	);
}
