import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionSoc from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionSoc";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/nurullina.png";
import doc from "../../../../assets/documents/uch-plan/sociol.pdf";

import img1 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/SocTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/SocTeacher/large-frame-bg2.svg";
import "./SocTeacherPage.css";

export default function SocTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher soc">
                    <span className="teacher__title">Экономическая социология и маркетинг
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Нуруллина Эльмира Ринатовна</span>
                            <span className="teacher__text">к.с.н., доцент кафедры<br /> «Социология, политология и право» (СПП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">ksociology@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-14</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. Д-706</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>

                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>
                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Soc">О</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">—</span>
                        </div>
                    </div>
                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Современные методы социалогических <br />исследований</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Социальные явления общества</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Процессы управления персоналом и<br /> бизнес-процессами</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Методы обработки и анализа данных</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Государственное и административное <br />управление</span>
                                </div>
                            </div>
                        </div>

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1} /></div>
                                    <span className="text-line">Маркетологом, специалистом по маркетингу</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2} /></div>
                                    <span className="text-line">Социологом-аналитиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3} /></div>
                                    <span className="text-line">HR-специалистом</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img4} /></div>
                                    <span className="text-line">Интернет-маркетологом, Digital-маркетологом</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">
                            <img src={bg_left} />
                            <img src={bg_right} />
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Управление персоналом,<br /> бизнес-процессами и<br /> предпринимательской <br />деятельностью</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Организация и проведение соц.<br /> исследований</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Проведение социально-<br /> экономических исследований<br />  общества, явлений и<br />  процессов</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Организация бизнес-<br /> процессов на основе анализа<br />  результатов соц.<br />  исследований</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Организация и проведение социалогических,<br /> социально-экономических и маркетинговых<br /> исследований, исследование рынков</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionSoc /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}