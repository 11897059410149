import React from "react";

import "./LNRandDNR.css";

export default function LNRandDNR() {

    return (
        <div className="special-admission-conditional">
            <span className="special-admission-conditional__title">Прием граждан ЛНР, ДНР и Украины</span><br />
            <div className="special-admission-conditional__text-block">
                <span className="special-admission-conditional__text">Опубликовано постановление Правительства РФ от 21 марта 2022 г. № 434, которое определяет порядок приема в российские вузы следующих категорий абитуриентов:</span>
                <span className="special-admission-conditional__teext">• граждане РФ, которые жили и учились на территории ДНР, ЛНР или Украины;</span>
                <span className="special-admission-conditional__teext">• граждане ДНР, ЛНР или Украины, которые жили на этих территориях;</span>
                <span className="special-admission-conditional__teext last">• иностранные граждане, которые жили на территории ДНР, ЛНР или Украины.</span>

                <span className="special-admission-conditional__teext last">Если Вы относитесь к одной из этих категорий, для поступления в российский вуз по этим особенностям, Вам необходимо документально подтвердить пересечение
                    границы Российской Федерацией в 2022 году.</span>

                <span className="special-admission-conditional__text">Граждане РФ, которые жили и учились на территории ДНР, ЛНР или Украины, поступают по общему конкурсу, наравне с обычными абитуриентами из России:</span>
                <span className="special-admission-conditional__teext">• Поступление проходит на общих основаниях, по ранжированным спискам;</span>
                <span className="special-admission-conditional__teext">• Можно выбрать между сдачей ЕГЭ и вступительными испытаниями, который проводит Университет (можно сдать ЕГЭ и вступительные испытания
                    Университета, при этом учитываться будет наивысший результат);</span>
                <span className="special-admission-conditional__teext">• Экзамен по русскому языку проходит в форме собеседования;</span>
                <span className="special-admission-conditional__teext">• При зачислении разрешается не предоставлять оригинал документа об образовании с указанием причин отсутствия оригинала и последующем
                    предоставлением недостающего документа до окончания обучения в Университете;</span>
                <span className="special-admission-conditional__teext last">• При приеме учитываются индивидуальные достижения, полученные как на территории Российской Федерации, так и за ее пределами,
                    а также документы об образовании и (или) о квалификации с отличием, полученные за рубежом;</span>

                <span className="special-admission-conditional__teext last">Абитуриенты из ДНР, ЛНР и Украины имеющие в том числе гражданство РФ могут поступить в российские вузы по квоте Правительства РФ для приема иностранных
                    граждан. Это значит, что лица набранные в рамках квоты приема иностранных граждан, будут зачислены на дополнительные бюджетные места и не повлияют на выделенное
                    число бюджетных мест для Российских граждан. Набор в этом случае ведет Россотрудничество — это традиционный вариант поступления для иностранцев. Для уточнения
                    подробностей обращайтесь на горячую линию Министерства науки и высшего образования:</span>

                <span className="special-admission-conditional__teext">
                    <span className="special-admission-conditional__link">+7 (800) 301-44-55 </span>- для звонков по России
                </span>
                <span className="special-admission-conditional__teext">
                    <span className="special-admission-conditional__link">+7 (495) 122-22-68 </span>- для звонков из-за рубежа
                </span>
            </div>
        </div>
    );
}
