import React, { useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import Container from "../../Container";

// import firstSteps from "../../../assets/images/MainPage/News/FirstStepEnergy.png";
// import dormitory from "../../../assets/images/MainPage/News/Dormitory.png";
// import energy from "../../../assets/images/MainPage/News/Energy.png";
import siriusLeto from "../../../assets/documents/sirius-leto.pdf";
import sirius from "../../../assets/images/MainPage/News/sirius.png";

// import { menuItems } from "../../Constants";

import "./NewsMainPageCopy.css";

const NewsMainPage = (props) => {
  const [count, setCount] = useState(2);

  const { blocks } = props;

  function getCount() {
    const width = document.body.offsetWidth;
    if (width > 950) {
      setCount(3);
      return;
    }
    if (width > 515) {
      setCount(2);
      return;
    }
    setCount(1);
    return;
  }

  document.addEventListener("DOMContentLoaded", getCount);
  window.addEventListener("resize", getCount);

  return (
    <Container>
      <div className="news-main-page one">
        <div className="news-main-page__top-block">
          <span className="news-main-page__title">Новости</span>
          {/* <Link to="/" className="news-main-page__button level-learning__link one">
                        <span className="level-learning__text">Смотреть все</span>
                    </Link> */}
        </div>
        <Slider
          activeIndex={0}
          slidesToShow={count}
          slidesToScroll={count}
          dots
        >
          <div className="news-main-page__news-block">
            <div className="news-main-page__news-img">
                <a href={siriusLeto} alt="" target="_blank" rel="noreferrer">
                  <img src={sirius} alt="" />
                </a>
                <span className="news-main-page__news-subtitle">
                Событие
                </span>
            </div>
            <div className="news-main-page__news-block-text">
                <span className="news-main-page__news-data">2023-2024</span>
                <span className="news-main-page__news-title">
                Сириус.Лето
                </span>
                <span className="news-main-page__news-text">Программа Сирирус.Лето была представлена руководителем Фонда «Талант и успех» и образовательного центра</span>
            </div>
          </div>
          {blocks &&
            blocks.map((block, index) => (
              <div className="news-main-page__news-block" key={String(index)}>
                <div className="news-main-page__news-img">
                  {block.link && (
                    <a
                      href={block.link}
                      alt=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={block.image} alt="" />
                    </a>
                  )}
                  {block.marker && (
                    <span className="news-main-page__news-subtitle">
                      {block.marker}
                    </span>
                  )}
                </div>
                <div className="news-main-page__news-block-text">
                  {block.data && (
                    <span className="news-main-page__news-data">
                      {block.data}
                    </span>
                  )}
                  {block.title && (
                    <span className="news-main-page__news-title">
                      {block.title}
                    </span>
                  )}
                  {block.text && (
                    <span className="news-main-page__news-text">
                      {block.text}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </Slider>
      </div>
      {/* ----------------------------------от 1040px----------------------------------- */}
      <div className="news-main-page two">
        <div className="news-main-page__top-block">
          <span className="news-main-page__title">Новости</span>
          {/* <Link to="/" className="news-main-page__button level-learning__link one">
                        <span className="level-learning__text">Смотреть все</span>
                    </Link> */}
        </div>

        {/* 3 слайдер - https://alvarotrigo.com/blog/react-carousels/ */}

        <Slider
          activeIndex={0}
          slidesToShow={count}
          slidesToScroll={count}
          dots
        >
          {blocks &&
            blocks.map((block, index) => (
              <div className="news-main-page__news-block" key={String(index)}>
                <div className="news-main-page__news-img">
                  {block.link && (
                    <a
                      href={block.link}
                      alt=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={block.image} alt="" />
                    </a>
                  )}
                  {block.marker && (
                    <span className="news-main-page__news-subtitle">
                      {block.marker}
                    </span>
                  )}
                </div>
                <div className="news-main-page__news-block-text">
                  {block.data && (
                    <span className="news-main-page__news-data">
                      {block.data}
                    </span>
                  )}
                  {block.title && (
                    <span className="news-main-page__news-title">
                      {block.title}
                    </span>
                  )}
                  {block.text && (
                    <span className="news-main-page__news-text">
                      {block.text}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </Container>
  );
};

export default NewsMainPage;
