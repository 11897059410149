import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../../components/Footer";
import EducationalProgramsATP from "../../../../components/ComponentsMagistracyPage/ITE/EducationalProgramsATP";

import Container from "../../../../components/Container";

import "./ATPPage.css";

export default function ATPPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top magistracy abiturient">
                    <span className="etap-postupleniya__title">Магистратура</span>
                </div>
                {/* <div className="step-prospective-student__top undergraduate-ICTE-page educational-program abiturient">
                    <Link to="/undergraduate-magistracy-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-magistracy-ite" className="etap-postupleniya__title one">Институт теплоэнергетики</Link>
                    <Link to="/undergraduate-magistracy-icte" className="etap-postupleniya__title">Институт цифровых технологий и экономики</Link>
                </div> */}
                <EducationalProgramsATP />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
