import React from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";

import Burger from "../Burger";

import "./Modal.css";

function Modal(props) {
    const { close, visible = false } = props;
    // const { visible = false } = props;


    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("top-bar__modal-menu--has-children--active");
    };

    return (
        <div
            className={`modal-wrapper ${visible ? "modal-wrapper--show" : "modal-wrapper--hide"
                }`}
        >
            <Burger active={true} click={close} />
            <div className="modal">
                <nav className="top-bar__modal-navbar">
                    <ul className="top-bar__modal-topmenu">
                        <li className="top-bar__modal-menu"><Link to="/">Главная</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/contacts">Контакты</Link></li>
                        <li className="top-bar__modal-menu--has-children one" onClick={handleClickQuestion}>О нас
                            <ul className="top-bar__modal-submenu one">
                                <li className="top-bar__modal-submenu-link"><Link to="//kgeu.ru/Section?idSection=1&idSectionMenu=7">Официальные документы</Link></li>
                                <li className="top-bar__modal-submenu-link"><Link to="//disk.yandex.ru/i/iKwCXFfpwtLH7g">История КГЭУ</Link></li>
                                <li className="top-bar__modal-submenu-link"><Link to="//disk.yandex.ru/i/YpyXgOC4otX-mQ">КГЭУ в цифрах</Link></li>
                                                                <li className="top-bar__modal-submenu-link"><Link to="//kgeu.ru/Home/About/180">Общежития</Link></li>
                            </ul>
                        </li>
                        <li className="top-bar__modal-menu"><Link to="/prospective-student">Абитуриенту</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/undergraduate">Бакалавриат и специалитет</Link>
                            
                        </li>
                        <li className="top-bar__modal-menu"><Link to="/undergraduate-magistracy">Магистратура</Link>
                            
                        </li>
                        <li className="top-bar__modal-menu"><Link to="//kgeu.ru/Abitur/Aspirant">Аспирантура</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/olimpiads">Олимпиады</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/preparatory-courses">Подготовительные курсы</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/special-admission-conditions">Особые условия поступления</Link></li>
                        <li className="top-bar__modal-menu"><Link to="/categories-of-applicants">Категории поступающих</Link></li>
                        <li className="top-bar__modal-menu"><Link to="//kgeu.ru/Home/About/180">Общежития</Link></li> 
                        <li className="top-bar__modal-menu"><a href="//priem.kgeu.ru" target="_blank" className="lk">Подать документы</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
export default Modal;
