import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsTPEiTE.css";

const items = [
  {
    subtitle: "61",
    text: (
      <React.Fragment>
        Количество бюджетных <br />
        мест на оч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "10; 25",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "174 890₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  {
    subtitle: "67 500₽",
    text: "Стоимость платного обучения на заоч/ф",
  },
];

export default function EducationalProgramsTPEiTE() {
  return (
    <div className="educational-program magistracy">
      <span className="individual-achivments__title">
        Институт теплоэнергетики
      </span>

      <table className="educational-program__scroll-table">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Теплоэнергетика и теплотехника»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет очная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Бюджет заочная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/technology-of-electric-and-thermal-energy-production"
              className="educational-program__block-text"
            >
              Технология производства электрической и тепловой энергии
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/hydrogen-and-electrochemical-power-engineering"
              className="educational-program__block-text one"
            >
              Водородная и электрохимическая энергетика. Автономные
              энергетические системы
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/autonomousm-energy-systems"
              className="educational-program__block-text"
            >
              Проектирование теплоэнергетических систем предприятий и ЖКХ
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/energym-supply-of-enterprises"
              className="educational-program__block-text one"
            >
              Энергообеспечение предприятий
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        {/* <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/operationm-and-optimization-of-thermal-power-systems"
              className="educational-program__block-text"
            >
              Эксплуатация и оптимизация теплоэнергетических систем
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr> */}
        {/* <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/innovative-technologies-in-the-energy-sector-of-housing-and-communal-services"
              className="educational-program__block-text one"
            >
              Инновационные технологии в энергетике жилищно-коммунального
              хозяйства
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}
      </table>
      <div className="educational-program__main-block elect">
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
