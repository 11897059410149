import React from "react";

import TopBar from "../../../components/TopBar";
import Footer from "../../../components/Footer";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import OlimpiadsMain from "../../../components/ComponentsOlimpiadsPage/OlimpiadsMain";

import { olimpiadsMain } from "../../../constants";

import "./OlimpiadsMainPage.css";

function OlimpiadsMainPage() {
  return (
    <React.Fragment>
      <TopBar />
      <TopBarProspectiveStudent />

      <OlimpiadsMain blocks={olimpiadsMain} />
      <Footer />
    </React.Fragment>
  );
}

export default OlimpiadsMainPage;
