import React from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MRmPage, MainPage } from "./pages";

import Modal from "./components/Modal";

import "./App.css";

import {
	ProspectiveStudentPage,
	NewsPage,
	OlimpiadsMainPage,
	OlimpiadfirstSteps,
	ExcursionPage,
	PreparatoryCourses,
	CategoriesOfApplicantsPage,
	AfterColagePage,
	UndergraduateICTEPage,
	SpecialAdmissionConditionsPage,
	ContactsPage,
	PricladMathPage,
	InformAndVichTechPage,
	PricladInfPage,
	PriborPage,
	MechatrAndRobotPage,
	EconomPage,
	MenedchPage,
	SociolPage,
	RSOPage,
	PMTeacherPage,
	EconomTeacherPage,
	BuchTeacherPage,
	BiznesTeacherPage,
	IVTTeacherPage,
	IIUITeacherPage,
	PoTeacherPage,
	WebTeacherPage,
	MechTeacherPage,
	MenedchTeacherPage,
	PITeacherPage,
	PriborTeacherPage,
	RSOTeacherPage,
	SocTeacherPage,
	// UndergraduateITEPage,
	ElectronikaPage,
	ElectronikaTeacherPage,
	ElectroenergPage,
	RZATeacherPage,
	ESiSTeacherPage,
	VIETeacherPage,
	ElectrosnabTeacherPage,
	ElectrooborTeacherPage,
	ESiPTeacherPage,
	VEETeacherPage,
	EKSTeacherPage,
	EBTTeacherPage,
	CSASTeacherPage,
	EYETeacherPage,
	TechBezPage,
	IESTeacherPage,
	TechBezTeacherPage,
	TeplotechPage,
	TECTeacherPage,
	EHCSTeacherPage,
	AECTeacherPage,
	EnergoobTeacherPage,
	PTTeacherPage,
	PTSTeacherPage,
	EYPTTeacherPage,
	SGTTeacherPage,

	MaterialovedPage,
	MaterialovedTeacherPage,
	MashinoStrPage,
	MashinostrTeacherPage,
	AvtomatizPage,
	AvtomatizTeacherPage,
	VBAPage,
	AcvaTeacherPage,
	AtomPage,
	AtomTeacherPage,
	MiTSPage,
	MCTSTeacherPage,

	ProspectiveStudentMagistraturPage,
	UndergraduateMagistracyPage,
	UndergraduateMagistracyMainDatePage,
	UndergraduateMagistracyDocumentsPage,
	UndergraduateMagistracyPortfolioPage,
	// UndergraduateICTEMagistracyPage,
	// UndergraduateIEEMagistracyPage,
	// UndergraduateITEMagistracyPage,
	TPEiTEPage,
	ATPPage,
	TechFizPage,
	YTSPage,
	VBAmPage,
	StroitPage,
	MRPage,
	ElectrNanoPage,
	EEPage,
	ERSPPage,
	TechBezopPage,
	IVTmPage,
	PImPage,
	PriborMPage,
	MenedchmentMPage,
	PEPage,
	NETPage,

	

	DENETeacherPage,
	MechmTeacherPage,
	EETTeacherPage,
	TeploTechnikTeacherPage,
	VodorodTeacherPage,
	ProectirovanieTeploTeacherPage,
	EnergoObecpechTeacherPage,
	ExpluataciyaTeacherPage,
	ATPPmTeacherPage,
	TeploFizTeacherPage,
	YTSmTeacherPage,
	AcvMTeacherPage,
	PromishmTeacherPage,
	IntellecTeacherPage,
	ECCETeacherPage,
	PRSETeacherPage,
	AETeacherPage,
	TIOPTeacherPage,
	PEEOTeacherPage,
	PERIPTeacherPage,
	TRSITeacherPage,
	IIITeacherPage,
	MSPOITeacherPage,
	IMSDCTeacherPage,
	SMYOTeacherPage,
	MCMTeacherPage,
	DokumPage,
	DOYCTeacherPage,
	OlimpiadTinchurin,
	StroitTeacherPage,
	InTechTeacherPage,
	EOPmTeacherPage,
	EESiSTeacherPage,
	DesignTeacherPage,
	DigitalTechnologiesTeacherPage,
	MaterialsTeacherPage,
	WithoutIntroductoryPage,
	PayStudyPage,
	InostrancyPage,
	UHESTeacherPage,
} from "./pages";

function App() {
	const { modal } = useSelector((state) => state);
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			<Routes>
				<Route path="/" element={<MainPage />} />
				<Route path="/contacts" element={<ContactsPage />} />
				{/* ----------------АБИТУРИЕНТУ------------ */}
				<Route
					path="/prospective-student"
					element={<ProspectiveStudentPage />}
				/>
				<Route
					path="/prospective-student-magistracy"
					element={<ProspectiveStudentMagistraturPage />}
				/>
				{/* ----------------ОСТАЛЬНЫЕ-------------- */}
				<Route path="/news" element={<NewsPage />} />
				<Route path="/excursion" element={<ExcursionPage />} />
				<Route path="/preparatory-courses" element={<PreparatoryCourses />} />
				<Route
					path="/without-introductory"
					element={<WithoutIntroductoryPage />}
				/>
				<Route
					path="/categories-of-applicants"
					element={<CategoriesOfApplicantsPage />}
				/>
				<Route path="/after-collage" element={<AfterColagePage />} />
				<Route
					path="/special-admission-conditions"
					element={<SpecialAdmissionConditionsPage />}
				/>
				<Route path="/pay-study" element={<PayStudyPage />} />
				<Route
					path="/reception-of-foreign-citizens"
					element={<InostrancyPage />}
				/>
				{/* ----------------ОЛИМПИАДЫ-------------- */}
				<Route path="/olimpiads" element={<OlimpiadsMainPage />} />
				<Route
					path="/olimpiad-first-steps-of-energy"
					element={<OlimpiadfirstSteps />}
				/>
				<Route path="/tinchurin-readings" element={<OlimpiadTinchurin />} />
				{/* ----------------БАКАЛАВРИАТ------------ */}
				<Route path="/undergraduate" element={<UndergraduateICTEPage />} />
				{/* <Route path="/undergraduate-iee" element={<UndergraduateIEEPage />} />
        <Route path="/undergraduate-ite" element={<UndergraduateITEPage />} /> */}
				{/* ------------ОБР. ПРОГРАММЫ ИЦТЭ-------- */}
				<Route path="/applied-mathematics" element={<PricladMathPage />} />
				<Route
					path="/computer-science-and-engineering"
					element={<InformAndVichTechPage />}
				/>
				<Route path="/applied-computer-science" element={<PricladInfPage />} />
				<Route path="/instrumentation" element={<PriborPage />} />
				<Route
					path="/mechatronics-and-robotics"
					element={<MechatrAndRobotPage />}
				/>
				<Route path="/economy" element={<EconomPage />} />
				<Route path="/management" element={<MenedchPage />} />
				<Route path="/sociology" element={<SociolPage />} />
				<Route path="/advertising-and-public-relations" element={<RSOPage />} />
				<Route
					path="/documentation-and-archival-science"
					element={<DokumPage />}
				/>
				{/* -----------ПРЕПОДАВАТЕЛИ ИЦТЭ--------- */}
				<Route
					path="/mathematical-and-software-support-of-artificial-intelligence-systems"
					element={<PMTeacherPage />}
				/>
				<Route
					path="/economics-of-enterprises-and-organizations"
					element={<EconomTeacherPage />}
				/>
				<Route
					path="/accounting-analysis-and-audit-at-enterprises"
					element={<BuchTeacherPage />}
				/>
				<Route
					path="/business-analytics-and-digital-economy"
					element={<BiznesTeacherPage />}
				/>
				<Route
					path="/business-process-management-information-systems"
					element={<IVTTeacherPage />}
				/>
				<Route
					path="/intelligent-instrument-systems-and-industrial-internet"
					element={<IIUITeacherPage />}
				/>
				<Route
					path="/software-development-technologies"
					element={<PoTeacherPage />}
				/>
				<Route
					path="/technologies-for-the-development-of-information-systems-and-web-applications"
					element={<WebTeacherPage />}
				/>
				<Route path="/mechatronics" element={<MechTeacherPage />} />
				<Route
					path="/organization-management"
					element={<MenedchTeacherPage />}
				/>
				<Route
					path="/applied-computer-science-in-economics-and-data-analysis"
					element={<PITeacherPage />}
				/>
				<Route
					path="/automated-quality-control-and-diagnostic-systems"
					element={<PriborTeacherPage />}
				/>
				<Route
					path="/advertising-and-public-relations-in-the-commercial-sphere"
					element={<RSOTeacherPage />}
				/>
				<Route path="/design-in-advertising" element={<DesignTeacherPage />} />
				<Route
					path="/economic-sociology-and-marketing"
					element={<SocTeacherPage />}
				/>
				<Route
					path="/documentation-support-of-management-in-the-digital-environment"
					element={<DOYCTeacherPage />}
				/>
				{/* ------------ОБР. ПРОГРАММЫ ИЭЭ--------- */}
				<Route
					path="/electronics-and-nanoelectronics"
					element={<ElectronikaPage />}
				/>
				<Route
					path="/electric-power-and-electrical-engineering"
					element={<ElectroenergPage />}
				/>
				<Route path="/technosphere-safety" element={<TechBezPage />} />
				{/* -----------ПРЕПОДАВАТЕЛИ ИЭЭ----------- */}
				<Route
					path="/industrial-electronics"
					element={<ElectronikaTeacherPage />}
				/>
				<Route
					path="/materials-and-technologies-of-electronics"
					element={<MaterialsTeacherPage />}
				/>
				<Route
					path="/relay-protection-and-automation-of-electric-power-systems"
					element={<RZATeacherPage />}
				/>
				<Route
					path="/electric-power-systems-and-networks"
					element={<ESiSTeacherPage />}
				/>
				<Route path="/renewable-energy-sources" element={<VIETeacherPage />} />
				<Route path="/power-supply" element={<ElectrosnabTeacherPage />} />
				<Route
					path="/electrical-equipment-and-electrical-facilities-of-enterprises-organizations-and-institutions"
					element={<ElectrooborTeacherPage />}
				/>
				<Route
					path="/electric-stations-and-substations"
					element={<ESiPTeacherPage />}
				/>
				<Route
					path="/high-voltage-electric-power-and-electrical-engineering"
					element={<VEETeacherPage />}
				/>
				<Route
					path="/electromechanical-complexes-and-systems"
					element={<EKSTeacherPage />}
				/>
				<Route
					path="/electric-car-and-unmanned-transport"
					element={<EBTTeacherPage />}
				/>
				<Route
					path="/digital-electric-power-automation-systems"
					element={<CSASTeacherPage />}
				/>
				<Route
					path="/economics-and-management-in-the-electric-power-industry"
					element={<EYETeacherPage />}
				/>
				<Route
					path="/environmental-engineering-and-industrial-safety"
					element={<TechBezTeacherPage />}
				/>
				<Route
					path="/industrial-and-environmental-safety"
					element={<IESTeacherPage />}
				/>
				{/* ------------ОБР. ПРОГРАММЫ ИTЭ--------- */}
				<Route
					path="/heat-power-engineering-and-heat-engineering"
					element={<TeplotechPage />}
				/>
				<Route
					path="/materials-science-and-technology"
					element={<MaterialovedPage />}
				/>

				<Route
					path="/energy-power-engineering"
					element={<MashinoStrPage />}
				/>

				<Route
					path="/automation-of-technological-processes-and-productions"
					element={<AvtomatizPage />}
				/>
				<Route
					path="/aquatic-bioresources-and-aquaculture"
					element={<VBAPage />}
				/>
				<Route
					path="/nuclear-power-plants-design-operation"
					element={<AtomPage />}
				/>
				<Route
					path="/management-in-technical-systems"
					element={<MiTSPage />}
				/>
				{/* -----------ПРЕПОДАВАТЕЛИ ИTЭ----------- */}
				<Route path="/thermal-power-stations" element={<TECTeacherPage />} />
				<Route
					path="/energy-of-housing-and-communal-services"
					element={<EHCSTeacherPage />}
				/>
				<Route path="/autonomous-energy-systems" element={<AECTeacherPage />} />
				<Route
					path="/energy-supply-of-enterprises"
					element={<EnergoobTeacherPage />}
				/>
				<Route
					path="/industrial-thermal-power-engineering"
					element={<PTTeacherPage />}
				/>
				<Route
					path="/design-of-thermal-power-systems"
					element={<PTSTeacherPage />}
				/>
				<Route
					path="/economics-and-management-at-a-thermal-power-plant"
					element={<EYPTTeacherPage />}
				/>
				<Route
					path="/production-and-operation-of-gas-and-steam-turbines"
					element={<MashinostrTeacherPage />}
				/>
				<Route
					path="/computer-engineering-in-materials-science"
					element={<MaterialovedTeacherPage />}
				/>
				<Route
					path="/automaty-of-technological-processes-and-productions"
					element={<AvtomatizTeacherPage />}
				/>
				<Route
					path="/digital-engineering-technologies"
					element={<DigitalTechnologiesTeacherPage />}
				/>
				<Route
					path="/aquaculture"
					element={<AcvaTeacherPage />}
				/>
				<Route
					path="/design-and-operation-of-nuclear-power-plants"
					element={<AtomTeacherPage />}
				/>
				<Route
					path="/management-and-computer-science-in-the-field-of-technical-sciences"
					element={<MCTSTeacherPage />}
				/>
				<Route
					path="/steam-and-gas-turbines"
					element={<SGTTeacherPage />}
				/>

				{/* ----------------МАГИСТРАТУРА------------ */}
				<Route
					path="/undergraduate-magistracy"
					element={<UndergraduateMagistracyPage />}
				/>
				<Route
					path="/undergraduate-magistracy-main-date"
					element={<UndergraduateMagistracyMainDatePage />}
				/>
				<Route
					path="/undergraduate-magistracy-documents"
					element={<UndergraduateMagistracyDocumentsPage />}
				/>
				<Route
					path="/undergraduate-magistracy-concurs-portfolio"
					element={<UndergraduateMagistracyPortfolioPage />}
				/>

				{/* <Route
          path="/undergraduate-magistracy-icte"
          element={<UndergraduateICTEMagistracyPage />}
        />
        <Route
          path="/undergraduate-magistracy-iee"
          element={<UndergraduateIEEMagistracyPage />}
        />
        <Route
          path="/undergraduate-magistracy-ite"
          element={<UndergraduateITEMagistracyPage />}
        /> */}

				{/* ------------ОБР. ПРОГРАММЫ ИЦТЭ--------- */}
				<Route
					path="/computerm-science-and-engineering"
					element={<IVTmPage />}
				/>
				<Route path="/appliedm-computer-science" element={<PImPage />} />
				<Route path="/instrumentationm" element={<PriborMPage />} />
				<Route path="/managementm" element={<MenedchmentMPage />} />

				{/* -----------ПРЕПОДАВАТЕЛИ ИЦТЭ----------- */}
				<Route
					path="/technologies-for-the-development-and-maintenance-of-intelligent"
					element={<TRSITeacherPage />}
				/>
				<Route
					path="/artificial-entelligence-engineering"
					element={<IIITeacherPage />}
				/>
				<Route
					path="/microprocessor-tools-and-measurement-software"
					element={<MSPOITeacherPage />}
				/>
				<Route
					path="/intelligent-medical-systems-devices-and-complexes"
					element={<IMSDCTeacherPage />}
				/>
				<Route
					path="/strategic-management-and-organization-management"
					element={<SMYOTeacherPage />}
				/>
				<Route
					path="/media-communications-management"
					element={<MCMTeacherPage />}
				/>

				{/* ------------ОБР. ПРОГРАММЫ ИТЭ---------- */}
				<Route
					path="/mechatronics-and-roboticsm"
					element={<MRmPage />}
				/>
				<Route
					path="/power-engineering"
					element={<PEPage />}
				/>
				<Route
					path="/nuclear-energy-and-thermophysics"
					element={<NETPage />}
				/>
				<Route
					path="/automation-of-technological-processes"
					element={<ATPPage />}
				/>
				<Route
					path="/undergraduate-magistracy-heat-power-engineering-and-heat-engineering"
					element={<TPEiTEPage />}
				/>
				<Route
					path="/automationm-of-technological-processes"
					element={<ATPPage />}
				/>
				<Route 
					path="/technicalm-physics" 
					element={<TechFizPage />} 
				/>
				<Route 
					path="/managementm-in-technical-systems" 
					element={<YTSPage />} 
				/>
				<Route
					path="/magistracy-quatic-bioresources-and-aquaculture"
					element={<VBAmPage />}
				/>
				<Route path="/construction" element={<StroitPage />} />
				{/* -----------ПРЕПОДАВАТЕЛИ ИТЭ------------ */}
				<Route
					path="/mechatronicsm"
					element={<MechmTeacherPage />}
				/>
				<Route
					path="/digital-engineering-in-nuclear-energy"
					element={<DENETeacherPage />}
				/>
				<Route
					path="/energy-efficient-and-environmentally-friendly-technologies-at-fuel-and-energy-enterprises"
					element={<EETTeacherPage />}
				/>
				<Route
					path="/technology-of-electric-and-thermal-energy-production"
					element={<TeploTechnikTeacherPage />}
				/>
				<Route
					path="/innovative-technologies-in-the-energy-sector-of-housing-and-communal-services"
					element={<InTechTeacherPage />}
				/>
				<Route
					path="/hydrogen-and-electrochemical-power-engineering"
					element={<VodorodTeacherPage />}
				/>
				<Route
					path="/autonomousm-energy-systems"
					element={<ProectirovanieTeploTeacherPage />}
				/>
				<Route
					path="/energym-supply-of-enterprises"
					element={<EnergoObecpechTeacherPage />}
				/>
				<Route
					path="/operationm-and-optimization-of-thermal-power-systems"
					element={<ExpluataciyaTeacherPage />}
				/>
				<Route
					path="/automationm-of-technological-processes-and-productions"
					element={<ATPPmTeacherPage />}
				/>
				<Route 
					path="/thermophysics"
					element={<TeploFizTeacherPage />}
				/>
				<Route
					path="/management-in-technical-systems"
					element={<YTSmTeacherPage />}
				/>
				<Route
					path="/aquaculturem"
					element={<AcvMTeacherPage />}
				/>
				<Route
					path="/design-operation-and-reconstruction-of-buildings-and-structures"
					element={<StroitTeacherPage />}
				/>

				{/* ------------ОБР. ПРОГРАММЫ ИЭЭ---------- */}
				<Route
					path="/electronicsm-and-nanoelectronics"
					element={<ElectrNanoPage />}
				/>
				<Route
					path="/electricm-power-and-electrical-engineering"
					element={<EEPage />}
				/>
				<Route
					path="/energy--and-resource-saving-processes-in-chemical-technology,-petrochemistry-and-biotechnology"
					element={<ERSPPage />}
				/>
				<Route
					path="/technospherem-safety"
					element={<TechBezopPage />}
				/>

				{/* -----------ПРЕПОДАВАТЕЛИ ИЭЭ------------ */}

				<Route
					path="/industrial-electronics-and-microprocessor-technology"
					element={<PromishmTeacherPage />}
				/>
				<Route
					path="/intelligent-energy-systems"
					element={<IntellecTeacherPage />}
				/>
				<Route
					path="/ilectric-power-systems-networks-power-transmission-their-modes-stability-and-reliability"
					element={<ECCETeacherPage />}
				/>
				<Route
					path="/design-of-developing-power-supply-systems"
					element={<PRSETeacherPage />}
				/>
				<Route
					path="/automation-of-power-systems"
					element={<AETeacherPage />}
				/>
				<Route
					path="/technical-and-information-support-for-the-design-and-operation-of-the-electric-power-industry-of-consumers"
					element={<TIOPTeacherPage />}
				/>
				<Route
					path="/design-and-operation-of-electrical-equipment-for-electric-vehicles-unmanned-vehicle-and-charging-infrastructure"
					element={<PEEOTeacherPage />}
				/>
				<Route
					path="/operation-of-high-speed-electric-rolling-stock"
					element={<UHESTeacherPage />}
				/>
				<Route
					path="/industrial-ecology-and-rational-use-of-natural-resources"
					element={<PERIPTeacherPage />}
				/>
				<Route
					path="/economics-and-management-in-the-electric-power-industry-magistracy"
					element={<EOPmTeacherPage />}
				/>
				<Route
					path="/electromechanical-and-electronic-systems-of-automation-of-processes-and-productions"
					element={<EESiSTeacherPage />}
				/>
			</Routes>
			<Modal
				close={() => dispatch({ type: "hide" })}
				visible={modal && modal.visible}
			></Modal>{" "}
		</React.Fragment>
	);
}

export default App;
