import React from "react";
// import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";
import StepsProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import Container from "../../../components/Container";

import { stepsUndergraduateICTE } from "../../../constants";

import "./UndergraduateICTEPage.css";

export default function UndergraduateICTEPage(props) {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top abiturient">
                    <span className="etap-postupleniya__title">Бакалавриат, специалитет</span>
                </div>
                {/* <div className="step-prospective-student__top undergraduate-ICTE-page abiturient">
                    <Link to="/undergraduate-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-ite" className="etap-postupleniya__title">Институт теплоэнергетики</Link>
                    <span className="etap-postupleniya__title one">Институт цифровых технологий и экономики</span>
                </div> */}
            </Container>
            <div className="undergraduate-ICTE-page__main abiturient">
                <StepsProspectiveStudent items={stepsUndergraduateICTE} />
            </div>
            <Footer />
        </React.Fragment>
    );
}
