import React from "react";

import Container from "../../../Container";

// import { menuItems } from "../../Constants";

import "./CalendarMagistracy.css";


const items = [
    {
        title: "Очная форма бюджет",
        anchor: "select-1",
        text: (
            <div className="calendar__data-block">
                    <div className="calendar__data">
                        <span className="calendar__data-title">17 июня</span>
                        <span className="calendar__data-text">Начало приема документов</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">22 июля</span>
                        <span className="calendar__data-text">Завершение приема документов, необходимых для поступления</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">26 июля</span>
                        <span className="calendar__data-text">Завершение вступительных испытаний</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">31 июля</span>
                        <span className="calendar__data-text">Публикация на официальном сайте КГЭУ конкурсных списков</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">7 августа</span>
                        <span className="calendar__data-text">Завершение предоставления согласия на зачисление по очной форме обучения</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">9 августа</span>
                        <span className="calendar__data-text">Издание приказа о зачислении лиц, поступающих в магистратуру по очной форме обучения</span>
                    </div>
            </div>
        ),
    },
    {
        title: "Все формы обучения платная основа",
        anchor: "select-2",
        text: (
            <div className="calendar__data-block">
                    <div className="calendar__data">
                        <span className="calendar__data-title">17 июня</span>
                        <span className="calendar__data-text">Начало приема документов</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">17 июня - 28 августа</span>
                        <span className="calendar__data-text">Сроки вступительных испытаний, проводимых КГЭУ самостоятельно</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">20 августа</span>
                        <span className="calendar__data-text">Завершение приема документов</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">29 августа</span>
                        <span className="calendar__data-text">Завершение предоставления согласия за зачисление</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">29 августа</span>
                        <span className="calendar__data-text">Завершение приема квитанций об оплате</span>
                    </div>
                    <div className="calendar__data">
                        <span className="calendar__data-title">30 августа</span>
                        <span className="calendar__data-text">Завершение зачисления</span>
                    </div>
            </div>
        ),
    },
];



export default function CalendarMagistracy() {
    const deleteActiveClassToTab = (activeTab = null) => {
        if (activeTab) {
            const tabs = document.querySelectorAll(
                ".calendar__col .calendar__item"
            );
            tabs.forEach((content) => {
                content.classList.remove("calendar__item--active");
            });
            activeTab.classList.add("calendar__item--active");
        }
    };

    const deleteActiveClassToContent = (href = null) => {
        if (href) {
            const contentTabs = document.querySelectorAll(
                ".calendar__content-tab"
            );
            contentTabs.forEach((content) => {
                content.classList.remove("calendar__content-tab--active");
            });
            [...contentTabs]
                .find((tab) => {
                    return tab.getAttribute("data-id") === href;
                })
                .classList.add("calendar__content-tab--active");
        }
    };

    const handleClickTab = (e) => {
        const target = e.currentTarget;
        const href = target.getAttribute("data-href");
        deleteActiveClassToTab(target);
        deleteActiveClassToContent(href);
    };
    return (
            <div className="calendar magistracy">
                <span className="individual-achivments__title">Календарь</span>
                <div className="calendar__content-row">
                    <span className="calendar__title">Форма обучения</span>
                    <div className="calendar__content-col">
                        <div className="calendar__col">
                            {items.map((item, index) => (
                                <div
                                    className={`calendar__item ${index === 0 && "calendar__item--active"
                                        }`}
                                    data-href={item.anchor}
                                    key={String(index)}
                                    onClick={handleClickTab}
                                >
                                    <span className="calendar__item-title">{item.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="calendar__col col__two">
                    {items.map((item, index) => (
                        <div
                            data-id={item.anchor}
                            key={item.anchor}
                            className={`calendar__content-tab ${index === 0 && "calendar__content-tab--active"
                                }`}
                        >
                            <span className="calendar__text">{item.text}</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}
