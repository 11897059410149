import React from "react";

import TopBar from "../../components/TopBar";
import SliderMainPage from "../../components/ComponentsMainPage/SliderMainPage";
import LevelLearning from "../../components/ComponentsMainPage/LevelLearning";
import CategoryAbiturientov from "../../components/ComponentsMainPage/CategoryAbiturientov";
// import NewsMainPage from "../../components/ComponentsMainPage/NewsMainPage";
import NewsMainPageCopy from "../../components/ComponentsMainPage/NewsMainPageCopy";
// import LastChanges from "../../components/ComponentsMainPage/LastChanges";
import Footer from "../../components/Footer";

// import {newsMainPage, lastChangesMainPage} from "../../constants";
import { newsMainPage } from "../../constants";

import "./MainPage.css";

function MainPage() {
  return (
    <React.Fragment>
      <TopBar />

      <SliderMainPage />
      <LevelLearning />
      <CategoryAbiturientov />
      {/* <NewsMainPage blocks={newsMainPage}/> */}
      <NewsMainPageCopy blocks={newsMainPage} />
      {/* <LastChanges changes={lastChangesMainPage}/> */}

      <Footer />
    </React.Fragment>
  );
}

export default MainPage;
