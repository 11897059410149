import React from "react";
import { Link } from "react-router-dom";

import Container from "../../Container";
import gg from "../../../assets/images/Olimpiads/olimpiads_labels/img_olimpiad_vsesib.png";
import arrow from "../../../assets/images/arrow.svg";

import "./OlimpiadsMain.css";

const OlimpiadsMain = (props) => {
  const {blocks} = props;

  return (
    <Container>
      <div className="olimpiad-main">
        {/* <div className="olimpiad-main__topmenu">
          <span className="olimpiad-main__topmenu-text">Главная</span>
          <span className="olimpiad-main__topmenu-text">
            <img src={arrow} alt="" />
          </span>
          <span className="olimpiad-main__topmenu-text">Абитуриенту</span>
          <span className="olimpiad-main__topmenu-text">
            <img src={arrow} alt="" />
          </span>
          <span className="olimpiad-main__topmenu-text">Олимпиады</span>
        </div> */}
        <span className="olimpiad-main__title">Олимпиады</span>
        <div className="olimpiad-main__block">
          {blocks.map((block, index) => (
              <div className="olimpiad-main__info-block" key={String(index)}>
                <span> {block.status}</span>
                <span className="olimpiad-main__info-title">{block.title}</span>
                <div className="olimpiad-main__info">{block.text}</div>
                <Link to={block.link} className="olimpiad-main__info-link">
                  <span className="olimpiad-main__info-button-text">
                    {block.btntext}
                  </span>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default OlimpiadsMain;
