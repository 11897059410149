import React from "react";
import { Link } from "react-router-dom";

import "./UndegraduateMagistracyInfo.css";

export default function UndegraduateMagistracyInfo() {
  return (
    <div className="undegraduate-ICTE-info magistracy">
      {/* <span className="undegraduate-ICTE-info__title">Институт цифровых технологий и экономики</span> */}

      <div className="entrance-tests magistratura" id="mag_tbl">
        <span className="individual-achivments__title">
          Контрольные цифры приема и перечень вступительных испытаний
        </span>
        <table className="scroll-table">
          <tr className="undegraduate-ICTE-info">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <span className="acceptance-checksum__table-title-text">
                Код и наименование направления
              </span>
            </div>
            <div className="entrance-tests__column">
              <span className="acceptance-checksum__table-title-text">
                Бюджет очная <br />
                форма
              </span>
            </div>
            <div className="entrance-tests__column">
              <span className="acceptance-checksum__table-title-text">
                Платные места <br />
                очная форма
              </span>
            </div>
            <div className="entrance-tests__column">
              <span className="acceptance-checksum__table-title-text">
                Платные места <br />
                заочная форма
              </span>
            </div>
            <div className="entrance-tests__column">
              <span className="acceptance-checksum__table-title-text">
                Вступительные <br />
                испытания
              </span>
            </div>
          </tr>

          {/* 08.04.01 Строительство */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  08.04.01
                </span>
                <Link
                  to="/construction"
                  className="acceptance-checksum__table-text"
                >
                  Строительство
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">44</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">30</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 09.04.01 Информатика и вычислительная техника */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  09.04.01
                </span>
                <Link
                  to="/computerm-science-and-engineering"
                  className="acceptance-checksum__table-text"
                >
                  Информатика
                  <br />и вычислительная техника
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">38</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">10</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">20</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  09.04.03
                </span>
                <Link
                  to="/appliedm-computer-science"
                  className="acceptance-checksum__table-text"
                >
                  Прикладная
                  <br />информатика
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__subjects">
                  Междисциплинарный экзамен
                </span>
              </div>
          </tr> */}

          {/* 11.04.04 Электроника и наноэлектроника */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  11.04.04
                </span>
                <Link
                  to="/electronicsm-and-nanoelectronics"
                  className="acceptance-checksum__table-text"
                >
                  Электроника
                  <br />и наноэлектроника
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">33</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 12.04.01 Приборостроение */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  12.04.01
                </span>
                <Link
                  to="/instrumentationm"
                  className="acceptance-checksum__table-text"
                >
                  Приборостроение
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 13.04.01 Теплоэнергетика и теплотехника */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  13.04.01
                </span>
                <Link
                  to="/undergraduate-magistracy-heat-power-engineering-and-heat-engineering"
                  className="acceptance-checksum__table-text"
                >
                  Теплоэнергетика
                  <br />и теплотехника
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">61</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">10</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">25</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 13.04.02 Электроэнергетика и электротехника */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  13.04.02
                </span>
                <Link
                  to="/electricm-power-and-electrical-engineering"
                  className="acceptance-checksum__table-text"
                >
                  Электроэнергетика
                  <br />и электротехника
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">114</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">20</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">55</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 13.04.03 Энергетическое машиностроение */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  13.04.03
                </span>
                <Link
                  to="/power-engineering"
                  className="acceptance-checksum__table-text"
                >
                  Энергетическое машиностроение
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 14.04.01 Ядерная энергетика и теплофизика */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  14.04.01
                </span>
                <Link
                  to="/nuclear-energy-and-thermophysics"
                  className="acceptance-checksum__table-text"
                >
                  Ядерная энергетика и теплофизика
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">14</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 15.04.04 Автоматизация технологических процессов */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  15.04.04
                </span>
                <Link
                  to="/automationm-of-technological-processes"
                  className="acceptance-checksum__table-text"
                >
                  Автоматизация технологических процессов
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">19</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 15.04.06 Мехатроника и робототехника */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  15.04.06
                </span>
                <Link
                  to="/mechatronics-and-roboticsm"
                  className="acceptance-checksum__table-text"
                >
                  Мехатроника и робототехника
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">19</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 16.04.01 Техническая физика */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  16.04.01
                </span>
                <Link
                  to="/technicalm-physics"
                  className="acceptance-checksum__table-text"
                >
                  Техническая физика
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 18.04.02 Энерго- и ресурсосберегающие процессы в химической технологии, нефтехимии и биотехнологии */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  18.04.02
                </span>
                <Link
                  to="/energy--and-resource-saving-processes-in-chemical-technology,-petrochemistry-and-biotechnology"
                  className="acceptance-checksum__table-text"
                >
                  Энерго- и ресурсосберегающие процессы в химической технологии, нефтехимии и биотехнологии
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>

            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* 20.04.01 Техносферная безопасность */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  20.04.01
                </span>
                <Link
                  Link
                  to="/technospherem-safety"
                  className="acceptance-checksum__table-text"
                >
                  Техносферная безопасность
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">13</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>

          {/* <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-one">
            <div className="entrance-tests__column">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  35.04.08
                </span>
                <Link
                  to="/magistracy-quatic-bioresources-and-aquaculture"
                  className="acceptance-checksum__table-text"
                >
                  Водные биоресурсы
                  <br />и аквакульутра
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">15</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">5</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__subjects">
                  Междисциплинарный экзамен
                </span>
              </div>
          </tr> */}

          {/* 38.04.02 Менеджмент */}
          <tr className="acceptance-checksum__table-title-block undegraduate-ICTE-info-two">
            <div className="entrance-tests__column" id="mag_tb_fc_title">
              <div className="acceptance-checksum__table-title">
                <span className="acceptance-checksum__table-text">
                  38.04.02
                </span>
                <Link
                  to="/managementm"
                  className="acceptance-checksum__table-text"
                >
                  Менеджмент
                </Link>
              </div>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">—</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">50</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__table-text">50</span>
            </div>
            <div className="acceptance-checksum__table-title">
              <span className="acceptance-checksum__subjects">
                Междисциплинарный экзамен
              </span>
            </div>
          </tr>
        </table>
      </div>
    </div>
  );
}
