import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionEYE from "../../../../components/ComponentsUndergraduatePage/IEE/IEEQuestion/QuestionEYE";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/achmetova.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/economikavIEE.pdf";


import img1 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img1.png";
import img3 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img3.png";
import img2 from "../../../../assets/images/New/MagPRCETeacher/info-block/info_block_img2.png";

import img_company1 from "../../../../assets/images/New/MagEOPmTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagEOPmTeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagEOPmTeacher/companies/img_company3.png";
import "./EOPmTeacherPage.css";

export default function EOPmTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher eopm">
                    <span className="teacher__title">Экономика и управление в электроэнергетике
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ахметова Ирина Гареева</span>
                            <span className="teacher__text">д.т.н., заведующая кафедрой <br />
                            «Экономика и организация производства» (ЭОП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">irina_akhmetova@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-43-55</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. Д-627</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
							Программа направлена на подготовку высококвалифицированных специалистов, деятельность которых связана с разработкой системы бизнес-процессов, направленных на реализацию поставленных целей  и задач, ради которых существует организация.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Сметное дело в энергетике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Автоматизированные системы управления предприятием</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Бухгалтерский учет и анализ</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Налоговое планирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Организация производства на предприятии электроэнергетики</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Экономика электроэнергетики</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Управление затратами и контроллинг в электроэнергетике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">08</span>
                                    <span className="text-line">Экономическая оценка эффективности инвестиционных проектов и планов</span>
                                </div>
                                
                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Экономика и организация инновационной деятельности</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Проектное управление в электроэнергетике.</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Экономистом</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Управляющим делами</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Энергоменеджером</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionEYE /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}