import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionMech from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionMech";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/malev.jpg";
import doc from "../../../../assets/documents/uch-plan/mechanika.pdf";

import img1 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/MechTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/MechTeacher/large-frame-bg2.svg";

import "./MechTeacherPage.css";

export default function MechTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher mech">
                    <span className="teacher__title">Мехатроника
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Малев Николай Анатольевич</span>
                            <span className="teacher__text">к.т.н., доцент кафедры<br /> «Приборостроение и механика» (ПМ)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">maleeev@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-43-19</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. А-314а</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                    <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>
                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Phys">Ф</span>
                                </div>
                                <div className="subject">
                                    <span className="Chem">Х</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">221</span>
                        </div> 
                    </div>

                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Основы синтеза микропрограммных<br /> модулей мехатронных систем</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Основы механики и роботехники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Детали мехатронных модулей и их<br /> конструирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Программные средства<br /> микропроцессорного управления<br /> объектами мехатроники</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Компьютерный инжиниринг</span>
                                </div>
                            </div>
                        </div>  

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Разработчиком автоматизированных систем<br /> и средств управления</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Робототехником</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Кибернетиком</span>
                                </div> 
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img4}/></div>
                                    <span className="text-line">Инженером-конструктором в области<br /> искусственного интеллекта</span>
                                </div>
                            </div>
                            
                        </div>  
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">  
                            <img src={bg_left}/>
                            <img src={bg_right}/>
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Создание и конструирование<br /> роботов и роботехнических<br /> систем</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Подготовка техникоbr<br />-экономического обоснования<br /> проектов</span> 
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Компьютерное и<br /> математическое<br /> моделирование</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Производство и эксплуатация<br /> мехатронных и<br /> роботехнических систем</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Разработка программного обеспечения для<br /> проектирования систем, конструирования<br /> мехатронных модулей, управления и обработки<br /> информации</span> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionMech /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}