import React from "react";
import { Link } from "react-router-dom";

import napravleniya from "../../../assets/documents/Undergraduate/perechen-napravlenii.pdf";
import vstupitelnie from "../../../assets/documents/Undergraduate/vsupitelnie.pdf";

import "./StepsProcpectiveStudentMenu.css";

export default function StepsProcpectiveStudentMenu(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="step-prospective-student-menu">
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                1
                            </span>
                            <span className="question-content__item-title">
                                Выбор направления подготовки
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Поступление в Университет начинается не только с подготовки и сдачи ЕГЭ.
                            В первую очередь важно определить свою цель, выбрать Вуз и
                            образовательную программу на которую Вы хотите поступить.
                            В КГЭУ перечень образовательных программ достаточно широк.
                            Просмотрите его и определите свои приоритеты. Будьте внимательны,
                            не пропустите программу своей мечты!
                        </span>
                        <div className="question-content__item-text">
                            <Link to={napravleniya} className="question-content__link-button" target="_blank">Список образовательных программ, направления</Link>
                            {/* <Link to="/" className="question-content__link-button">Справочник абитуриента</Link> */}
                            <Link to={vstupitelnie} className="question-content__link-button" target="_blank">Вступительные испытания</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                2
                            </span>
                            <span className="question-content__item-title">
                                Подготовка документов
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Какие документы необходимы для поступления? В какие сроки осуществляется прием в ВУЗ?
                            Можно ли подать документы электронно или принести лично? Все это Вы можете узнать
                            из Правил приема в КГЭУ.
                        </span>
                        <div className="question-content__item-text">
                            <Link to="/" className="question-content__link-button">Правила приема</Link>
                            <Link to="/" className="question-content__link-button">Поступление после школы</Link>
                            <Link to="/" className="question-content__link-button">Поступление после колледжа</Link>
                            <Link to="/" className="question-content__link-button">Особые условия поступления</Link>
                            <Link to="/" className="question-content__link-button">Иностранным абитуриентам</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                3
                            </span>
                            <span className="question-content__item-title">
                                Прохождение конкурсного отбора
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Одним из самых волнительных этапов является этап прохождения конусного отбора.
                            Будьте внимательны, проверьте всю ли информацию о себе Вы предоставили в
                            Приемную комиссию. Отслеживайте рейтинговые списки, ведь именно от успешного
                            завершения этого этапа зависит то, на какую программу вы можете поступить.
                        </span>
                        <div className="question-content__item-text">
                            <Link to={vstupitelnie} className="question-content__link-button" target="_blank">Вступительные испытания</Link>
                            <Link to="/olimpiads" className="question-content__link-button">Олимпиады школьников</Link>
                            <Link to="/" className="question-content__link-button">Проходные баллы прошлых лет</Link>
                            <Link to="/" className="question-content__link-button">Рейтинговые списки</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                4
                            </span>
                            <span className="question-content__item-title">
                                Подача согласия на зачисление
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Итак, этап конкурсного отбора позади остался один шаг до поступления на программу
                            своей мечты, не пропусти его!
                        </span>
                        <span className="question-content__item-text">
                            Для зачисления в Университет важно подать не только документ об образовании, а еще и
                            "Согласие о зачислении" в установленные сроки. Отметьте в календаре даты начала
                            и окончания приема и запишите расписание работы приемной комиссии.
                        </span>
                        <div className="question-content__item-text">
                            <Link to="/contacts" className="question-content__link-button">Контакты</Link>
                            <Link to="/" className="question-content__link-button">Основные даты</Link>
                            <Link to="/" className="question-content__link-button">Подача согласия на зачисление</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                5
                            </span>
                            <span className="question-content__item-title">
                                Зачисление
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Вы прошли сложные испытания на экзаменах, однако то, что вам
                            пришлось преодолеть, — это только первый шаг и начало трудного,
                            но интересного пути к вершинам образования и получению интересной и
                            востребованной профессии.
                        </span>
                        <span className="question-content__item-text">
                            Сегодня вы вливаетесь в сплоченное десятитысячное сообщество студентов
                            КГЭУ. Перед вами распахнулись двери в новый мир. Узнайте как сделать
                            вашу жизнь в университете яркой и незабываемой с первого дня обучения!
                        </span>
                        <div className="question-content__item-text">
                            <Link to="/" className="question-content__link-button">Приказы</Link>
                            <Link to="//kgeu.ru/Home/About/180" className="question-content__link-button">Общежитие</Link>
                            <Link to="/" className="question-content__link-button">Научная и общественная жизнь</Link>
                            <Link to="/" className="question-content__link-button">Творчество и спорт</Link>
                        </div>
                    </li>
                </ul>
            </nav>

        </div>
    );
}
