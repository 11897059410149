import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";
// import StepsProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import InostranieStudentImg from "../../components/ComponentsBacalavriatPage/InostranieStudentImg";

import Container from "../../components/Container";

// import { stepsCategoriesOfApplicants } from "../../constants";

import "./CategoriesOfApplicantsPage.css";

// _______

import AdmissionAfterSchool from "../../components/ComponentsBacalavriatPage/AdmissionAfterSchool";
// import AdmissionAfterCollege from "../../components/ComponentsBacalavriatPage/AdmissionAfterCollege";
// import PaidBasis from "../../components/ComponentsBacalavriatPage/PaidBasis";
// import ReceptioOfForeignCitizens from "../../components/ComponentsBacalavriatPage/ReceptioOfForeignCitizens";
// import CDO from "../../components/ComponentsBacalavriatPage/CDO";

// _______

function CategoriesOfApplicantsPage() {
  return (
    <React.Fragment>
      <div className="categories-pf-applicants">
        <TopBar />
        <TopBarProspectiveStudent />
        <Container>
          <div className="step-prospective-student__top">
            <span className="etap-postupleniya__title">
              Категории поступающих
            </span>
          </div>
          <div className="step-prospective-student__top">
            <span className="etap-postupleniya__title one">
              Бакалавриат, специалитет
            </span>
          </div>
        </Container>
        {/* <StepsProspectiveStudent items={stepsCategoriesOfApplicants} /> */}

        <Container>
          <div className="step-prospective-student">
            <div className="etap-postupleniya__content-col">
              <div className="etap-postupleniya__col magistracy">
                <div className="progress__item">
                  <Link to="/after-collage" className="progress__item-title">
                    Поступление после колледжа
                  </Link>
                </div>
                <div className="progress__item--active">
                  <span className="progress__item-title">
                    Поступление после школы
                  </span>
                </div>
                <div className="progress__item">
                  <Link to="/without-introductory" className="progress__item-title">
                    Без вступительных испытаний
                  </Link>
                </div>
                <div className="progress__item">
                  <Link to="/pay-study" className="progress__item-title">
                    Платное обучение
                  </Link>
                </div>
                <div className="progress__item">
                  <Link to="/reception-of-foreign-citizens" className="progress__item-title">
                    Прием иностранных граждан
                  </Link>
                </div>
              </div>
              <div className="etap-postupleniya__col col__two">
                <div className="etap-postupleniya__content-tab--active">
                  <span className="etap-postupleniya__text">
                    <AdmissionAfterSchool />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CategoriesOfApplicantsPage;
