import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../../components/Footer";
import EducationalProgramsVBAm from "../../../../components/ComponentsMagistracyPage/ITE/EducationalProgramsVBAm";

import Container from "../../../../components/Container";

import "./VBAmPage.css";

export default function VBAmPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top magistracy abiturient">
                    <span className="etap-postupleniya__title">Магистратура</span>
                </div>
                <EducationalProgramsVBAm />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
