import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import whattsApp from "../../assets/images/WhattsApp.svg";
import mail from "../../assets/images/Mail.svg";
import vk from "../../assets/images/Vk.svg";
import telegram from "../../assets/images/Telegram.svg";

import Container from "../Container";

import "./Footer.css";


function Footer() {
    return (
        <React.Fragment>
            <Container>
                <div className="footer__top">
                    <div className="logo">
                        <Link to="/" className="logo__link">
                            <img src={logo} alt="Приемная Кампания" className="logo__img" />
                            <div className="logo__text">
                                <span className="logo__text-title">Приемная кампания</span>
                                <span className="logo__text-subtitle">Казанский государственный<br />Энергетический университет</span>
                            </div>
                        </Link>
                        <div className="logo__string"></div>
                    </div>
                    <div className="footer__main">
                        <div className="footer__text-column">
                            <span className="footer__title">Республика Татарстан, г. Казань, 420066, Кировский район,<br className="footer__br-one" />Красносельская, 51 (Приемная комиссия, корпус ”В”, В-100)</span>
                            <span className="footer__text">
                                <Link to="//yandex.ru/maps/-/CCUBBTrEHA" className="footer__text-link" target="_blank">Показать на карте</Link>
                            </span>
                        </div>
                        <div className="footer__text-column">
                            <span className="footer__title">8 (843) 519-42-42  /    <br className="footer__br" />519-42-23</span>
                            <span className="footer__text">Приемная комиссия</span>
                        </div>
                        <div className="footer__text-column">
                            <span className="footer__title">pr-kgeu@yandex.ru</span>
                            <span className="footer__text">E-mail</span>
                        </div>
                        <div className="footer__link-block">
                            <Link to="https://wa.me/79510618792?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!" className="footer__link one" target="_blank">
                                <img src={whattsApp} alt="" className="footer__link-img" />
                            </Link>
                            <Link to="mailto:pk@kgeu.ru" className="footer__link two" target="_blank">
                                <img src={mail} alt="" className="footer__link-img" />
                            </Link>
                            <Link to="//vk.com/abiturient_kgeu" className="footer__link three" target="_blank">
                                <img src={vk} alt="" className="footer__link-img" />
                            </Link>
                            <Link to="//t.me/kgeu_postupi" className="footer__link four" target="_blank">
                                <img src={telegram} alt="" className="footer__link-img" />
                            </Link>
                        </div>
                    </div>

                    {/* ---------- */}

                    <div className="footer__main-two">
                        <div className="footer__main-column">
                            <div className="footer__text-column">
                                <span className="footer__title">Республика Татарстан, г. Казань, 420066, Кировский район,<br />Красносельская, 51 (Приемная комиссия, корпус ”В”, В-100)</span>
                                <span className="footer__text">
                                    <Link to="//yandex.ru/maps/-/CCUBBTrEHA" className="footer__text-link" target="_blank">Показать на карте</Link>
                                </span>
                            </div>
                            <div className="footer__text-column">
                                <span className="footer__title">8 (843) 519-42-42  <br />8 (843) 519-42-23</span>
                                <span className="footer__text">Приемная комиссия</span>
                            </div>
                        </div>
                        <div className="footer__main-column down">
                            <div className="footer__text-column">
                                <span className="footer__text">E-mail</span>
                                <span className="footer__title">pk@kgeu.ru</span>
                            </div>

                            <div className="footer__link-block">
                                <Link to="https://wa.me/79510618792?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!" className="footer__link one" target="_blank">
                                    <img src={whattsApp} alt="" className="footer__link-img" />
                                </Link>
                                <Link to="mailto:pk@kgeu.ru" className="footer__link two" target="_blank">
                                    <img src={mail} alt="" className="footer__link-img" />
                                </Link>
                                <Link to="//vk.com/abiturient_kgeu" className="footer__link three" target="_blank">
                                    <img src={vk} alt="" className="footer__link-img" />
                                </Link>
                                <Link to="//t.me/kgeu_postupi" className="footer__link four" target="_blank">
                                    <img src={telegram} alt="" className="footer__link-img" />
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default Footer;
