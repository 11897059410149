import React from "react";

import SpecialAdmissionConditions from "../../components/ComponentsProspeciveStudentPage/SpecialAdmissionConditions";
import AdmissionToTargetedTraining from "../../components/ComponentsProspeciveStudentPage/AdmissionToTargetedTraining";
import LNRandDNR from "../../components/ComponentsProspeciveStudentPage/LNRandDNR";
import OtherKvot from "../../components/ComponentsProspeciveStudentPage/OtherKvot";

export const stepsSpecialAdmissionConditions = [
//   {
//     title: "Без вступительных испытаний",
//     anchor: "select-1",
//     text: (
//       <div className="step-procpective-student__padding">
//         <SpecialAdmissionConditions />
//       </div>
//     ),
//   },
  {
    title: "Прием на целевое обучение",
    anchor: "select-1",
    text: (
      <div className="step-procpective-student__padding">
        <AdmissionToTargetedTraining />
      </div>
    ),
  },
  {
    title: "Прием граждан ЛНР, ДНР и Украины",
    anchor: "select-2",
    text: (
      <div className="step-procpective-student__padding">
        <LNRandDNR />
      </div>
    ),
  },
  {
    title: "Отдельная квота",
    anchor: "select-3",
    text: (
      <div className="step-procpective-student__padding">
        <OtherKvot />
      </div>
    ),
  },
];
