import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import Footer from "../../../components/Footer";
import Container from "../../../components/Container";

import qr from "../../../assets/images/Olimpiads/qrFirstSteps.jpg";
import polochenie from "../../../assets/documents/Olimpiads/firstSteps/polochenie.pdf";
import appelyaciya from "../../../assets/documents/Olimpiads/firstSteps/appelyaciya.pdf";
import pobeditel from "../../../assets/documents/Olimpiads/firstSteps/opredelenie-pobeditelya.pdf";
import proverka from "../../../assets/documents/Olimpiads/firstSteps/kriterii-proverki.pdf";
import rezultati from "../../../assets/documents/Olimpiads/firstSteps/rezultati.pdf";

import "./OlimpiadfirstSteps.css";

function OlimpiadfirstSteps() {
    return (
        <React.Fragment>
            <TopBar />
            <Container>
                <div className="olimpiad-first-steps">
                    <div className="olimpiad-first-steps__img-block">
                        <div className="olimpiad-first-steps__img-block-text">
                            <span className="olimpiad-first-steps__block-teg">Статья</span>
                            <span className="olimpiad-first-steps__block-title">Первые шаги в энергетике</span>
                            <span className="olimpiad-first-steps__block-text">Уже в третий раз юные исследователи со всей Белгородской области защитили
                                исследовательские проекты на тему энергетики в рамках конкурса</span>
                        </div>
                    </div>
                    <div className="olimpiad-first-steps__main-block">
                        <div className="olimpiad-first-steps__main-block-column">
                            <span className="olimpiad-first-steps__main-block-column-title">Олимпиада проводится ежегодно с 1 сентября по 15 мая в два этапа, Отборочный и Заключительный этапы,
                                которые проводится в online форме с применением дистанционных образовательных технологий</span>
                            <span className="olimpiad-first-steps__main-block-column-title">Олимпиада проводится в онлайн-формате</span>
                            <span className="olimpiad-first-steps__main-block-column-title one">Для участия в отборочном этапе Олимпиады участники должны:</span>
                            <span className="olimpiad-first-steps__main-block-column-text">• пройти регистрацию на странице Олимпиады<br />
                                <br />• распечатать сформированную анкету участника и подписать согласие на использование персональных данных оргкомитетом Олимпиады
                                <br /> • выслать электронную копию анкеты участника и согласия на использование персональных данных оргкомитетом Олимпиады на электронную почту олимпиады</span>
                            <span className="olimpiad-first-steps__main-block-column-title one">Олимпиада проводится в онлайн-формате</span>
                            <span className="olimpiad-first-steps__main-block-column-text">• работа может быть создана с помощью текстового редактора или разборчиво написана от руки и отсканирована с разрешением не менее 300 DPI
                                <br />• работа (в том числе чертежи и рисунки) должна быть выполнена шрифтами чёрного или синего цвета или ручкой синего или чёрного цвета
                                <br />• работы принимаются в файлах форматов РDF или JPEG, файлы иных форматов не принимаются
                                <br />• нельзя указывать в работе фамилию, имя, отчество участника или иные пометки, позволяющие идентифицировать участника
                                <br />• нельзя делать в работе пометки, не относящиеся к заданию Олимпиады
                                <br />• дополнительные требования к оформлению работы могут быть приведены в заданиях Олимпиады
                            </span>
                        </div>
                        <div className="olimpiad-first-steps__main-block-column">
                            <div className="question-content__item-link-block one">
                                <Link to={polochenie} className="question-content__link-button" target="_blank">
                                    Положение
                                </Link>
                                <Link to={appelyaciya} className="question-content__link-button" target="_blank">
                                    Заявление на аппеляцию
                                </Link>
                                <Link to={pobeditel} className="question-content__link-button" target="_blank">
                                    Критерии определения победителей
                                </Link>
                                <Link to={proverka} className="question-content__link-button" target="_blank">
                                    Критерии проверки
                                </Link>
                                <Link to={rezultati} className="question-content__link-button" target="_blank">
                                    Результаты
                                </Link>
                            </div>
                            <div className="question-content__item-link-block">
                                <Link to="//drive.google.com/drive/folders/1PIA0N10tJEtHN9l56OjDCvj6g_kG-rEf?usp=sharing" className="question-content__link-button" target="_blank">
                                    Работы победителей и призеров
                                </Link>
                                <Link to="//drive.google.com/drive/folders/1RAbYIWA41tHNHXcs_XZg-jcqEu3ElU82?usp=sharing" className="question-content__link-button" target="_blank">
                                    Методические рекомендации
                                </Link>
                                <Link to="//drive.google.com/drive/folders/17FzxJvslFWu7DdgSbNIa_6jIZwyWoh4w?usp=sharing" className="question-content__link-button" target="_blank">
                                    Задания прошлых лет
                                </Link>
                            </div>
                            <div className="olimpiad-first-steps__main-block-img">
                                <img src={qr} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment>
    );
}

export default OlimpiadfirstSteps;
