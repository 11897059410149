import React from "react";

import Container from "../../Container";

// import { menuItems } from "../../Constants";

import "./StepsProspectiveStudent.css";


// _________

// import AdmissionAfterSchool from "../../../components/ComponentsBacalavriatPage/AdmissionAfterSchool";
// import AdmissionAfterCollege from "../../../components/ComponentsBacalavriatPage/AdmissionAfterCollege";
// import PaidBasis from "../../../components/ComponentsBacalavriatPage/PaidBasis";
// import ReceptioOfForeignCitizens from "../../../components/ComponentsBacalavriatPage/ReceptioOfForeignCitizens";
// import CDO from "../../../components/ComponentsBacalavriatPage/CDO";

// import iee from "../../../assets/documents/Brochures/IEE.pdf";

// _________

export default function StepsProspectiveStudent(props) {
    const { items } = props;


    const deleteActiveClassToTab = (activeTab = null) => {
        if (activeTab) {
            const tabs = document.querySelectorAll(
                ".step-prospective-student__col .progress__item"
            );
            tabs.forEach((content) => {
                content.classList.remove("progress__item--active");
            });
            activeTab.classList.add("progress__item--active");
        }
    };

    const deleteActiveClassToContent = (href = null) => {
        if (href) {
            const contentTabs = document.querySelectorAll(
                ".etap-postupleniya__content-tab"
            );
            contentTabs.forEach((content) => {
                content.classList.remove("etap-postupleniya__content-tab--active");
            });
            [...contentTabs]
                .find((tab) => {
                    return tab.getAttribute("data-id") === href;
                })
                .classList.add("etap-postupleniya__content-tab--active");
        }
    };

    const handleClickTab = (e) => {
        const target = e.currentTarget;
        const href = target.getAttribute("data-href");
        deleteActiveClassToTab(target);
        deleteActiveClassToContent(href);
    };
    return (
        <Container>
            <div className="step-prospective-student">
                <div className="etap-postupleniya__content-col abiturient">
                    <div className="etap-postupleniya__col magistracy">
                        {items.map((item, index) => (
                            <div
                                className={`progress__item last ${index === 0 && "progress__item--active"
                                    }`}
                                data-href={item.anchor}
                                key={String(index)}
                                onClick={handleClickTab}
                            >
                                <span className="progress__item-title" id={item.id}>{item.title}</span>
                            </div>
                        ))}
                        <div className="progress__item last raspisanie">
                            <a href="//kgeu.ru/Document/GetDocument/94c9071b-1cf8-4c0d-ad02-8165972e8196" target="_blank" className="progress__item-title">
                                Расписание экзаменов на очную форму
                            </a>
                        </div>
                        <div className="progress__item last raspisanie">
                            <a href="//kgeu.ru/Document/GetDocument/ee5344e4-5137-4a66-920b-229b8792ab9a" target="_blank" className="progress__item-title">
                                Расписание экзаменов на заочную форму
                            </a>
                        </div>
                        <div className="progress__item last raspisanie">
                            <a href="//kgeu.ru/Document/GetDocument/05b3676f-419b-4062-b039-de4c9091018b" target="_blank" className="progress__item-title">
                                Расписание экзаменов на платную форму
                            </a>
                        </div>
                        <div className="progress__item last raspisanie">
                            <a href="//kgeu.ru/Document/GetDocument/13313643-a348-4b45-81f6-2c74090d2b49" target="_blank" className="progress__item-title">
                                Расписание экзаменов дистанционный формат
                            </a>
                        </div>
                    </div>
                    <div className="etap-postupleniya__col col__two">
                        {items.map((item, index) => (
                            <div
                                data-id={item.anchor}
                                key={item.anchor}
                                className={`etap-postupleniya__content-tab ${index === 0 && "etap-postupleniya__content-tab--active"
                                    }`}
                            >
                                <span className="etap-postupleniya__text">{item.text}</span>
                            </div>
                        ))}
                    </div>

                </div>
            </div>


        </Container>

    );
}
