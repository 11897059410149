// import React from "react";
import React, { useState } from "react";

import Slider from "react-slick";

import Container from "../../Container";

// import doc from "../../../assets/images/MainPage/News/document.png";

import "./LastChanges.css";


const LastChanges = (props) => {

    const [count, setCount] = useState(2);

    const { changes } = props;

    function getCount() {

        const width = document.body.offsetWidth;
        if (width > 680) {
            setCount(2);
            return;
        }
        setCount(1);
        return;

    }

    document.addEventListener('DOMContentLoaded', getCount)
    window.addEventListener("resize", getCount);


    return (
        <React.Fragment>
            <Container className="last-change">
                <div className="slider-news-wrapper one">
                    <span className="slider-news-wrapper__title">Важно</span>

                    {/* 3 слайдер - https://alvarotrigo.com/blog/react-carousels/ */}

                    <Slider
                        activeIndex={0}
                        slidesToShow={count}
                        slidesToScroll={1}
                        vertical={true}
                    >

                        {changes &&
                            changes.map((change, index) => (
                                <div className="slider-news-wrapper__block-text" key={String(index)}>
                                    <span className="slider-news-wrapper__block-subtitle">{change.subtitle}</span>
                                    <span className="slider-news-wrapper__block-title">{change.title}</span>
                                    {change.link && (
                                        <a href={change.link} target="_blank" className="slider-news-wrapper__block-link">
                                            <span className="slider-news-wrapper__text">Подробнее</span>
                                        </a>
                                    )}
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </Container>

            {/* ----------------------------------от 1040px----------------------------------- */}
            {/* <div className="slider-news-wrapper two">
                <span className="slider-news-wrapper__title">Последние обновления</span>

                3 слайдер - https://alvarotrigo.com/blog/react-carousels/ 

                <Slider
                    activeIndex={0}
                    slidesToShow={count}
                    slidesToScroll={count}
                    dots
                >

                    {changes &&
                        changes.map((change, index) => (
                            <div className="slider-news-wrapper__block-text" key={String(index)}>
                                <span className="slider-news-wrapper__block-subtitle">{change.subtitle}</span>
                                <span className="slider-news-wrapper__block-title">{change.title}</span>
                                {change.link && (
                                    <Link to={change.link} className="slider-news-wrapper__block-link">
                                        <img src={doc} alt="" className="slider-news-wrapper__link-img" />
                                        <span className="slider-news-wrapper__text">Открыть</span>
                                    </Link>
                                )}
                            </div>
                        ))
                    }
                </Slider>
            </div> */}
        </React.Fragment >
    );
};

export default LastChanges;
