import React from "react";

import "./QuestionVIE.css";

export default function QuestionVIE(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="step-prospective-student-menu question">
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Объект, область профессиональной деятельности студента
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            На кафедре «Возобновляемые источники энергии» (ВИЭ) обучающиеся изучают теоретические и физические основы ВИЭ, общие характеристики энергоустановок на основе ВИЭ, основное и вспомогательное оборудование энергетических комплексов с применением ВИЭ, способы выбора параметров установок и комплексов на базе ВИЭ, способы монтажа и наладки оборудования в энергоустановках на ВИЭ, методы планирования режимов работы энергетических систем на базе ВИЭ. Студенты в процессе обучения активно участвуют в научно-исследовательских работах кафедры по
                            направлениям ветроэнергетики, солнечной энергетики, водородной энергетики и энергетических накопителей.
                        </span>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Базы прохождения практик
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Пройдя обучение на кафедре ВИЭ, вы получите все необходимые компетенции, которые позволят вам стать востребованным и высокооплачиваемым специалистом как для рынка ВИЭ, так и для любой электроэнергетической компании России. Обучение по этому профилю позволит вам получить знания и умения, необходимые для строительства,
                            эксплуатации и сервиса энергетических установок и комплексов на основе возобновляемых источников энергии.
                        </span>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Трудоустройство выпускников
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Выпускники кафедры востребованы такими ведущими энергетическими предприятиями как АГК1, ПАО «Фортум»,
                            ГК «Hevel», ПАО «Enel Россия», ООО «ГК «Ветропарки Симбирский», ГК «Энергия солнца», ООО «Solar Systems», ПАО «ВетроОГК».
                        </span>

                    </li>
                </ul>
            </nav>

        </div>
    );
}
