import React from "react";
import { Link } from "react-router-dom";

import img from "../../../assets/images/ProspectiveStudent/qr.png";
import doc from "../../../assets/documents/Undergraduate/OnePage.pdf";

import "./EducationalLoan.css";

export default function EducationalLoan() {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };
    return (
        <div className="educational-loan">
            <span className="individual-achivments__title">Образовательный кредит СберБанка с государственной поддержкой</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Условия образовательного кредита с государственной поддержкой</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">• Можно оформить кредит <span className="tuition-fees__text blue">с 14 лет</span></span>
                    <span className="tuition-fees__text">• Вы платите только <span className="tuition-fees__text blue">3% годовых</span></span>
                    <span className="tuition-fees__text">• Увеличивайте сумму кредита, если стоимость обучения выросла</span>
                    <span className="tuition-fees__text">• Досрочное погашение кредита без комиссий</span>
                    <div className="div-row">
                        <div className="question-content__item-text one">
                            <Link to={doc} className="question-content__link-button" target="_blank">
                                Подробнее
                            </Link>
                        </div>
                        <div className="question-content__item-text">
                            <Link to="//forms.yandex.ru/cloud/62baa64e0b13a17b9172276a/" className="question-content__link-button" target="_blank">
                                Оставить заявку
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="tuition-fees__text-block-title img">
                    <img src={img} alt="" />
                </div>
            </div>
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="tuition-fees__title">
                                Документы для оформления образовательного кредита
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            <div className="grant-rectora__steps">
                                <div className="grant-rectora__steps-row">
                                    <span className="grant-rectora__text border">1</span>
                                    <span className="grant-rectora__text">
                                        Паспорт РФ
                                    </span>
                                </div>
                                <div className="grant-rectora__steps-row">
                                    <span className="grant-rectora__text border">2</span>
                                    <span className="grant-rectora__text">
                                        Договор на платное обучение с подписью и печатью вуза
                                    </span>
                                </div>
                                <div className="grant-rectora__steps-row">
                                    <span className="grant-rectora__text border">3</span>
                                    <span className="grant-rectora__text">
                                        Квитанция или счета на оплату от вуза с суммой оплаты за обучение
                                    </span>
                                </div>
                                <div className="grant-rectora__steps-row">
                                    <span className="grant-rectora__text border">4</span>
                                    <span className="grant-rectora__text">
                                        Заявление
                                    </span>
                                </div>
                                <div className="grant-rectora__steps-row">
                                    <span className="grant-rectora__text border">5</span>
                                    <span className="grant-rectora__text">
                                        <span className="tuition-fees__text blue">С 14 до 18 лет дополнительно:</span>
                                        • Согласие от одного из законных представителей<br />
                                        • Паспорта законных представителей<br />
                                        • Свидетельство о рождении
                                    </span>
                                </div>
                            </div>
                        </span>
                    </li>

                </ul>
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="tuition-fees__title">
                                Остались вопросы?
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            <div className="grant-rectora__steps">
                                <span className="question-content__item-title">
                                    Представители Сбербанка по образовательным кредитам в КГЭУ:
                                </span>
                                <span className="question-content__item-text">
                                    Орехова Ангелина +7 987 413-69-55
                                </span>
                                <span className="question-content__item-text">
                                    Павлов Александр +7 909 307-60-67
                                </span>
                            </div>
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
