import React from "react";

import "./Scholarship.css";

export default function Scholarship() {
    return (
        <div className="scholarship">
            <span className="individual-achivments__title">Стипендия</span>
            <span className="individual-achivments__title">Бюджетная основа</span>
            <div className="tuition-fees__title-block first">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">Количество баллов</span>
                </div>
                    <span className="tuition-fees__text">Стипендия</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">200 - 219 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">10 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block two">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">220 - 239 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">12 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">240 - 259 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">15 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block two">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">260 баллов и выше</span>
                </div>
                    <span className="tuition-fees__text-block-summa">16 000 руб.</span>
            </div>

            <span className="individual-achivments__title">Платная основа</span>
            <div className="tuition-fees__title-block first">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">Количество баллов</span>
                </div>
                    <span className="tuition-fees__text">Стипендия</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">195 - 199 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">4 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block two">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">200 - 219 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">6 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">220 - 229 баллов</span>
                </div>
                    <span className="tuition-fees__text-block-summa">8 000 руб.</span>
            </div>
            <div className="tuition-fees__text-block two last">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__title">230 баллов и выше</span>
                </div>
                    <span className="tuition-fees__text-block-summa">10 000 руб.</span>
            </div>
        </div>
    );
}
