import React from "react";
import { Link } from "react-router-dom";

import rules from "../../../../assets/documents/Magistracy/admission-rules.pdf";

import "./StepsProcpectiveStudentMagystracyMenu.css";

export default function StepsProcpectiveStudentMagystracyMenu(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="step-prospective-student-menu magistracy">
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                1
                            </span>
                            <span className="question-content__item-title">
                                Выбор направления подготовки
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Вы уже получили высшее образование и выбираете между тем, чтобы устроиться на работу или продолжить обучение в магистратуре?
                            Выбор очевиден! Магистратура – это шанс скорректировать свою траекторию обучения или же углубить знания,
                            полученные за годы бакалавриата или специалитета.
                            Поэтому при подаче заявления на поступление в магистратуру стоит еще раз просмотреть список магистерских программ.
                        </span>
                        <div className="question-content__item-text">
                            <Link to="/" className="question-content__link-button" target="_blank">Список образовательных программ, направления</Link>
                            {/* <Link to="/" className="question-content__link-button">Справочник абитуриента</Link> */}
                            <Link to="/" className="question-content__link-button" target="_blank">Вступительные испытания</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                2
                            </span>
                            <span className="question-content__item-title">
                                Подготовка документов
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Защиты диплома является завершающим этапом получения первого уровня высшего образования в этот напряженный момент легко забыть
                            про сроки подачи документов в магистратуру. Чтобы не забыть сроки подачи вам поможет этот раздел.
                            Здесь можно узнать, как правильно заполнить документы и в как нужно подать их в приемную комиссию.
                            Напоминаем, что подать документы в магистратуру можно лично и электронно через личный кабинет абитуриента
                        </span>
                        <div className="question-content__item-text">
                            <Link to={rules} className="question-content__link-button" target="_blank">Правила приема</Link>
                            <Link to="/" className="question-content__link-button">Основные даты</Link>
                            <Link to="/" className="question-content__link-button">Заполнение документов</Link>
                        </div>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">
                                3
                            </span>
                            <span className="question-content__item-title">
                                Прохождение конкурсного отбора и зачисление
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Одним из самых волнительных этапов является этап прохождения конусного отбора. Будьте внимательны, проверьте всю ли информацию о себе Вы предоставили в Приемную комиссию. Итак, этап конкурсного отбора позади остался один шаг до поступления на программу своей мечты, не пропусти его!
                            <br /><br />
                            Для зачисления в Университет важно подать не только документ об образовании, а еще и "Согласие о зачислении" в установленные сроки.
                        </span>
                        <div className="question-content__item-text">
                            <Link to="/" className="question-content__link-button" target="_blank">Вступительные испытания</Link>
                            <Link to="/" className="question-content__link-button">Проходные баллы прошлых лет</Link>
                            <Link to="/" className="question-content__link-button">Рейтинговые списки</Link>
                        </div>
                    </li>
                </ul>
            </nav>

        </div>
    );
}
