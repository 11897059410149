// import React from "react";

export const olimpiadsMain = [
  {
    title: "Первые шаги в энергетике",
    text: (
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">Цель олимпиады: </span>•
        <span className="olimpiad-main__info-text">
          Выявление одаренных детей и подготовка школьников к поступлению в вуз
        </span>
        <span className="olimpiad-main__info-top-text">Задачи олимпиады:</span>
        <span className="olimpiad-main__info-text">
          • Развитие у обучающихся творческих способностей и интереса к научной
          деятельности
          <br />
          • Создание необходимых условий для поддержки одарённых детей и
          привлечение внимания отраслевых партнеров в области энергетики к
          новому подрастающему поколению
          <br />
          • Активизация работы профессорско-преподавательского состава КГЭУ со
          школьникамия
          <br />
          • Расширение путей взаимодействия средней и высшей школы
          <br />• Содействие профессиональной ориентации и продолжению
          образования учащихся, их дальнейшему интеллектуальному развитию
        </span>
      </span>
    ),
    link: "/olimpiad-first-steps-of-energy",
    btntext: "Читать подробнее",
  },
  {
    title: "Всесибирская олимпиада школьников",
    text: (
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
        Масштабная образовательная олимпиада, которая ежегодно собирает тысячи школьников проверить свои силы и знания по математике, физике, информатике, химии и биологии. Все предметы олимпиады входят в перечень Российского совета олимпиад школьников (РСОШ). Призовые места в олимпиаде дают право выпускникам на льготы при поступлении в вузы, в том числе поступление без вступительных испытаний.
        </span>
        <span className="olimpiad-main__info-top-text">
        Отборочный и заключительный этап проходят на базе КГЭУ для 7-11 класса по предметам:
        </span>
        <div className="olimpiad-main__info-subjects">
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Математика
              <br />
              • Информатика
              <br />• Физика
              <br />• Химия
            </span>
          </div>
        </div>
        <span className="olimpiad-main__info-top-text">
        Примерные даты проведения основного отборочного (очного) этапа:
        </span>
        <span className="olimpiad-main__info-text">
          • 15 октября — 19 ноября.
          <br />Регистрация на отборочный этап: с 25 сентября 2023 г.
        </span>
      </span>
    ),
    link: "//sesc.nsu.ru/olymp-vsesib/",
    btntext: "Зарегистрироваться",
    status: "Входит в Перечень олимпиад РСОШ",
  },
  {
    title: "Бельчонок",
    text:
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
        В текущем учебном году олимпиада вошла в Перечень Российского совета олимпиад школьников под номером 85 по предметам «химия» 
        (II уровень), «математика» (III уровень) и «информатика» (III уровень).
        <br /><br />
        Напомним, победители и призеры перечневых олимпиад получают особые права при поступлении в вузы России.

        </span>
        <span className="olimpiad-main__info-top-text">
        На площадке КГЭУ Олимпиада проводится по предметам:
        </span>
        <div className="olimpiad-main__info-subjects">
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Математика (2-11 классы)
              <br />
              • Информатика (2-11 классы)
              <br />• Физика (7-11 классы)
            </span>
          </div>
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Химия (8-11 классы)
              <br />
              • Биология (9-11 классы)
              <br />• Обществознание (9-11 классы)
            </span>
          </div>
        </div>
        <span className="olimpiad-main__info-top-text">
          Олимпиада проводится в два этапа:
        </span>
        <span className="olimpiad-main__info-text">
        • 1 этап (отборочный) -  с 01 октября по 14 января в дистанционной форме<br />
        • 2 этап (заключительный) проводится на базе КГЭУ. <br />
        График проведения заключительного этапа будет опубликован не позднее, чем за 14 дней до проведения этапа
        </span>
      </span>,
    link: "//sdo.sfu-kras.ru/lk/?q=%2Flk",
    btntext: "Зарегистрироваться",
    status: "Входит в Перечень олимпиад РСОШ",
  },

  
  {
    title: "Будущие исследователи - будущее науки",
    text:
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
        Межрегиональная олимпиада школьников «Будущие исследователи-будущее науки»<br />
        Олимпиада «Будущие исследователи - будущее науки» входит в Перечень олимпиад школьников, дающих особые права (максимальные льготы) при поступлении в высшие учебные заведения РФ (Приказ Министерства науки и высшего образования РФ на 2023/24 стр. 74-).
        </span>
        <span className="olimpiad-main__info-top-text">
        На площадке КГЭУ Олимпиада проводится для 7-11 классов по предметам:</span>
        <div className="olimpiad-main__info-subjects">
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Математика<br />
              • Физика
            </span>
          </div>
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • История
              <br />
              • Биология
            </span>
          </div>
        </div>
        <span className="olimpiad-main__info-top-text">
          Олимпиада проводится в два этапа:
        </span>
        <span className="olimpiad-main__info-text">
          • 1 этап (отборочный) - с 11 ноября по 9 декабря на базе КГЭУ. <br />
          • 2 этап (заключительный) проводится на базе КГЭУ

        </span>
      </span>,
    link: "//www.unn.ru/bibn/calendar/kzn",
    btntext: "Читать подробнее",
    status: "Входит в Перечень олимпиад РСОШ"
  },
  {
    title: "Надежда энергетики",
    text:
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
        <span className="olimpiad-main__info-top-text">
        Олимпиада школьников «Надежда энергетики»<br />
        Олимпиада проводится вузами, расположенными не только в разных субъектах Российской Федерации, но и в разных федеральных округах РФ.
         Это позволяет облегчить школьникам практически любого субъекта европейской части РФ, а также многих субъектов азиатской части РФ, 
         доступ к участию в Олимпиаде, так как экономится время и стоимость проезда к месту проведения Олимпиады. С целью предоставления 
         свободного доступа к участию в Олимпиаде всех желающих, отборочный этап Олимпиады проводится в двух формах: очной и заочной, 
         с использованием современных компьютерных и информационных технологий. Заключительный этап проводится на базе вузов-организаторов 
         только в очной форме.

        </span>
        </span>
        <span className="olimpiad-main__info-top-text">
          На площадке КГЭУ Олимпиада проводится по предметам:
        </span>
        <div className="olimpiad-main__info-subjects">
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Математика (5-11 классы);
              <br />
              • Информатика (9-11 классы);
              <br />• Физика (7-11 классы);
              <br />•	компьютерное моделирование (9 -11 классы).
            </span>
          </div>
        </div>
        <span className="olimpiad-main__info-top-text">
          Олимпиада проводится в два этапа:
        </span>
        <span className="olimpiad-main__info-text">
          • 1 этап (отборочный) - 29 октября по 3 декабря в дистанционной форме<br />
          • 2 этап (заключительный) проодится на базе КГЭУ.
        </span>
      </span>,
    link: "//www.energy-hope.ru/novice/reg_user.html",
    btntext: "Читать подробнее",
    status: "Входит в Перечень олимпиад РСОШ"
  },
  {
    title: "Наследники Левши",
    text:
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
        Олимпиада школьников "Наследники Левши" по физике<br /><br />
Тульский государственный университет c 1995 года проводит олимпиаду школьников «Наследники Левши» по физике для учащихся 7-11 классов.
Олимпиада «Наследники Левши» по физике включена в Перечень олимпиад и иных интеллектуальных и (или) творческих конкурсов, мероприятий на 2023/24 учебный год (приказ Минпросвещения России от 31 августа 2023 года № 649).
<br /><br />
Дипломы, грамоты и свидетельства заключительного этапа олимпиады рассматриваются как индивидуальные достижения и оцениваются дополнительными баллами (до 10 баллов).
        </span>
        <span className="olimpiad-main__info-top-text">
          Олимпиада проводится в два этапа:
        </span>
        <span className="olimpiad-main__info-text">
          • 1 этап (отборочный) - с декабря по январь на базе КГЭУ<br />
          • 2 этап (заключительный) проводится на базе КГЭУ.
        </span>
      </span>,
    link: "https://abitur.tsu.tula.ru/2024/olimp/olimp_physics/timetable_otb_etap_190124.pdf",
    btntext: "Читать подробнее",
    status: "НЕ входит в Перечень олимпиад РСОШ"
  },
  {
    title: "Тинчуринские чтения",
    text:
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">
          В 2022/2023 учебном году олимпиада пройдет по шести предметам:
        </span>
        <div className="olimpiad-main__info-subjects">
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Математика
              <br />
              • Информатика
              <br />• Физика
            </span>
          </div>
          <div className="olimpiad-main__info-subjects-column">
            <span className="olimpiad-main__info-text">
              • Химия
              <br />
              • Биология
              <br />• Обществознание
            </span>
          </div>
        </div>
        <span className="olimpiad-main__info-top-text">
          Олимпиада проводится в два этапа:
        </span>
        <span className="olimpiad-main__info-text">
          • 1 этап (отборочный) - с 1 октября 2022 г. до 13 января 2023 г. в дистанционной форме<br />
          • 2 этап (заключительный) - в очной форме на региональных площадках Олимпиады. График проведения заключительного этапа будет опубликован не позднее, чем за 14 дней до проведения этапа
        </span>
      </span>,
    link: "/tinchurin-readings",
    btntext: "Читать подробнее",
    status: "НЕ входит в Перечень олимпиад РСОШ"
  },
  
  {
    title: "Международная олимпиада «ОлимпТалант»",
    text: (
      <span className="olimpiad-main__info-text">
        <span className="olimpiad-main__info-top-text">Цель олимпиады: </span>
        <span className="olimpiad-main__info-text">
          • Выявление одаренных детей и подготовка обучающихся к поступлению в
          вуз
          <br />
          • Отбор лиц для поступления в КГЭУ
          <br />• Создание условий для интеллектуального развития и поддержки
          обучающихся
        </span>
        <span className="olimpiad-main__info-top-text">Задачи олимпиады:</span>
        <span className="olimpiad-main__info-text">
          • Развитие у обучающихся творческих способностей и интереса к научной
          деятельности
          <br />
          • Создание необходимых условий для поддержки одарённых детей и
          привлечение внимания отраслевых партнеров в области энергетики к
          новому подрастающему поколению
          <br />
          • Активизация работы профессорско-преподавательского состава КГЭУ со
          школьникамия
          <br />
          • Продвижение и популяризация российского образования
          <br />• Содействие профессиональной ориентации и продолжению
          образования учащихся, их дальнейшему интеллектуальному развитию
        </span>
      </span>
    ),
    link: "/",
    btntext: "Читать подробнее",
    status: "НЕ входит в Перечень олимпиад РСОШ"
  },

];
