import React from "react";
import { Link } from "react-router-dom";

import sberbank from "../../../assets/images/ProspectiveStudent/Sberbank.png";
import doc from "../../../assets/documents/Undergraduate/OnePage.pdf";

import "./PaymentMethods.css";

export default function PaymentMethods(props) {
  const handleClickQuestion = (e) => {
    const current = e.currentTarget;
    current.classList.toggle("question-content__item--active");
  };

  return (
    <div className="payment-methods">
      <span className="individual-achivments__title">Способы оплаты</span>
      <span className="payment-methods__subtitle">
        Узнай всевозможные способы оплаты
      </span>
      <nav className="question-content">
        <ul className="question-content__menu">
          <li className="question-content__item" onClick={handleClickQuestion}>
            <div className="question-content__item-title-block">
              <span className="question-content__item-title-number">1</span>
              <span className="question-content__item-title">
                Оплата стоимости обучения материнским (семейным) капиталом
              </span>
            </div>
            <span className="question-content__item-text title">
              Требования пенсионного фонда РФ к оплате обучения:
            </span>
            <span className="question-content__item-text">
              • Младшему ребенку должно быть не менее 3 лет;
              <br />
              • Пенсионный фонд РФ рассматривает заявление в течение месяца;
              <br />
              • Пенсионный фонд РФ осуществляет перевод денежных средств в
              течение 2 месяцев;
              <br />
              • Заявление о направлении средств материнского (семейного)
              капитала на обучение ребенка пишется в Пенсионном фонде;
              <br />
              • Договор и дополнительное соглашение на оказание платных
              образовательных услуг, заключенный с КГЭУ + копии, заверенные
              гербовой печатью КГЭУ;
              <br />• Лицензия и свидетельство о государственной аккредитации
              КГЭУ с приложениями (копия, заверенная гербовой печатью вуза).
            </span>
            <span className="question-content__item-text title">
              Необходимые документы для КГЭУ:
            </span>
            <span className="question-content__item-text">
              • Паспорт заказчика, либо другие документы, подтверждающие
              личность;
              <br />
              • Паспорт абитуриента (копия);
              <br />
              • Сертификат на материнский (семейный) капитал (копия);
              <br />
              • Заявление от заказчика об оплате обучения за счет средств
              материнского (семейного) капитала;
              <br />• Уведомление из Пенсионного фонда РФ о принятых документах.
            </span>
            <div className="question-content__item-text block">
              <span className="question-content__item-text one">Внимание!</span>
              <span className="question-content__item-text two">
                При оплате обучения Материнским капиталом, документы должны
                оформляться за 2 месяца до начала семестра, в связи с тем, что
                Пенсионный фонд рассматривает заявление в течении 2-х месяцев,
                денежные средства возможно не будут перечислены до конца
                приемной кампании.
              </span>
            </div>
          </li>
        </ul>
        {/* ----------- */}
        <ul className="question-content__menu">
          <li className="question-content__item" onClick={handleClickQuestion}>
            <div className="question-content__item-title-block">
              <span className="question-content__item-title-number">2</span>
              <span className="question-content__item-title">
                Оплата стоимости обучения собственными средствами
              </span>
            </div>
            <span className="question-content__item-text">
              В качестве заказчика может выступать физическое либо юридическое
              лицо, обязующееся оплатить обучение зачисляемого. В таком случае в
              роли заказчика выступает организация, заинтересованная в
              конкретном кадровом ресурсе либо в повышении квалификации своего
              работника.
            </span>
            <span className="question-content__item-text">
              Оплату можно произвести в любом банке по реквизитам указанным в
              квитанции на оплату обучения.
            </span>
            <div className="question-content__item-text">
              <Link to="/" className="question-content__link-button">
                Оплата через Сбербанк онлайн
              </Link>
              <Link to="/" className="question-content__link-button">
                Оплата через другой банк
              </Link>
            </div>
          </li>
        </ul>
        {/* ----------- */}
        <ul className="question-content__menu">
          <li className="question-content__item" onClick={handleClickQuestion}>
            <div className="question-content__item-title-block">
              <span className="question-content__item-title-number">3</span>
              <span className="question-content__item-title">
                Кредит на образование
              </span>
            </div>
            <span className="question-content__item-text">
              Образовательный кредит от Сбербанка с государственной поддержкой
            </span>
            <span className="question-content__item-text">
              <img
                src={sberbank}
                alt=""
                className="question-content__item-text-img"
              />
            </span>
            <div className="question-content__item-text">
              <Link to={doc} className="question-content__link-button" target="_blank">
                Изучить подробную информацию
              </Link>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
