import React from "react";

import grantRectora from "../../../assets/images/ProspectiveStudent/GrantRectora.png";

import "./GrantRectora.css";

export default function GrantRectora() {
  return (
    <div className="grant-rectora">
      <div className="grant-rectora__info-block">
        <div className="grant-rectora__text-block">
          <span className="individual-achivments__title">Абитуриент!</span>
          <span className="grant-rectora__text">
            Не упусти свой шанс получить качественное высшее образование
            бесплатно в рамках проекта «Грант ректора на обучение»
          </span>
          <span className="grant-rectora__text">
            «Грант ректора на обучение» - это возможность для абитуриентов с
            высокими баллами ЕГЭ обучаться бесплатно за счёт средств
            университета на направлениях, где предусмотрено только платное
            обучение.
          </span>
          <span className="grant-rectora__text background">
            2022 год - 5 бесплатных мест на направления: <br/><br/>• 38.03.01 «Экономика»<br/>• 38.03.02 «Менеджмент»<br/>• 39.03.01 «Социология»<br/>• 42.03.01 «Реклама и связи с общественностью»
          </span>
          <div className="grant-rectora__steps">
            <div className="grant-rectora__steps-row">
              <span className="grant-rectora__text border">1 шаг</span>
              <span className="grant-rectora__text">
                Оплата - минимум 25% от годовой стоимости обучения
              </span>
            </div>
            <div className="grant-rectora__steps-row">
              <span className="grant-rectora__text border">2 шаг</span>
              <span className="grant-rectora__text">
                Предоставить квитанцию об оплате до 10 августа 2022 года 18:00
                по Московскому времени. В списки претендентов включены только
                абитуриенты предоставившие квитанцию об оплате
              </span>
            </div>
            <div className="grant-rectora__steps-row">
              <span className="grant-rectora__text border">3 шаг</span>
              <span className="grant-rectora__text">
                10 августа 2022 года вывешиваются списки участников конкурса,
                которые должны предоставить оригиналы до 18 августа 2022 года
                17:00 по Московскому времени
              </span>
            </div>
            <div className="grant-rectora__steps-row">
              <span className="grant-rectora__text border">4 шаг</span>
              <span className="grant-rectora__text">
                20 августа 2022 года - издание приказа по Гранту Ректора
              </span>
            </div>
          </div>
        </div>
        <div className="grant-rectora__image-block">
          <img src={grantRectora} alt="" className="grant-rectora__image" />
        </div>
      </div>
    </div>
  );
}
