import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsPrib.css";

const items = [
  {
    // 2020 - 160
    subtitle: "-", 
    text: (
      <React.Fragment>
        Проходной балл <br /> в 2023 (бюджет)
      </React.Fragment>
    ),
  },
  {
    subtitle: "25",
    text: (
      <React.Fragment>
        Количество бюджетных <br />
        мест на оч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "5",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "163 160₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  // {
  //   subtitle: "45 000₽",
  //   text: "Стоимость платного обучения на заоч/ф",
  // },
];

export default function EducationalProgramsPrib() {
  return (
    <div className="educational-program">
      <span className="individual-achivments__title">
        Институт цифровых технологий и экономики
      </span>

      <table className="educational-program__scroll-table">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Приборостроение»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет очная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Бюджет заочная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
        </tr>
        
        {/* <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/automated-quality-control-and-diagnostic-systems"
              className="educational-program__block-text"
            >
              Автоматизированные системы контроля качества и диагностики
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}

        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/intelligent-instrument-systems-and-industrial-internet"
              className="educational-program__block-text"
            >
              Интеллектуальные приборные комплексы и промышленный интернет
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
      </table>

      <div className="educational-program__main-block vie">
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
