import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionEconom from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionEconom";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/achmetova.jpg";
import doc from "../../../../assets/documents/EducationalPrograms/buch.pdf";


import "./BuchTeacherPage.css";

export default function BuchTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher">
                    <span className="teacher__title">Бухгалтерский учет, анализ и аудит <br />на предприятиях
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ахметова Ирина Гареева</span>
                            <span className="teacher__text">д.т.н., заведующая кафедрой «Экономика и организация производства» (ЭОП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">irina_akhmetova@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-43-55</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань,</span>
                            <span className="teacher__text">ул. Красносельская, 51</span>
                            <span className="teacher__text">каб. Д-627</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <Link to=""  className="teacher__button">Учебный план </Link>
                            </div>
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionEconom /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}