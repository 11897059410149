import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";
import StepsProspectiveStudentMagistratura from "../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudentMagistratura";

import Container from "../../components/Container";

import { stepsProcpectiveStudentMagistracy } from "../../constants";

import "./ProspectiveStudentMagistraturPage.css";

function ProspectiveStudentMagistraturPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top">
                    <span className="etap-postupleniya__title">Абитуриенту</span>
                </div>
                <div className="step-prospective-student__top">
                    <Link to="/prospective-student" className="etap-postupleniya__title two">Бакалавриат, специалитет</Link>
                    <span className="etap-postupleniya__title one">Магистратура</span>
                </div>
            </Container>
            <StepsProspectiveStudentMagistratura items={stepsProcpectiveStudentMagistracy}/>
            <Footer />
        </React.Fragment>
    );
}

export default ProspectiveStudentMagistraturPage;
