import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { combineReducers } from "redux";
import { Provider } from "react-redux";
import { modalReducer } from "./reducers";
// import { Layout } from "./components/Routing/Layout";
// import { createBrowserHistory } from "history";

const rootReducer = combineReducers({
  modal: modalReducer,
});

const store = createStore(rootReducer);

// const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <BrowserRouter history={history}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
