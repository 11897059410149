import React from "react";

import improved from "../../../assets/images/ProspectiveStudent/EnergyImproved.png";
import high from "../../../assets/images/ProspectiveStudent/EnergyHigh.png";
import general from "../../../assets/images/ProspectiveStudent/EnergyGeneral.png";
import povishennayaStipendiya from "../../../assets/images/ProspectiveStudent/PovishennayaStipendiya.png";
import academStipendiya from "../../../assets/images/ProspectiveStudent/AcademStipendiya.png";
import obshechitie from "../../../assets/images/ProspectiveStudent/Obshechitie.png";
import productciya from "../../../assets/images/ProspectiveStudent/Productciya.png";
import premium from "../../../assets/images/ProspectiveStudent/Premium.png";
import dpo from "../../../assets/images/ProspectiveStudent/Dpo.png";

import "./EnergyLevels.css";

export default function EnergyLevels() {
  return (
    <div className="energy-levels">
      <span className="individual-achivments__title">
        Бонусная программа для высокобальников
      </span>
      <table className="scroll-table">
        <tr className="energy-levels__images-block">
          <img src={improved} alt="" className="energy-levels__image" />
          <img src={high} alt="" className="energy-levels__image" />
          <img src={general} alt="" className="energy-levels__image" />
        </tr>
      </table>
      <div className="energy-levels__info-block">
        {/* <div className="energy-levels__info-block-row"> */}
        <div className="energy-levels__info-block-column">
          <img
            src={povishennayaStipendiya}
            alt=""
            className="energy-levels__info-block-image"
          />
          <span className="energy-levels__info-block-text">
            Повышенная стипендия в первом семестре в зависимости от суммы
            баллов по вступительным испытаниям
          </span>
        </div>
        <div className="energy-levels__info-block-column">
          <img
            src={academStipendiya}
            alt=""
            className="energy-levels__info-block-image"
          />
          <span className="energy-levels__info-block-text">
            Увеличение академической стипендии в следующем семестре в случае
            закрытии сессии на отлично
          </span>
        </div>
        <div className="energy-levels__info-block-column">
          <img
            src={obshechitie}
            alt=""
            className="energy-levels__info-block-image"
          />
          <span className="energy-levels__info-block-text">
            Возможность выбора комнаты в общежитиях КГЭУ
          </span>
        </div>
        {/* </div> */}
        {/* <div className="energy-levels__info-block-row"> */}
        <div className="energy-levels__info-block-column">
          <img
            src={productciya}
            alt=""
            className="energy-levels__info-block-image"
          />
          <span className="energy-levels__info-block-text">
            Фирменная продукция КГЭУ в подарок (блокнот, ручка)
          </span>
        </div>
        <div className="energy-levels__info-block-column">
          <img
            src={premium}
            alt=""
            className="energy-levels__info-block-image"
          />
          <span className="energy-levels__info-block-text one">
            Предоставление возможности участия в 3-х дневном интенсиве
            «ПремиУМ» по созданию научной траектории развития.
          </span>
          <span className="energy-levels__info-block-text">
            Зачисление в ряды резидентов таких площадок как:
            <br />
            - Молодежный бизнес инкубатор (МБИ);
            <br />- Студенческое научное общество (СНО)
          </span>
        </div>
        <div className="energy-levels__info-block-column">
          <img src={dpo} alt="" className="energy-levels__info-block-image" />
          <span className="energy-levels__info-block-text">
            Возможность получить дополнительное образование по программам
            профессиональной переподготовки в институте дополнительного
            профессионального образования (ИДПО) со скидкой
          </span>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
