import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";

import ListOfDocuments from "../../../components/ComponentsUndergraduatePage/ListOfDocuments";

// import StepsProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import Container from "../../../components/Container";

import { stepsUndergraduateMagistracy } from "../../../constants";

import "./UndergraduateMagistracyPortfolioPage.css";

export default function UndergraduateMagistracyPortfolioPage(props) {
  return (
    <React.Fragment>
      <TopBar />
      <TopBarProspectiveStudent />
      <Container>
        <div className="step-prospective-student__top abiturient">
          <span className="etap-postupleniya__title">Магистратура</span>
        </div>
        {/* <div className="step-prospective-student__top undergraduate-ICTE-page">
                    <Link to="/undergraduate-magistracy-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-magistracy-ite" className="etap-postupleniya__title">Институт теплоэнергетики</Link>
                    <Link to="/undergraduate-magistracy-icte" className="etap-postupleniya__title">Институт цифровых технологий и экономики</Link>
                    <span className="etap-postupleniya__title one">Институт цифровых технологий и экономики</span>
                </div> */}
      </Container>
      <Container>
        <div className="undergraduate-ICTE-page__main abiturient magistracy">
          <div className="etap-postupleniya__content-col">
            <div className="etap-postupleniya__col magistracy">
              <div className="progress__item">
                <a href="/undergraduate-magistracy"
                  className="progress__item-title"
                >
                  Вступительные испытания
                </a>
              </div>
              <div className="progress__item">
                <a href="/undergraduate-magistracy-main-date"
                  className="progress__item-title"
                >
                  Основные даты
                </a>
              </div>
              <div className="progress__item">
                <a href="/undergraduate-magistracy-documents"
                  className="progress__item-title"
                >
                  Перечень документов
                </a>
              </div>
              <div className="progress__item--active">
                <span className="progress__item-title">Конкурс портфолио</span>
              </div>
              {/* <div className="progress__item">
                <a href="//kgeu.ru/Document/GetDocument/2904ccb5-0455-4e15-abea-f30ba77ea7ba" target="_blank" className="progress__item-title">
                  Расписание экзаменов на бюджетную форму
                </a>
              </div>
              <div className="progress__item">
                <a href="//kgeu.ru/Document/GetDocument/ebacb432-e627-4feb-8d04-dfa4a0734f29" target="_blank" className="progress__item-title">
                  Расписание экзаменов на платную форму
                </a>
              </div> */}
            </div>
            <div className="step-prospective-student etap-postupleniya__col col__two">
              <div className="etap-postupleniya__content-tab--active">
                <span className="etap-postupleniya__text">
                  <div className="step-procpective-student__padding">
                    {/* <PassingPoints /> */}

                    <div className="concurs-portfolio">
                      <span className="individual-achivments__title">
                        Конкурс портфолио
                      </span>
                      <div className="list-of-documents__block">
                        <span className="list-of-documents__block-text">
                          В КГЭУ стартовал конкурс портфолио для поступления в магистратуру, не упусти свой шанс поступить без экзаменов!
                        </span>
                      </div>

                      <div className="list-of-documents__block two">
                        <span className="list-of-documents__block-title">
                          Кто может участвовать?
                        </span>
                        <span className="list-of-documents__block-text">
                          Любой поступающий в магистратуру КГЭУ, который имеет диплом о высшем образовании со средним баллом не ниже 4,75
                        </span>
                        <span className="list-of-documents__block-title">
                          Сроки проведения:
                        </span>
                        <span className="list-of-documents__block-text">
                          С 1 июня по 20 июля;
                        </span>
                      </div>

                      <div className="list-of-documents__block">
                        <span className="list-of-documents__block-title">
                          Что необходимо для участия?
                        </span>
                        <span className="list-of-documents__block-text">
                          1. Зарегистрироваться на курсе конкурса по ссылке: https://clck.ru/34R3zr;
                        </span>
                        <span className="list-of-documents__block-text">
                          2. Выбрать одно направление подготовки и профиль внутри него;
                        </span>
                        <span className="list-of-documents__block-text">
                          3. Заполнить анкету;
                        </span>
                        <span className="list-of-documents__block-text">
                          4. Написать мотивационное письмо и заявление;
                        </span>
                        <span className="list-of-documents__block-text">
                          5. Прикрепить портфолио достижений.
                        </span>
                      </div>

                      <div className="list-of-documents__block two">
                        <span className="list-of-documents__block-title">
                          Участники, набравшие 100 баллов, могут поступить в магистратуру без экзаменов.
                          <br />
                          <br />
                          Участники, набравшие от 70 до 99 баллов имеют возможность сдать экзамен и выбрать для поступления наибольший результат.
                        </span>
                      </div>

                      <div className="list-of-documents__block two">
                        <span className="list-of-documents__block-title">
                          По возникающим вопросам обращаться к куратору конкурса – доценту каф. ЭОП Маймаковой Людмиле Владимировне
                          <br />
                          <br />
                          По средам 10:00 – 13:00 каб. В-100
                        </span>
                      </div>
                      <a
                        href="//clck.ru/34R3zr"
                        target="_blank"
                        className="progress__item-title"
                      >
                        Участвовать в конкурсе
                      </a>
                      <span>
                        <br /><br />
                        <br /><br />
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
