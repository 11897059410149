import React from "react";

// import { menuItems } from "../../Constants";

import "./Calendar.css";


const items = [
    {
        title: "Очная, заочная формы бюджет",
        anchor: "select-1",
        text: (
            <div className="calendar__data-block">
                <div className="calendar__data">
                    <span className="calendar__data-title">17 июня</span>
                    <span className="calendar__data-text">Начало приема документов</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">20 июля</span>
                    <span className="calendar__data-text">Завершение приема документов, от лиц, поступающих на обучение по результатам вступительных испытаний, проводимых КГЭУ самостоятельно</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">25 июля</span>
                    <span className="calendar__data-text">Завершение приема документов, от лиц, поступающих по результатам ЕГЭ</span>
                    <span className="calendar__data-text">Завершение вступительных испытаний, проводимых КГЭУ самостоятельно</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">27 июля</span>
                    <span className="calendar__data-text">Публикация на официальном сайте КГЭУ конкурсных списков</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">28 июля</span>
                    <span className="calendar__data-text">Завершение приема заявлений оригиналов документа об образовании от лиц, поступающих без вступительных испытаний, поступающих на места в пределах квот на этапе приоритетного зачисления</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">29 июля</span>
                    <span className="calendar__data-text">Издание приказа о зачислении лиц, подавших оригинал документа об образовании, из числа поступающих без вступительных испытаний, поступающих на места в пределах квот</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">3 августа</span>
                    <span className="calendar__data-text">Завершение приема оригиналов документа об образовании от лиц, включенных в конкурсныйт список на основном этапе зачисления</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">5 августа</span>
                    <span className="calendar__data-text">Издание и размещение на официальном сайте КГЭУ приказа о зачислении лиц в основной этап зачисления</span>
                </div>
                {/* </div> */}
            </div>
        ),
    },
    {
        title: "Очная, заочная, очно-заочная формы платная основа",
        anchor: "select-2",
        text: (
            <div className="calendar__data-block">
                {/* <div className="calendar__data-row"> */}
                <div className="calendar__data">
                    <span className="calendar__data-title">17 июня</span>
                    <span className="calendar__data-text">Начало приема документов</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">17 июня - 28 августа</span>
                    <span className="calendar__data-text">Сроки вступительных испытаний, проводимых КГЭУ самостоятельно</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">20 августа</span>
                    <span className="calendar__data-text">Завершение приема документов</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">29 августа</span>
                    <span className="calendar__data-text">Завершение приема заявлений о согласии на зачисление</span>
                    <span className="calendar__data-text">Завершение приема квитанций об оплате</span>
                </div>
                <div className="calendar__data">
                    <span className="calendar__data-title">30 августа</span>
                    <span className="calendar__data-text">Завершение зачисления</span>
                </div>
                {/* </div> */}
            </div>
        ),
    },
];



export default function Calendar() {
    const deleteActiveClassToTab = (activeTab = null) => {
        if (activeTab) {
            const tabs = document.querySelectorAll(
                ".calendar__col .calendar__item"
            );
            tabs.forEach((content) => {
                content.classList.remove("calendar__item--active");
            });
            activeTab.classList.add("calendar__item--active");
        }
    };

    const deleteActiveClassToContent = (href = null) => {
        if (href) {
            const contentTabs = document.querySelectorAll(
                ".calendar__content-tab"
            );
            contentTabs.forEach((content) => {
                content.classList.remove("calendar__content-tab--active");
            });
            [...contentTabs]
                .find((tab) => {
                    return tab.getAttribute("data-id") === href;
                })
                .classList.add("calendar__content-tab--active");
        }
    };

    const handleClickTab = (e) => {
        const target = e.currentTarget;
        const href = target.getAttribute("data-href");
        deleteActiveClassToTab(target);
        deleteActiveClassToContent(href);
    };
    return (
            <div className="calendar">
                <span className="individual-achivments__title">Календарь</span>
                <div className="calendar__content-row">
                    <span className="calendar__title">Форма обучения</span>
                    <div className="calendar__content-col">
                        <div className="calendar__col">
                            {items.map((item, index) => (
                                <div
                                    className={`calendar__item ${index === 0 && "calendar__item--active"
                                        }`}
                                    data-href={item.anchor}
                                    key={String(index)}
                                    onClick={handleClickTab}
                                >
                                    <span className="calendar__item-title">{item.title}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="calendar__col col__two">
                    {items.map((item, index) => (
                        <div
                            data-id={item.anchor}
                            key={item.anchor}
                            className={`calendar__content-tab ${index === 0 && "calendar__content-tab--active"
                                }`}
                        >
                            <span className="calendar__text">{item.text}</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}
