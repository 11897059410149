import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/ilin.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/energoobecpecch.pdf";

import img2 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagStroitTeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagTPETeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagESETeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagTPETeacher/companies/img_company3.png";
import "./EnergoObecpechTeacherPage.css";


export default function EnergoObecpechTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher ese">
                    <span className="teacher__title">Энергообеспечение предприятий
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ильин Владимир Кузьмич</span>
                            <span className="teacher__text">д.т.н., профессор кафедры <br />
                                «Энергообеспечение предприятий, <br />
                                строительство зданий и сооружений» (ЭОС)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">ilyinvk@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-06</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. Д-627</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                                
                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                            Программа направлена на подготовку высококвалифицированных специалистов, умеющих решать проектные, эксплуатационные и научно-исследовательские задачи в области энергообеспечения предприятий.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Управление проектами в энергетике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Проектирование тепловых сетей</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Методологические основы научной работы и принципы  диссертационного исследования</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Использование прикладных программ для проектирования систем энергообеспечения предприятий</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Основы проектирования малых теплоэлектроцентралей</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Энергообеспечение зданий и сооружений</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Планирование экспериментальных исследований работы энергетического оборудования.</span>
                                </div>
                                
                                
                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Энергетическое обследование и паспортизация объектов энергетики</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Надежность систем энергообеспечения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Энергосбережение при производстве, транспортировке и потреблении энергии</span>
                                </div>
                                
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Инженером-энергетиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Главным энергетиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Инженером-проектировщиком</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}