import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsEE.css";

const items = [
  {
    subtitle: "114",
    text: (
      <React.Fragment>
        Количество бюджетных <br />
        мест на оч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "20; 55",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "174 890₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  {
    subtitle: "67 500₽",
    text: "Стоимость платного обучения на заоч/ф",
  },
];

export default function EducationalProgramsEE() {
  return (
    <div className="educational-program magistracy">
      <span className="individual-achivments__title">
        Институт электроэнергетики и электроники
      </span>

      <table className="educational-program__scroll-table">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Электроэнергетика и электротехника»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет очная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Бюджет заочная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <a href="/intelligent-energy-systems"
              className="educational-program__block-text"
            >
              Интеллектуальные энергетические системы
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <a href="/ilectric-power-systems-networks-power-transmission-their-modes-stability-and-reliability"
              className="educational-program__block-text one"
            >
              Электроэнергетические системы, сети, электропередачи, их режимы,
              устойчивость и надежность
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <a href="/design-of-developing-power-supply-systems"
              className="educational-program__block-text"
            >
              Проектирование развивающихся систем электроснабжения
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <a href="/automation-of-power-systems"
              className="educational-program__block-text one"
            >
              Автоматика энергосистем
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <a href="/technical-and-information-support-for-the-design-and-operation-of-the-electric-power-industry-of-consumers"
              className="educational-program__block-text"
            >
              Техническое и информационное обеспечение проектирования и
              функционирования электроэнергетического хозяйства потребителей
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        {/* <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <a href="/operation-of-high-speed-electric-rolling-stock"
              className="educational-program__block-text one"
            >
              Эксплуатация высокоскоростного электроподвижного состава
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}
        {/* <tr className="educational-program__scroll-table-row two">
          <div className="educational-program__scroll-table-column one">
            <a href="/electromechanical-and-electronic-systems-of-automation-of-processes-and-productions"
              className="educational-program__block-text one"
            >
              Электроустановки электрических станций и подстанций
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <a href="/design-and-operation-of-electrical-equipment-for-electric-vehicles-unmanned-vehicle-and-charging-infrastructure"
              className="educational-program__block-text one"
            >
              Проектирование и эксплуатация электротехнического оборудования электромобилей, беспилотного транспорта и зарядной инфраструктуры
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <a href="/technical-and-information-support-for-the-design-and-operation-of-the-electric-power-industry-of-consumers"
              className="educational-program__block-text one"
            >
              Техническое и информационное обеспечение проектирования и функционирования электроэнергетического хозяйства потребителей
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <a href="/economics-and-management-in-the-electric-power-industry-magistracy"
              className="educational-program__block-text one"
            >
              Экономика и управление в электроэнергетике
            </a>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
      </table>
      <div className="educational-program__main-block elect">
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
