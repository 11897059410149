// import React from "react";


export const lastChangesMainPage = [
    {
        subtitle: "17.06.24",
        title: "Успей подать документы",
        link: "//priem.kgeu.ru/user/sign-in/login"
    },
    {
        subtitle: "29.02.24",
        title: "Институт цифровых технологий и экономики",
        link: "//vk.com/video/@kgeu_official?list=6053d54608c4e6d83d&preview=&screen=&webcast=&z=video-24498396_456239955"
    },
    {
        subtitle: "28.02.24",
        title: "Институт теплоэнергетики",
        link: "//vk.com/video/@kgeu_official?z=video-24498396_456239954%2Fclub24498396%2Fpl_-24498396_-2"
    },
    {
        subtitle: "27.02.24",
        title: "Институт электроэнергетики и электроники",
        link: "//vk.com/video-24498396_456239951"
    },
    {
        subtitle: "27-29.02.24",
        title: "День открытых дверей",
        link: "//rutube.ru/video/d37db9a308ae44dd583c301f469a66ab/"
    },
    {
        subtitle: "01.10.2023",
        title: "Школа олимпиадной подготовки",
        link: "//kgeu.ru/News/Item/287/13088"
    },
    {
        subtitle: "01.10.2023",
        title: "Конструкторское бюро",
        link: "//kgeu.ru/News/Item/287/13089"
    },
    {
        subtitle: "01.06.2023",
        title: "Конкурс портфолио",
        link: "/undergraduate-magistracy-concurs-portfolio"
    },
    {
        subtitle: "май",
        title: "Сертификаты «Наследники Левши»",
        link: "//drive.google.com/drive/folders/1hcNEI9xyV0vMsxydWq_hQje4QUp6LPXb?usp=sharing"
    },
    {
        subtitle: "май",
        title: "Сертификаты «Будущие исследователи»",
        link: "//drive.google.com/drive/folders/1lDOctmwfYQ1Et81IxrZ_Ac9F6tRqSBRk?usp=sharing"
    },
    {
        subtitle: "03.02.2023",
        title: "Результаты «БЕЛЬЧОНКА»",
        link: "//dovuz.sfu-kras.ru/abiturientu-sfu/olimpiady/belchonok/actual/rezultaty-otborochnogo-etapa-2022-2023/"
    },
]