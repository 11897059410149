import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/machiyanova.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/menedchm.pdf";


import img2 from "../../../../assets/images/New/MagSMYOTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagSMYOTeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagSMYOTeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagSMYOTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagSMYOTeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagSMYOTeacher/companies/img_company3.png";
import "./SMYOTeacherPage.css";

export default function SMYOTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher smyo">
                    <span className="teacher__title">Стратегический менеджмент
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Махиянова Алина Владимировна</span>
                            <span className="teacher__text">д.с.н., доцент кафедры <br />«Менеджмент» (М)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">socavm@rambler.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-42-91</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, <br />каб. Д-704

                            </span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                                
                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                            Программа направлена на подготовку профессиональных медиаспециалистов в отрасли медиапроизводства, журналистики, мультимедийных средств и стратегических коммуникаций, которые могли бы создавать качественный видео- и аудиоконтент, анимационные и мультимедийные продукты с учетом специфики потребительского рынка.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Современный стратегический управленческий учет</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Стратегическая логистика</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Стратегический менеджмент</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Стратегическое планирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Теория экономического роста</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Управленческая экономика</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Экономико-математическое моделирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">08</span>
                                    <span className="text-line">Экономический анализ и организация производства</span>
                                </div>

                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Коммуникационный менеджмент</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Теория и практика научных исследований и организации производства</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Теория и практика саморазвития</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Теория и практика лидерства</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Финансистом</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Топ-менеждером</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Менеджером отрасли</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                    
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}