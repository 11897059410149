import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionMashinostr from "../../../../components/ComponentsUndergraduatePage/ITE/ITEQuestion/QuestionMashinostr";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/dmitriev.jpg";
import doc from "../../../../assets/documents/uch-plan/avtomat.pdf";

import img1 from "../../../../assets/images/New/AtomTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/AtomTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/AtomTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/AtomTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/ATPTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/ATPTeacher/large-frame-bg2.svg";
import "./AvtomatizTeacherPage.css";

export default function AvtomatizTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher atp">
                    <span className="teacher__title">Автоматизация технологических процессов и производств
                    </span>
                    <div className="teacher__main-block chich">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                        <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Дмитриев Андрей Владимирович</span>
                            <span className="teacher__text">д.т.н., доцент кафедры <br /> «Автоматизация технологических процессов и<br /> производств» (АТПП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">ieremiada@gmail.com</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-58</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                                
                            </div>
                        </div>

                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Phys">Ф</span>
                                </div>
                                <div className="subject">
                                    <span className="Chem">Х</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">214</span>
                        </div>
                    </div>

                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Автоматизация управления жизненным<br /> циклом продукции</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Программирование и алгоритмизация</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Численные и аналитические методы</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Организация и планирование<br /> автоматизированных средств</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Освоение программно-технического<br />
                                     комплекса, включающего в себя все<br /> стадии разработки изделия</span>
                                </div>
                            </div>
                        </div>  

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Специалист по САПР</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Инженер 3D-печати</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Инженер по автоматизации и механизации</span>
                                </div> 
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img4}/></div>
                                    <span className="text-line">Инженер АСУ ТП</span>
                                </div>
                            </div>
                            
                        </div>  
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">  
                            <img src={bg_left}/>
                            <img src={bg_right}/>
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Применение современных<br /> систем компьютерной<br /> математики, технологий<br /> конечно-элементарного анализа</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Применение наукоемких<br /> компьютерных технологий</span> 
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Применение информационных<br /> технологий</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Примененеие систем<br /> автоматизированного<br /> проектирования</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Решение задач динамики, прочности, устойчивости, рациональной<br />
                                     оптимизации, долговечности, ресурса, живучести, надежности и<br />
                                      безопасности конструкций, композитных структур, оборудования,<br />
                                      приборов и их элементов</span> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span> */}
                    {/* <QuestionMashinostr /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}