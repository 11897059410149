import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsMenedch.css";

const items = [
  {
    subtitle: "50; 15",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф; заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "50",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "143 930₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  {
    subtitle: "68 900₽",
    text: "Стоимость платного обучения на оч — заоч/ф",
  },
];

export default function EducationalProgramsMenedch() {
  return (
    <div className="educational-program">
      <span className="individual-achivments__title">
        Институт цифровых технологий и экономики
      </span>

      <table className="educational-program__scroll-table economy">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Менеджмент»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
            Платные места
              <br />
              очно-заочная форма
            </span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/organization-management"
              className="educational-program__block-text"
            >
              Менеджмент организации
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
      </table>

      <div className="educational-program__main-block economy" >
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
