import React from "react";

import UndegraduateMagistracyInfo from "../../components/ComponentsMagistracyPage/UndegraduateMagistracyInfo";
import ListOfDocuments from "../../components/ComponentsUndergraduatePage/ListOfDocuments";
// import PassingPoints from "../../components/ComponentsUndergraduatePage/PassingPoints";
import CalendarMagistracy from "../../components/ComponentsMagistracyPage/ProspectiveStudentPages/CalendarMagistracy";
// import Scholarship from "../../components/ComponentsProspeciveStudentPage/Scholarship";
// import IndividualAchievements from "../../components/ComponentsProspeciveStudentPage/IndividualAchievements";

export const stepsUndergraduateMagistracy = [
  {
    title: "Вступительные испытания",
    anchor: "select-1",
    text: (
      <div className="step-procpective-student__padding">
        <UndegraduateMagistracyInfo />
      </div>
    ),
  },
  {
    title: "Основные даты",
    anchor: "select-2",
    text: (
      <div className="step-procpective-student__padding">
        <CalendarMagistracy />
      </div>
    ),
  },
  // {
  //   title: "Стипендия (бюджет и платная основа)",
  //   anchor: "select-3",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <Scholarship />
  //     </div>
  //   ),
  // },
  // {
  //   title: "Индивидуальные достижения",
  //   anchor: "select-4",
  //   text: (
  //     <div className="step-procpective-student__padding">
  //       <IndividualAchievements/>
  //     </div>
  //   ),
  // },
  {
    title: "Перечень документов",
    anchor: "select-5",
    text: (
      <div className="step-procpective-student__padding">
        <ListOfDocuments />
      </div>
    ),
  },
  {
    title: "Конкурс портфолио",
    anchor: "select-6",
    text: (
      <div className="step-procpective-student__padding">
        {/* <PassingPoints /> */}

        <div className="concurs-portfolio">
          <span className="individual-achivments__title">
            Конкурс портфолио
          </span>
          <div className="list-of-documents__block">
            <span className="list-of-documents__block-text">
              Участвуй и поступи в магистратуру КГЭУ без экзаменов!
            </span>
            <span className="list-of-documents__block-text">
              Конурс портфолио позволяет поступить в магистрату КГЭУ без
              вступительных испытаний. Каждый участник может подать заявку
              одновременно на 1 направление подготовки в рамках направления на
              одну образовательную программу.
            </span>
          </div>

          <div className="list-of-documents__block two">
            <span className="list-of-documents__block-title">
              Кто может участвовать?
            </span>
            <span className="list-of-documents__block-text">
              Любой поступающий в магистратуру КГЭУ, который имеет диплом высшем
              образовании со средним баллом не ниже 4,4.
            </span>
            <span className="list-of-documents__block-title">
              Сроки проведения:
            </span>
            <span className="list-of-documents__block-text">
              1 этап - с 1 мая по 20 июня;
              <br />2 этап - с 21 июня по 20 июля.
            </span>
          </div>

          <div className="list-of-documents__block">
            <span className="list-of-documents__block-title">
              Что необходимо для участия?
            </span>
            <span className="list-of-documents__block-text">
              1. Выбрать институт/направление подготовки/образовательную
              программу;
            </span>
            <span className="list-of-documents__block-text">
              2. Подготовить мотивационное письмо - аргументированное
              обоснование выбора программы магистратуры, видение планируемой
              научно-исследовательской или иной профессиональной деятельности,
              перспектив примения полученных знаний, навыков, компетенций;
            </span>
            <span className="list-of-documents__block-text">
              3. Заполнить портфолио достижения по следующим разделам:
              <br />
              - мотивационное письмо (макс. балл - 10);
              <br />
              - учебная деятельность (макс. балл - 40);
              <br />- научная деятельность (макс. балл - 50).
            </span>
          </div>

          <div className="list-of-documents__block two">
            <span className="list-of-documents__block-title">
              Победителями являются, набравшие 100 баллов.
              <br />
              Призеры от 70 до 99 баллов имеют возможность сдать экзамен и
              выбрать для поступления наибольший результат.
            </span>
          </div>

          <a
            href="//priem.kgeu.ru/user/sign-in/login"
            target
            className="progress__item-title"
          >
            Участвовать в конкурсе
          </a>
        </div>
      </div>
    ),
  },
];
