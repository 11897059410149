import React from "react";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";
import SliderMainPage from "../../components/ComponentsMainPage/SliderMainPage";
import LevelLearning from "../../components/ComponentsMainPage/LevelLearning";

import "./NewsPage.css";

function NewsPage() {
    return (
        <React.Fragment>
            <TopBar />

            <TopBarProspectiveStudent />
            <SliderMainPage />
            <LevelLearning />
            <LevelLearning />


            <Footer />
        </React.Fragment>
    );
}

export default NewsPage;
