import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionEconom from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionEconom";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/achmetova.jpg";
import doc from "../../../../assets/documents/uch-plan/economy.pdf";

import img1 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/BADETeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/BADETeacher/large-frame-bg2.svg";
import "./BiznesTeacherPage.css";

export default function BiznesTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher bade">
                    <span className="teacher__title">Бизнес-аналитика и цифровая экономика
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ахметова Ирина Гареевна</span>
                            <span className="teacher__text">д.т.н., заведующая кафедрой<br /> «Экономика и организация производства» (ЭОП)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">irina_akhmetova@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-43-55</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. Д-627</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                    <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>

                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Soc">О</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="His">Ис</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">—</span>
                        </div>
                    </div>
                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Количественную оценку инвестиций и<br /> инноваций</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Управление рисками и моделирование<br /> рисковых ситуаций</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Анализ финансово-хозяйственной<br /> деятельности предприятия</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Цифровую трансформацию предприятия</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Цифровую экономику</span>
                                </div>
                            </div>
                        </div>  

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Аналитиком</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Бизнес-аналитиком</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Аналитиком данных (аналитик Big Data)</span>
                                </div>
                                <div className="info_block-text-row">
                                <div className="info_block-text-row_img"><img src={img4}/></div>
                                    <span className="text-line">Специалистом в области искусственного интеллекта</span>
                                </div>
                            </div>
                            
                        </div>  
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">  
                            <img src={bg_left}/>
                            <img src={bg_right}/>
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Генерирование новых решений <br />для предприятий и<br /> организаций</span>
                                </div>
                                
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Трансформирование целей<br /> проекта в бизнес-требования</span> 
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Проведение воркшопов,<br /> общих конструктивных<br /> собраний</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Планирование бизнес-анализа<br /> организаций и предприятий</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Использование визуальных инструментов для<br /> представления информации</span> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionEconom /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}