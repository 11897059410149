import React from "react";

import './Container.css';

function Container(props) {
    const { className = null, children } = props;
    return <div className={`container ${className && className}`}>{children}</div>;
}

export default Container;
