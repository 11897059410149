import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionMenedch from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionMenedch";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/machiyanova.jpg";
import doc from "../../../../assets/documents/uch-plan/menedcment.pdf";

import img1 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/EconomTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/MenedchTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/MenedchTeacher/large-frame-bg2.svg";
import "./MenedchTeacherPage.css";

export default function MenedchTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher manage">
                    <span className="teacher__title">Менеджмент организации
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Махиянова Алина Владимировна</span>
                            <span className="teacher__text">д.с.н., доцент кафедры <br />«Менеджмент» (М)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">socavm@rambler.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-91</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. Д-704</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>

                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>

                        <div className="teacher_exams" >

                            <span className="teacher__title" >Вступительные испытания</span>

                            {/* Обязательные предметы */}
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                            </div>

                            {/* Предметы по выбору */}
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Soc">О</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="His">Ис</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                            </div>

                            <span className="teacher__title">Проходной<br /> балл на 2023 г.</span>
                            <span className="passing_score">—</span>
                        </div>
                    </div>

                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Бизнес-планирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Разработку управленческих решений</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Организацию предпринимательской деятельности</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Методы принятия управленческих<br /> решений</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Инвестиционный менеджмент организации</span>
                                </div>
                            </div>
                        </div>

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1} /></div>
                                    <span className="text-line">Начальником отдела или функциональных <br />служб</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2} /></div>
                                    <span className="text-line">Руководителем компаний</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3} /></div>
                                    <span className="text-line">Офис-менеджером и топ-менеджером</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img4} /></div>
                                    <span className="text-line">Руководителем подразделения</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">
                            <img src={bg_left} />
                            <img src={bg_right} />
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Управление инструментами<br />  формирования товарного<br />  ассортимента</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Осуществление бизнес и медиа-<br />планов</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Работа с персоналом любой<br /> организации, осуществление<br />  его подбора</span>
                                </div>
                            </div>


                            <div className="frame-large-text-row last" >
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Разработка кадровой<br /> политики, стратегии<br /> организаций, стандартов и<br /> корпоративных регламентов</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Создание эффективной схемы функционирования<br /> процессов в организации и осуществление<br /> контроля работы подразделений и повышение<br /> эффективности труда персонала</span>
                                </div>
                            </div>

                        </div>
                    </div>EconomTeacher/info-block/
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionMenedch /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}