import React from "react";

import "./PassingPoints.css";

export default function PassingPoints() {

    return (
        <div className="passing-points">
            <span className="individual-achivments__title">Проходные баллы прошлых лет</span>
            <div className="list-of-documents__block">
                <div className="list-of-documents__block-row">
                    <span className="list-of-documents__block-title">Наименование направления</span>
                    <span className="list-of-documents__block-text"> 2022 г.</span>
                    <span className="list-of-documents__block-text"> 2023г.</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Информатика и вычислительная техника</span>
                    <span className="list-of-documents__block-text"> 227</span>
                    <span className="list-of-documents__block-text"> 239</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Прикладная информатика</span>
                    <span className="list-of-documents__block-text"> 255</span>
                    <span className="list-of-documents__block-text"> 233</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Прикладная математика</span>
                    <span className="list-of-documents__block-text"> 213</span>
                    <span className="list-of-documents__block-text"> 225</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Автоматизация технологических процессов и производств</span>
                    <span className="list-of-documents__block-text"> 207</span>
                    <span className="list-of-documents__block-text"> 214</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Мехатроника и роботехника</span>
                    <span className="list-of-documents__block-text"> 203</span>
                    <span className="list-of-documents__block-text"> 221</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Электроэнергетика и электротехника</span>
                    <span className="list-of-documents__block-text"> 189</span>
                    <span className="list-of-documents__block-text"> 187</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Энергетическое машиностроение</span>
                    <span className="list-of-documents__block-text"> 147</span>
                    <span className="list-of-documents__block-text"> 146</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Теплоэнергетика и теплотехника</span>
                    <span className="list-of-documents__block-text"> 169</span>
                    <span className="list-of-documents__block-text"> 160</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Электроника и наноэлектроника</span>
                    <span className="list-of-documents__block-text"> 154</span>
                    <span className="list-of-documents__block-text"> 157</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Техносферная безопасность</span>
                    <span className="list-of-documents__block-text"> 162</span>
                    <span className="list-of-documents__block-text"> 148</span>
                </div>
                <div className="list-of-documents__block-row two">
                    <span className="list-of-documents__block-title">Водные биоресурсы и аквакультура</span>
                    <span className="list-of-documents__block-text"> 149</span>
                    <span className="list-of-documents__block-text"> 162</span>
                </div>
                <div className="list-of-documents__block-row one">
                    <span className="list-of-documents__block-title">Атомные станции: Проектирование, эксплуатация и инжиниринг</span>
                    <span className="list-of-documents__block-text"> - </span>
                    <span className="list-of-documents__block-text"> 217</span>
                </div>
            </div>
        </div>
    );
}