import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";
import EducationalProgramsMech from "../../../components/ComponentsUndergraduatePage/ComponentsEducationalPrograms/EducationalProgramsMech";

import Container from "../../../components/Container";

import "./MechatrAndRobotPage.css";

export default function MechatrAndRobotPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top abiturient">
                    <span className="etap-postupleniya__title">Бакалавриат, специалитет</span>
                </div>
                <div className="step-prospective-student__top undergraduate-ICTE-page educational-program abiturient">
                    {/* <Link to="/undergraduate-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-ite" className="etap-postupleniya__title">Институт теплоэнергетики</Link>
                    <Link to="/undergraduate-icte" className="etap-postupleniya__title one">Институт цифровых технологий и экономики</Link> */}
                </div>
                <EducationalProgramsMech />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
