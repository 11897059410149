import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionPT from "../../../../components/ComponentsUndergraduatePage/ITE/ITEQuestion/QuestionPT";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsITE/vankov.jpg";
import doc from "../../../../assets/documents/uch-plan/teplo.pdf";

import img1 from "../../../../assets/images/New/TECTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/TECTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/TECTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/TECTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/PTTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/PTTeacher/large-frame-bg2.svg";
import "./PTTeacherPage.css";

export default function PTTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher pt">
                    <span className="teacher__title">Промышленная теплоэнергетика
                    </span>
                    <div className="teacher__main-block">
                    <div className="teacher__block-title">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Ваньков Юрий Витальевич</span>
                            <span className="teacher__text">д.т.н., профессор кафедры «Промышленная теплоэнергетика и системы теплоснабжения» (ПТЭ)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">yvankov@kgeu.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-12</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. Б-306е</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>

                            </div>
                            </div>
                        </div>
                        <div className="teacher_exams" >
                            <div className="teacher_exams-column">

                                <span className="teacher__title" >Вступительные испытания</span>

                                {/* Обязательные предметы */}
                                <div className="teacher_exams-row">
                                    <div className="teacher_exams-column">
                            <span className="teacher__text">Обязательные предметы</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Math" >М</span>
                                </div>
                                <div className="subject">
                                    <span className="Rus">Р</span>
                                </div>
                                        </div>
                                    </div>

                                    {/* Предметы по выбору */}
                                    <div className="teacher_exams-column">
                            <span className="teacher__text">Предметы по выбору</span>

                            <div className="subjects_group">
                                <div className="subject">
                                    <span className="Phys">Ф</span>
                                </div>
                                <div className="subject">
                                    <span className="Chem">Х</span>
                                </div>
                                <div className="subject">
                                    <span className="Eng">А</span>
                                </div>
                                <div className="subject">
                                    <span className="IT">И</span>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="teacher_exams-column">

                                <span className="teacher__title">Проходной балл на 2023 г.</span>
                            <span className="passing_score">160</span>
                        </div>
                    </div>
                    </div>

                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Инженерное проектирование</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Источники производства теплоты предприятий и объектов</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Тепломассообменное оборудование предприятий</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Нагнетатели и тепловые двигатели</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Системы автоматизации и контроля</span>
                                </div>
                            </div>
                        </div>

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1} /></div>
                                    <span className="text-line">Руководителем по эксплуатации и наладке различного теплотехнического oборудования</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2} /></div>
                                    <span className="text-line">Инженером-теплотехником</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3} /></div>
                                    <span className="text-line">Инженером-теплоэнергетиком</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img4} /></div>
                                    <span className="text-line">Инженером-энергетиком</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">
                            <img src={bg_left} />
                            <img src={bg_right} />
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Проектирование и реконструирование сетей теплоснабжения</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Контроль соответствия разрабатываемых бизнес-процессов</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Разработка систем бизнес-процессов</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Обслуживание теплосиловых установок, гидромашин предприятий энергетики</span>
                                </div>

                                <div className="info_block-text-row last">
                                    <span className="number">05</span>
                                    <span className="text-line">Ведение энергообследования, разработка и реализация 
                                        энергосберегающих мероприятий в организациях, на объектах 
                                        бюджетной сферы и на промышленных предприятиях</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <span className="teacher__sub-block">Обучение ведется на русском языке</span>
                    <QuestionPT /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}