import React from "react";

import InostranieStudentImg from "../../ComponentsBacalavriatPage/InostranieStudentImg";

import "./ReceptioOfForeignCitizens.css";

export default function ReceptioOfForeignCitizens() {
  return (
    <div className="receptio-of-foreign-citizens" id="inostranci">
      <span className="individual-achivments__title">
        Прием иностранных граждан
      </span>
      <div className="receptio-of-foreign-citizens__block">
        <div className="receptio-of-foreign-citizens__block-column">
          <div className="receptio-of-foreign-citizens__block-text one">
            <span className="receptio-of-foreign-citizens__number">1</span>
            <span className="receptio-of-foreign-citizens__text">
              Паспорт с переводом на русский язык, заверенный нотариусом
            </span>
          </div>
          <div className="receptio-of-foreign-citizens__block-text two">
            <span className="receptio-of-foreign-citizens__number">2</span>
            <span className="receptio-of-foreign-citizens__text">
              Документ об образовании, легализованный и переведнный на русский язык, заверенный нотариусом
            </span>
          </div>
          <div className="receptio-of-foreign-citizens__block-text one">
            <span className="receptio-of-foreign-citizens__number">3</span>
            <span className="receptio-of-foreign-citizens__text">
              Медицинская справка (для стран СНГ — справка по форме № 086у,
              для стран дальнего зарубежья — оформление по прибытии)
            </span>
          </div>
          <div className="receptio-of-foreign-citizens__block-text two">
            <span className="receptio-of-foreign-citizens__number">4</span>
            <span className="receptio-of-foreign-citizens__text">
              Финансовые Гарантии
            </span>
          </div>
          <div className="receptio-of-foreign-citizens__block-text one">
            <span className="receptio-of-foreign-citizens__number">5</span>
            <span className="receptio-of-foreign-citizens__text">
              Документ, подтверждающий знание русского языка
            </span>
          </div>
        </div>
        <div className="receptio-of-foreign-citizens__block-column">
          <span className="receptio-of-foreign-citizens__block-column-title">ВНИМАНИЕ</span>
          <span className="receptio-of-foreign-citizens__text">
            • по прибытии каждый иностранный гражданин должен оформить полис медицинского страхования
          </span>
          <span className="receptio-of-foreign-citizens__text">
            • по прибытии каждый иностранный гражданин должен связаться с отделом международный связей (ОМДС), который находится по адресу:
          </span>
          <span className="receptio-of-foreign-citizens__text">
            Казань, ул. Красносельская, 51<br />
            (ст. метро "Козья Слобода")
          </span>
          <span className="receptio-of-foreign-citizens__text last">
            Корпус В, каб. В-300,
          </span>
          <span className="receptio-of-foreign-citizens__text">

            <span className="receptio-of-foreign-citizens__text blue">
              тел. 8 (843) 519-43-69
            </span>
          </span>
          <span className="receptio-of-foreign-citizens__text">
            <span className="receptio-of-foreign-citizens__text blue">
              e-mail: cio_kgeu@mail.ru
            </span>
          </span>
          <span className="receptio-of-foreign-citizens__text last">
            Рабочий день:<br />
            понедельник - пятница
          </span>
          <span className="receptio-of-foreign-citizens__text">
            <span className="receptio-of-foreign-citizens__text blue">
              с 8:30 до 17:30
            </span>
          </span>
        </div>
      </div>
      <InostranieStudentImg />
    </div>
  );
}
