import React from "react";
import { Link } from "react-router-dom";

import individualki from "../../../assets/documents/Undergraduate/individualki.pdf";
import sroki from "../../../assets/documents/Undergraduate/sroki.pdf";

import "./AdmissionAfterCollege.css";

export default function AdmissionAfterCollege(props) {

    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="admission-after-college" id="after-college">
            <span className="individual-achivments__title">Поступление после колледжа</span>
            <span className="payment-methods__subtitle">
                Узнай порядок поступления после колледжа
            </span>
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">1</span>
                            <span className="question-content__item-title">
                                Порядок поступления
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Поступающие на базе профессионального образования (диплом колледжа, диплом университета) могут поступить как по результатам ЕГЭ
                            так и по результатам вступительных испытаний, проводимых КГЭУ самостоятельно в соответствии с перечнем вступительных испытаний.
                        </span>
                        <span className="question-content__item-text">
                            Вся информация о поступлении представлена на сайте в разделе «Абитуриенту».
                        </span>
                        <span className="question-content__item-text">
                            Будьте внимательны! Сроки приема документов поступающих на обучение по результатам вступительных испытаний, проводимых КГЭУ самостоятельно
                            отличается от сроков приема документов, поступающих по результатам ЕГЭ.
                        </span>
                        <span className="question-content__item-text">
                            Рекомендуем заранее ознакомиться с основными датами календаря абитуриента.
                        </span>
                        <span className="question-content__item-text">
                            К сожалению, мы не можем гарантировать достоверность предоставленной информации сторонними
                            ресурсами (сайтами образовательных организаций и порталов), поэтому настоятельно рекомендуем вам
                            проверять информацию о поступлении на официальном сайте университета, сайте Приемной комиссии или
                            по телефонам контакт-центра Приемной комиссии:
                        </span>
                        <span className="question-content__item-text">
                            тел. <span className="admission-after-school__text blue">8 (843) 519-42-42; 8 (843) 519-43-23</span> <br />
                            e-mail: <span className="admission-after-school__text blue">pk@kgeu.ru</span><br />
                            420066, г. Казань, ул. Красносельская, 51
                        </span>
                        <span className="question-content__item-text">
                            Если Вы являетесь гражданином другого государства, то для понимания порядка зачисления вам необходимо
                            определить свой статус в отделе по работе с иностранными студентами.
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">2</span>
                            <span className="question-content__item-title">
                                Документы
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Подать документы можно электронно через личный кабинет и лично по адресу:
                        </span>
                        <span className="question-content__item-text title-blue">
                            420066, РТ, г. Казань, ул. Красносельская, д. 51, корпус В, каб. 100
                        </span>
                        <span className="question-content__item-text">
                            Сформированное в личном кабинете заявление необходимо распечатать, подписать, отсканировать и в формате .pdf
                            загрузить в Личный кабинет абитуриента в разделе “Загрузить документы”.
                        </span>
                        <span className="question-content__item-text">
                            Для подтверждения ваших баллов ЕГЭ, вам не требуется предоставлять никаких документов. Если вы не знаете или
                            не помните результатов ЕГЭ, вы можете подавать документы, так как все данные подгружаются автоматически из
                            федеральной базы ЕГЭ по вашим паспортным данным (если произошла замена паспорта, а ЕГЭ вы сдавали по старому
                            паспорту, то нужны будут данные старого паспорта).
                        </span>
                        <span className="question-content__item-text">
                            Если ваши баллы за предмет меньше установленного университетом минимума, то вы не можете участвовать в
                            конкурсе на поступление. С минимальными (пороговыми) баллами вы можете ознакомиться по ссылке.
                        </span>
                        <span className="question-content__item-text">
                            Копии документов для подачи в приёмную комиссию заверять не нужно.
                        </span>

                        <span className="question-content__item-text">
                            Даже если вы подаете заявление на несколько направлений в университет, вы всё равно предоставляете только
                            один комплект документов.
                        </span>
                        <span className="question-content__item-text">
                            В рамках университета вы можете подать только на пять направлений по одной образовательной программе в каждом.
                            Однако в рамках каждого направления вы можете выбирать разные формы обучения (очная, очно-заочная, заочная) и
                            способ финансирования обучения (бюджет/ платное).
                        </span>
                        <span className="question-content__item-text">
                            Если вы поступаете на бюджетную форму обучения, то заявление о согласии на зачисление можно переписать только
                            ТРИ РАЗА. Напоминаем, что без заявления на согласие абитуриент не может быть зачислен. Со сроками подачи документов
                            можно ознакомиться <Link to={sroki} className="question-content__item-text title-blue" target="_blank" rel="noreferrer">ЗДЕСЬ</Link>
                        </span>
                        <span className="question-content__item-text">
                            Если ваш документ об образовании выдан в другом государстве, вам необходимо пройти процедуру экспертизы, которая подтверждает
                            уровень вашего образования.
                        </span>
                        <span className="question-content__item-text">
                            Во время подачи документов в приёмную комиссию при поступлении на обучение по направлениям подготовки «Теплоэнергетика и
                            теплотехника» и «Электроэнергетика и электротехника» вам необходимо предоставить медицинскую справку (в форме 086у, оригинал или копию).
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">3</span>
                            <span className="question-content__item-title">
                                Индивидуальные достижения
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Максимальная сумма баллов, которые вы можете получить за индивидуальные достижения - 10 баллов.
                        </span>
                        <span className="question-content__item-text">
                            С перечнем индивидуальных достижений можно ознакомиться <Link to={individualki} className="question-content__item-text title-blue" target="_blank" rel="noreferrer">ЗДЕСЬ</Link>
                        </span>
                    </li>
                </ul>
                {/* ----------- */}
                <ul className="question-content__menu">
                    <li className="question-content__item" onClick={handleClickQuestion}>
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title-number">4</span>
                            <span className="question-content__item-title">
                                Вступительные испытания
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Поступающие на базе профессионального образования (диплом колледжа, диплом университета) могут поступить как по результатам ЕГЭ так и по
                            внутренним вступительным испытаниям ВУЗа в соответствии с перечнем вступительных испытаний.
                        </span>
                        <span className="question-content__item-text">
                            Перед экзаменом рекомендуем вам ознакомиться с программами вступительных испытаний и с положением о вступительных испытаниях, проводимых в КГЭУ.
                        </span>
                        <span className="question-content__item-text">
                            Записаться на экзамены можно <Link to="//forms.yandex.ru/cloud/6641f7fcc417f3a862f27a3c/" className="question-content__item-text title-blue" target="_blank" rel="noreferrer">ЗДЕСЬ</Link>
                        </span>
                    </li>
                </ul>
            </nav>
            <div className="entrance-tests">
                <span className="individual-achivments__title">Вступительные испытания</span>
                <div className="acceptance-checksum__table-title-block">
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Код и наименование <br />направления</span>
                    </div>
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Перечень вступительных <br />испытаний</span>
                    </div>
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Минимальный <br />балл</span>
                    </div>
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Форма вступительных <br />испытаний</span>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block">
                    <span className="acceptance-checksum__table-title-text">Институт теплоэнергетики</span>
                </div>

                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.01</span>
                            <Link to="/heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика <br />и теплотехника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.03</span>
                            <Link to="/energy-power-engineering" className="acceptance-checksum__table-text">Энергетическое <br />машиностроение</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">15.03.04</span>
                            <Link to="/automation-of-technological-processes-and-productions" className="acceptance-checksum__table-text">Автоматизация технологических <br />процессов и производств</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">35.03.08</span>
                            <Link to="/aquatic-bioresources-and-aquaculture" className="acceptance-checksum__table-text">Водные биоресурсы <br />и аквакультура</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects biol">
                                <span className="acceptance-checksum__subjects">
                                    БЭ
                                </span>
                            </div>
                            
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>

                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">14.05.02</span>
                            <Link to="/nuclear-power-plants-design-operation" className="acceptance-checksum__table-text">Атомные станции: <br />Проектирование,<br />эксплуатация и инжиниринг</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block title">
                    <span className="acceptance-checksum__table-title-text">Институт электроэнергетики и электроники</span>
                </div>

                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">11.03.04</span>
                            <Link to="/electronics-and-nanoelectronics" className="acceptance-checksum__table-text">Электроника <br />и наноэлектроника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.03.02</span>
                            <Link to="/electric-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика <br />и электротехника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">20.03.01</span>
                            <Link to="/technosphere-safety" className="acceptance-checksum__table-text">Техносферная <br />безопасность</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block title">
                    <span className="acceptance-checksum__table-title-text">Институт цифровых технологий и экономики</span>
                </div>

                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">01.03.04</span>
                            <Link to="/applied-mathematics" className="acceptance-checksum__table-text">Прикладная математика</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    ПИ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">44</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">09.03.01</span>
                            <Link to="/computer-science-and-engineering" className="acceptance-checksum__table-text">Информатика <br />и вычислительная техника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    ПИ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">44</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">09.03.03</span>
                            <Link to="/applied-computer-science" className="acceptance-checksum__table-text">Прикладная информатика</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects inf">
                                <span className="acceptance-checksum__subjects">
                                    ПИ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">44</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">12.03.01</span>
                            <Link to="/instrumentation" className="acceptance-checksum__table-text">Приборостроение</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">15.03.06</span>
                            <Link to="/mechatronics-and-robotics" className="acceptance-checksum__table-text">Мехатроника <br />и робототехника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects fiz">
                                <span className="acceptance-checksum__subjects">
                                    ОЭ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">38.03.01</span>
                            <Link to="/economy" className="acceptance-checksum__table-text">Экономика</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    ЭТ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">45</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">38.03.02</span>
                            <Link to="/management" className="acceptance-checksum__table-text">Менеджмент</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects math">
                                <span className="acceptance-checksum__subjects">
                                    ПМ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    ЭТ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">45</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>

                <div className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">39.03.01</span>
                            <Link to="/sociology" className="acceptance-checksum__table-text">Социология</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    ЭТ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects his">
                                <span className="acceptance-checksum__subjects">
                                    ИР
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">45</span>
                            <span className="acceptance-checksum__table-text">39</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">42.03.01</span>
                            <Link to="/advertising-and-public-relations" className="acceptance-checksum__table-text">Реклама и связи <br />с общественностью</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <div className="acceptance-checksum__subjects obsh">
                                <span className="acceptance-checksum__subjects">
                                    ЭТ
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects his">
                                <span className="acceptance-checksum__subjects">
                                    ИР
                                </span>
                            </div>
                            <div className="acceptance-checksum__subjects rus">
                                <span className="acceptance-checksum__subjects">
                                    РЯ
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">45</span>
                            <span className="acceptance-checksum__table-text">35</span>
                            <span className="acceptance-checksum__table-text">40</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">Письменная форма</span>
                        </div>
                    </div>
                </div>
                <div className="entrance-tests__column finish">
                    <span className="acceptance-checksum__table-text">Предметы расположены по приоритетности вступительных испытаний при ранжировании
                        поступающих по результатам <br />вступительных испытаний</span>
                </div>


            </div>
        </div>
    );
}
