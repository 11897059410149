import React from "react";
import { Link } from "react-router-dom";

import "./EducationalProgramsElectroenerget.css";

const items = [
  {
    subtitle: "187",
    text: (
      <React.Fragment>
        Проходной балл <br /> в 2023 (бюджет)
      </React.Fragment>
    ),
  },
  {
    subtitle: "427; 70",
    text: (
      <React.Fragment>
        Количество бюджетных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "25; 250",
    text: (
      <React.Fragment>
        Количество платных <br />
        мест на оч/ф; — заоч/ф
      </React.Fragment>
    ),
  },
  {
    subtitle: "163 160₽",
    text: "Стоимость платного обучения на оч/ф",
  },
  {
    subtitle: "63 600₽",
    text: "Стоимость платного обучения на заоч/ф",
  },
];

export default function EducationalProgramsElectroenerget() {
  return (
    <div className="educational-program">
      <span className="individual-achivments__title">
        Институт электроэнергетики и электроники
      </span>

      <table className="educational-program__scroll-table">
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <span className="educational-program__block-title">
              Образовательные программы: <br />
              «Электроэнергетика и электротехника»
            </span>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-title sub">
              Бюджет очная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-title sub">
              Бюджет заочная
              <br />
              форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              очная форма
            </span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-title sub">
              Платные места
              <br />
              заочная форма
            </span>
          </div>
        </tr>
        {/* <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/high-voltage-electric-power-and-electrical-engineering"
              className="educational-program__block-text"
            >
              Высоковольтные электроэнергетика и электротехника
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/electric-stations-and-substations"
              className="educational-program__block-text one"
            >
              Электрические станции и подстанции
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/relay-protection-and-automation-of-electric-power-systems"
              className="educational-program__block-text"
            >
              Релейная защита и автоматизация электроэнергетических систем
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/electric-power-systems-and-networks"
              className="educational-program__block-text one"
            >
              Электроэнергетические системы и сети
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        {/* <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/renewable-energy-sources"
              className="educational-program__block-text"
            >
              Возобновляемые источники энергии
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr> */}
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/electrical-equipment-and-electrical-facilities-of-enterprises-organizations-and-institutions"
              className="educational-program__block-text"
            >
              Проектирование и эксплуатация электрохозяйства потребителей
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/power-supply"
              className="educational-program__block-text one"
            >
              Электроснабжение
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/electric-car-and-unmanned-transport"
              className="educational-program__block-text"
            >
              Электромобильный и беспилотный транспорт
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/electromechanical-complexes-and-systems"
              className="educational-program__block-text one"
            >
              Электромеханические комплексы и системы
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">✓</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row one">
          <div className="educational-program__scroll-table-column one">
            <Link to="/digital-electric-power-automation-systems"
              className="educational-program__block-text one"
            >
              Цифровые системы автоматизации в электроэнергетике
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
        <tr className="educational-program__scroll-table-row">
          <div className="educational-program__scroll-table-column one">
            <Link to="/economics-and-management-in-the-electric-power-industry"
              className="educational-program__block-text"
            >
              Экономика и управление в электроэнергетике
            </Link>
          </div>
          <div className="educational-program__scroll-table-column two">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column three">
            <span className="educational-program__block-text">—</span>
          </div>
          <div className="educational-program__scroll-table-column four">
            <span className="educational-program__block-text">✓</span>
          </div>
          <div className="educational-program__scroll-table-column five">
            <span className="educational-program__block-text">—</span>
          </div>
        </tr>
      </table>
      <div className="educational-program__main-block elect">
        {items.map((item, index) => (
          <div
            className="individual-achivments__text-block"
            key={String(index)}
          >
            <span className="individual-achivments__subtitle">
              {item.subtitle}
            </span>
            <span className="individual-achivments__text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
