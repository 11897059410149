import React from "react";

import "./QuestionPribor.css";

export default function QuestionPribor(props) {
    const handleClickQuestion = (e) => {
        const current = e.currentTarget;
        current.classList.toggle("question-content__item--active");
    };

    return (
        <div className="step-prospective-student-menu question">
            <nav className="question-content">
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Объект, область профессиональной деятельности студента
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Область профессиональной деятельности, в которой выпускники могут осуществлять профессиональную деятельность – это производство электрического и электронного оборудования, сфера проектирования, конструирования, технологической подготовки и сопровождения производства электронного оборудования, производство, технический контроль, постпродажное обслуживание и сервис технических систем и приборов, сфера научного и аналитического приборостроения.
                            Также выпускники могут осуществлять профессиональную деятельность в других областях промышленности.
                        </span>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Базы прохождения практик
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Студенты проходят практику в отделах технического контроля и главного метролога таких предприятий как: Казанский авиационный завод им. С.П. Горбунова филиал ПАО «Туполев», «Научно-производственное объединение «Радиоэлектроника» имени В.И.Шимко»,
                            Казанский физико-технический институт им. Е.К. Завойского – ОСП ФИЦ КазНЦ РАН.
                        </span>
                    </li>
                </ul>
                <ul className="question-content__menu">
                    <li
                        className="question-content__item"
                        onClick={handleClickQuestion}
                    >
                        <div className="question-content__item-title-block">
                            <span className="question-content__item-title">
                                Трудоустройство выпускников
                            </span>
                        </div>
                        <span className="question-content__item-text">
                            Выпускники кафедры крайне востребованы, как на данных предприятиях, так и в отделах контроля качества продукции других промышленных предприятий и являются универсальными специалистами попроектированию, созданию, эксплуатации, ремонту, контролю качества выпускаемых изделий на всех без исключения предприятиях независимо от ведомственной подчиненности и форм собственности; успешно трудятся на
                            предприятиях топливно-энергетических систем, промышленных и сельскохозяйственных предприятиях РФ и РТ.
                        </span>

                    </li>
                </ul>
            </nav>

        </div>
    );
}
