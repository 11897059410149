import React from "react";
// import { Link } from "react-router-dom";

import Slider from "react-slick";

import Container from "../../Container";

import "./SliderMainPage.css";

import { lastChangesMainPage } from "../../../constants";

import LastChanges from "../../../components/ComponentsMainPage/LastChanges";

// import sliderOne from "../../../assets/images/SliderMainPage/DOD-bcg.jpg";
import sliderTwo from "../../../assets/images/SliderMainPage/FirstStepsInEnergy.png";
import sliderFour from "../../../assets/images/SliderMainPage/excursion2.png";
import sliderFour2 from "../../../assets/images/SliderMainPage/excursion.png";
import magipeople from "../../../assets/images/SliderMainPage/magistracy-people.png";
import spo from "../../../assets/images/SliderMainPage/SPO.png";
import spogirls from "../../../assets/images/SliderMainPage/SPOgirl.png";
import manager from "../../../assets/images/SliderMainPage/manager.png";
import manager1040 from "../../../assets/images/SliderMainPage/manager1040.png";
import spoLogo from "../../../assets/images/SliderMainPage/spoLogo.png";
import managerLogo from "../../../assets/images/SliderMainPage/managerLogo.png";
import opendayBlock1040 from "../../../assets/images/SliderMainPage/opendayCalendar1040.svg";
import opendayGirl from "../../../assets/images/SliderMainPage/opendayGirl.svg";
// import hopeEnergyMen from "../../../assets/images/SliderMainPage/lighting.png";
import logo from "../../../assets/images/SliderMainPage/55logo.png";
import logoWhite from "../../../assets/images/SliderMainPage/54logo.png";
// import hopeEnergyLogo from "../../../assets/images/SliderMainPage/kgeu-logo.png";
import magi from "../../../assets/images/SliderMainPage/magistracy.png";

// import cursor from "../../../assets/images/cursor.svg";
import whattsApp from "../../../assets/images/WhattsApp.svg";
import mail from "../../../assets/images/Mail.svg";
import vk from "../../../assets/images/Vk.svg";
import telegram from "../../../assets/images/Telegram.svg";

const SliderMainPage = (props) => {
  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <React.Fragment>
      <Container>
        <div className="slider-wrapper two slider">
          {/* 3 слайдер - https://alvarotrigo.com/blog/react-carousels/ */}

          <Slider {...settings} className="slider">

            <div className="slider-wrapper__block spo">
              <img src={spo} alt="" />
              <img src={spoLogo} alt="" className="spo-logo" />
              <img src={spogirls} alt="" className="spo-girls" />
              <Container>
                <div className="slider-wrapper__block-text">
                </div>
                <div className="slider-wrapper__block-text open-day__footer">
                  <div className="open-day__footer text-block">
                    <span className="slider-wrapper__block-subtitle">
                      ТЫ ВЫПУСНИК КОЛЛЕДЖА
                    </span>
                    <span className="slider-wrapper__block-title spo-fill">
                    </span>
                    <span className="slider-wrapper__block-title">
                      У тебя есть уникальный шанс поступить на сокращенную форму обучения и  подготовиться к вступительным испытаниям
                    </span>
                    <a href="//olimpkgeu.ru/spo" target="_blank" className="slider-wrapper__block-title spo-button">
                      Пройди тестирование
                    </a>
                  </div>
                </div>
              </Container>
            </div>

            <div className="slider-wrapper__block manager">
              <img src={manager} alt="" />
              <img src={managerLogo} alt="" className="spo-logo" />
              <Container>
                <div className="slider-wrapper__block-text">
                </div>
                <div className="slider-wrapper__block-text open-day__footer">
                  <div className="open-day__footer text-block">
                    <span className="slider-wrapper__block-subtitle">
                      Персональный
                      менеджер КГЭУ
                    </span>
                    <span className="slider-wrapper__block-title">
                      Сопровождение всю
                      Приемную компанию
                    </span>
                  </div>
                </div>
              </Container>
            </div>

            <div className="slider-wrapper__block magi">
              <img src={magi} alt="" />
              <Container>
                <div className="slider-wrapper__block-text">
                  <img className="logo" src={logo} alt="" />
                </div>
                <div className="slider-wrapper__block-text open-day__footer">
                  <div className="open-day__footer text-block">
                    <span className="slider-wrapper__block-title">
                      Конкурс портфолио для поступления в магистратуру
                    </span>
                    <span className="slider-wrapper__block-title">
                      01.06-20.07
                    </span>
                    <div className="slider-wrapper__block-button">
                      <a
                        href="//lms.kgeu.ru/course/view.php?id=4661"
                        target="_blank"
                        className="slider-wrapper__block-subtitle"
                      >
                        Участвовать
                      </a>
                      <a
                        href="/undergraduate-magistracy-concurs-portfolio"
                        className="slider-wrapper__block-subtitle"
                      >
                        Подробнее
                      </a>
                    </div>
                  </div>
                  <img className="open-day__qr" src={magipeople} alt="" />
                </div>
              </Container>
            </div>

            <div className="slider-wrapper__block two">
              <img src={sliderTwo} alt="" />
              <Container>
                <div className="slider-wrapper__block-text">
                  <span className="slider-wrapper__block-title">
                    Первые шаги в энергетике
                  </span>
                  <div className="slider-wrapper__block-text-row">
                    <a
                      href="/olimpiad-first-steps-of-energy"
                      className="slider-wrapper__block-link"
                    >
                      Подробнее
                    </a>
                    <span className="slider-wrapper__block-subtitle">
                      Хочешь получить дополнительные баллы? <br />
                      Участвуй в олимпиаде
                    </span>
                  </div>
                </div>
              </Container>
            </div>
            <div className="slider-wrapper__block four">
              <img src={sliderFour} alt="" />
              <Container>
                <div className="slider-wrapper__block-text">
                  <span className="slider-wrapper__block-subtitle">
                    Бывали ли вы когда-либо
                  </span>
                  <span className="slider-wrapper__block-title">
                    НА ЭКСКУРСИИ
                  </span>
                  <span className="slider-wrapper__block-subtitle sub">
                    которая была бы не только увлекательна, но и полезна?
                  </span>
                  <a
                    href="//kgeu.ru/News/Item/211/11042"
                    className="slider-wrapper__block-link"
                    target="_blank"
                  >
                    Записаться
                  </a>
                </div>
              </Container>
            </div>
          </Slider>

          <Container>
            <div className="slider-wrapper__text-block">
              <LastChanges changes={lastChangesMainPage} />
              <div className="slider-wrapper__text-block one">
                <div className="slider-wrapper__text-block-column">
                  <div className="slider-wrapper__number-block">
                    <span className="slider-wrapper__number">
                      8 (843) 519-42-23
                    </span>
                  </div>
                </div>
                <div className="slider-wrapper__text-block-link">
                  <a
                    href="//wapp.click/79510618792"
                    className="footer__link one"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={whattsApp} alt="" className="footer__link-img" />
                  </a>
                  <a
                    href="mailto:pk@kgeu.ru"
                    className="footer__link two"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={mail} alt="" className="footer__link-img" />
                  </a>
                  <a
                    href="//vk.com/abiturient_kgeu"
                    className="footer__link three"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={vk} alt="" className="footer__link-img" />
                  </a>
                  <a
                    href="//t.me/kgeu_postupi"
                    className="footer__link four"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={telegram} alt="" className="footer__link-img" />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Container>

      {/* ----------------------------------от 1040px----------------------------------- */}

      <div className="slider-wrapper one">
        {/* 3 слайдер - https://alvarotrigo.com/blog/react-carousels/ */}

        <Slider {...settings} className="slider">

          <div className="slider-wrapper__block spo">
            <img src={spo} alt="" />
            <img src={spoLogo} alt="" className="spo-logo" />
            <img src={spogirls} alt="" className="spo-girls" />
            <Container>
              <div className="slider-wrapper__block-text">
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-subtitle">
                    ТЫ ВЫПУСНИК КОЛЛЕДЖА
                  </span>
                  <span className="slider-wrapper__block-title spo-fill">
                  </span>
                  <span className="slider-wrapper__block-title">
                    У тебя есть уникальный шанс поступить на сокращенную форму обучения и  подготовиться к вступительным испытаниям
                  </span>
                  <a href="//olimpkgeu.ru/spo" target="_blank" className="slider-wrapper__block-title spo-button">
                    Пройди тестирование
                  </a>
                </div>
              </div>
            </Container>
          </div>

          <div className="slider-wrapper__block manager">
            <img src={manager1040} alt="" />
            <img src={managerLogo} alt="" className="spo-logo" />
            <Container>
              <div className="slider-wrapper__block-text">
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-subtitle">
                    Персональный
                    менеджер КГЭУ
                  </span>
                  <span className="slider-wrapper__block-title">
                    Сопровождение всю
                    Приемную компанию
                  </span>
                </div>
              </div>
            </Container>
          </div>

          <div className="slider-wrapper__block magi">
            <img src={magi} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <img className="logo" src={logo} alt="" />
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-title">
                    Конкурс портфолио для поступления в магистратуру
                  </span>
                  <span className="slider-wrapper__block-title">
                    01.06-20.07
                  </span>
                  <div className="slider-wrapper__block-button">
                    <a
                      href="//lms.kgeu.ru/course/view.php?id=4661"
                      target="_blank"
                      className="slider-wrapper__block-subtitle"
                    >
                      Участвовать
                    </a>
                    <a
                      href="/undergraduate-magistracy-concurs-portfolio"
                      className="slider-wrapper__block-subtitle"
                    >
                      Подробнее
                    </a>
                  </div>
                </div>
                <img className="open-day__qr" src={magipeople} alt="" />
              </div>
            </Container>
          </div>

          <div className="slider-wrapper__block two">
            <img src={sliderTwo} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <span className="slider-wrapper__block-title">
                  Первые шаги в энергетике
                </span>
                <div className="slider-wrapper__block-text-row">
                  <a
                    href="/olimpiad-first-steps-of-energy"
                    className="slider-wrapper__block-link"
                  >
                    Подробнее
                  </a>
                  <span className="slider-wrapper__block-subtitle">
                    Хочешь получить дополнительные баллы? <br />
                    Участвуй в олимпиаде
                  </span>
                </div>
              </div>
            </Container>
          </div>
          <div className="slider-wrapper__block four">
            <img src={sliderFour2} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <span className="slider-wrapper__block-subtitle">
                  Бывали ли вы когда-либо
                </span>
                <span className="slider-wrapper__block-title">
                  НА ЭКСКУРСИИ
                </span>
                <span className="slider-wrapper__block-subtitle sub">
                  которая была бы не только увлекательна, но и полезна?
                </span>
                <a
                  href="//kgeu.ru/News/Item/211/11042"
                  className="slider-wrapper__block-link"
                  target="_blank"
                >
                  Записаться
                </a>
              </div>
            </Container>
          </div>
        </Slider>
      </div>



      {/* ----------------------------------от 680px----------------------------------- */}

      <div className="slider-wrapper three">
        {/* 3 слайдер - https://alvarotrigo.com/blog/react-carousels/ */}

        <Slider {...settings} className="slider">

          <div className="slider-wrapper__block spo">
            <img src={spo} alt="" />
            <img src={spoLogo} alt="" className="spo-logo" />
            <img src={spogirls} alt="" className="spo-girls" />
            <Container>
              <div className="slider-wrapper__block-text">
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-subtitle">
                    ТЫ ВЫПУСНИК КОЛЛЕДЖА
                  </span>
                  <span className="slider-wrapper__block-title spo-fill">
                  </span>
                  <span className="slider-wrapper__block-title">
                    У тебя есть уникальный шанс поступить на сокращенную форму обучения и  подготовиться к вступительным испытаниям
                  </span>
                  <a href="//olimpkgeu.ru/spo" target="_blank" className="slider-wrapper__block-title spo-button">
                    Пройди тестирование
                  </a>
                </div>
              </div>
            </Container>
          </div>

          <div className="slider-wrapper__block manager">
            <img src={manager1040} alt="" />
            <img src={managerLogo} alt="" className="spo-logo" />
            <Container>
              <div className="slider-wrapper__block-text">
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-subtitle">
                    Персональный
                    менеджер КГЭУ
                  </span>
                  <span className="slider-wrapper__block-title">
                    Сопровождение всю
                    Приемную компанию
                  </span>
                </div>
              </div>
            </Container>
          </div>

          <div className="slider-wrapper__block magi">
            <img src={magi} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <img className="logo" src={logo} alt="" />
              </div>
              <div className="slider-wrapper__block-text open-day__footer">
                <div className="open-day__footer text-block">
                  <span className="slider-wrapper__block-title">
                    Конкурс портфолио для поступления в магистратуру
                  </span>
                  <span className="slider-wrapper__block-title">
                    01.06-20.07
                  </span>
                  <div className="slider-wrapper__block-button">
                    <a
                      href="//lms.kgeu.ru/course/view.php?id=4661"
                      target="_blank"
                      className="slider-wrapper__block-subtitle"
                    >
                      Участвовать
                    </a>
                    <a
                      href="/undergraduate-magistracy-concurs-portfolio"
                      className="slider-wrapper__block-subtitle"
                    >
                      Подробнее
                    </a>
                  </div>
                </div>
                <img className="open-day__qr" src={magipeople} alt="" />
              </div>
            </Container>
          </div>



          <div className="slider-wrapper__block two">
            <img src={sliderTwo} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <span className="slider-wrapper__block-title">
                  Первые шаги в энергетике
                </span>
                <div className="slider-wrapper__block-text-row">
                  <a
                    href="/olimpiad-first-steps-of-energy"
                    className="slider-wrapper__block-link"
                  >
                    Подробнее
                  </a>
                  <span className="slider-wrapper__block-subtitle">
                    Хочешь получить дополнительные баллы? <br />
                    Участвуй в олимпиаде
                  </span>
                </div>
              </div>
            </Container>
          </div>
          <div className="slider-wrapper__block four">
            <img src={sliderFour2} alt="" />
            <Container>
              <div className="slider-wrapper__block-text">
                <span className="slider-wrapper__block-subtitle">
                  Бывали ли вы когда-либо
                </span>
                <span className="slider-wrapper__block-title">
                  НА ЭКСКУРСИИ
                </span>
                <span className="slider-wrapper__block-subtitle sub">
                  которая была бы не только увлекательна, но и полезна?
                </span>
                <a
                  href="//kgeu.ru/News/Item/211/11042"
                  className="slider-wrapper__block-link"
                  target="_blank"
                >
                  Записаться
                </a>
              </div>
            </Container>
          </div>
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default SliderMainPage;
