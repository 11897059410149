import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsIEE/laptev.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/energoresursosberega.pdf";

import img2 from "../../../../assets/images/New/MagEETTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagEETTeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagEETTeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagEETTeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagEETTeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagEETTeacher/companies/img_company3.png";
import img_company4 from "../../../../assets/images/New/MagEETTeacher/companies/img_company4.png";

import "./EETTeacherPage.css";


export default function EETTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher eet">
                    <span className="teacher__title">Энергоэффективные и экологически безопасные<br />
                        технологии на предприятиях ТЭК
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Лаптев Анатолий Григорьевич</span>
                            <span className="teacher__text">д.т.н., профессор кафедры <br />
                                «Промышленная теплоэнергетика и системы<br />
                                теплоснабжения» (ПАХТ)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">tvt_kgeu@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text">8 (843) 519-42-54</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51,<br /> каб. В-517</span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                            </div>
                        </div>

                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                            Программа направлена на подготовку высококвалифицированных специалистов, умеющих эффективно решать профессиональные задачи в сфере планирования, организации,  разработки безотходных технологий производства в химии, нефтехимии и энергетике, эффективного обращения с отходами производства, снижения уровня загрязнения атмосферного воздуха на производственных промышленных предприятиях.
                            </span>
                        </div>
                    </div>
                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Информационные технологии в технической физике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Управление проектами</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Математическое моделирование в теплофизике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Современные проблемы технической физики</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Технологическое оборудование промышленного производства</span>
                                </div>
                               

                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Специальные вопросы тепломассообмена</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Планирование и проведение исследовательских и экспериментальных работ</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Компьютерные технологии при проектировании технологических процессов</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Энергетическая политика</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Управление проектами в энергетике</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Техногенная безопасность</span>
                                </div>
                            </div>
                        </div>  
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                            <span className="info_block-title">Кем я смогу работать?</span>

                            <div className="info_block-text_area">
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1}/></div>
                                    <span className="text-line">Экологом</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2}/></div>
                                    <span className="text-line">Специалистом в области экологии</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3}/></div>
                                    <span className="text-line">Специалистом по проектированию</span>
                                </div>
                            </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1}/>
                                    <img src={img_company2}/>
                                    <img src={img_company3}/>
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company4}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}