// import React from "react";

import firstSteps from "../../assets/images/MainPage/News/FirstStepEnergy.png";
import dod from "../../assets/images/MainPage/News/DOD.jpg";
import march from "../../assets/images/MainPage/News/marchIntensivу.jpg";
import demo from "../../assets/images/MainPage/News/demoStudent.jpg";
import inchin from "../../assets/images/MainPage/News/inchinir.png";
import portfolio from "../../assets/images/MainPage/News/portfolio.png";
import schoolOlimpiad from "../../assets/images/MainPage/News/olimpiad-school.png";
import construction from "../../assets/images/MainPage/News/construction.png";

export const newsMainPage = [
  {
    link: "//rutube.ru/video/d37db9a308ae44dd583c301f469a66ab/",
    image: dod,
    marker: "Событие",
    data: "27-29.02",
    title: "День открытых дверей",
    text: "Традиционный День открытых дверей в Казанском государственном энергетическом университете пройдет в онлайн",
  },
  {
    link: "//kgeu.ru/News/Item/287/13089",
    image: construction,
    marker: "Событие",
    data: "01.11.2023",
    title: "Конструкторское бюро",
    text: "Инженерно-конструкторское бюро «Старт в профессию» — научно-творческое пространство с современным",
  },
  {
    link: "//kgeu.ru/News/Item/287/13088",
    image: schoolOlimpiad,
    marker: "Событие",
    data: "23.11.23-24.04.24",
    title: "Школа олимпиадной подготовки",
    text: "Если Вы являетесь учеником 9-10 классов",
  },
  {
    link: "/undergraduate-magistracy-concurs-portfolio",
    image: portfolio,
    marker: "Статья",
    data: "01.06.2023",
    title: "Конкурс портфолио",
    text: "Конурс портфолио позволяет поступить в магистрату КГЭУ без вступительных испытаний. Каждый участник может подать",
  },
  {
    link: "//forms.yandex.ru/cloud/63fa1182eb61466e3e981929/",
    image: demo,
    marker: "Мероприятие",
    data: "27-31.03.2023",
    title: "ЯСтудент.demo",
    text: "Уникальная возможнсть узнать университет изнутри, познакомиться с преподавателями, получить индивидуальную консультацию",
  },
  {
    link: "//utalents.ru/republic-center/programs/300?utm_source=localhost&utm_medium=referral&utm_campaign=localhost&utm_referrer=localhost",
    image: march,
    marker: "Мероприятие",
    data: "март 2023",
    title: "Мартовские интенсивы",
    text: "В течение нескольких недель ты будешь изучать технологию моделирования методом послойного наплавления (FDM)",
  },
  {
    link: "//taplink.cc/inginiriumkazan/p/a93731/",
    image: inchin,
    marker: "Мероприятие",
    data: "01.10.2022",
    title: "Инжинириум",
    text: "«Инжинириум МГТУ им. Н.Э. Баумана» в г. Казани в партнерстве с Казанским государственным энергетическим",
  },
  {
    link: "/olimpiad-first-steps-of-energy",
    image: firstSteps,
    marker: "Статья",
    data: "22.11.2021",
    title: "Первые шаги в энергетике",
    text: "На открытии финальной части конкурса член жюри, ведущий инженер управления производственной безопасности",
  },
];
