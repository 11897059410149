import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import Footer from "../../../components/Footer";
import Container from "../../../components/Container";

import qr from "../../../assets/images/Olimpiads/qrTinch.jpg";
import ocenka from "../../../assets/documents/Olimpiads/tinchurin/ocenka.pdf";
import proverka from "../../../assets/documents/Olimpiads/tinchurin/opredelenie-pobeditelya.pdf";
// import rezultati from "../../../assets/documents/Olimpiads/tinchurin/rezultati.pdf";

import "./OlimpiadTinchurin.css";

function OlimpiadTinchurin() {
    return (
        <React.Fragment>
            <TopBar />
            <Container>
                <div className="olimpiad-first-steps tinchury">
                    <div className="olimpiad-first-steps__img-block">

                    </div>
                    <div className="olimpiad-first-steps__main-block">
                        <div className="olimpiad-first-steps__main-block-column">
                            <span className="olimpiad-first-steps__main-block-column-title">27–29 апреля 2022 года в Казанском государственном энергетическом университете проводится Международная молодежная научная конференция Тинчуринские чтения – 2022 «Энергетика и 
                            цифровая трансформация» при поддержке Министерства науки и высшего образования РФ.</span>
                            <span className="olimpiad-first-steps__main-block-column-title">Ежегодная конференция проводится в память первого ректора КГЭУ – Фореля Закировича Тинчурина. (1926–2002).</span>
                            
                            <span className="olimpiad-first-steps__main-block-column-title one">Условия участия в конференции</span>
                            <span className="olimpiad-first-steps__main-block-column-text">Участниками Конференции могут быть обучающиеся российских и зарубежных университетов, колледжей, обучающиеся 
                            9-11 классов школ, аспиранты, молодые ученые и специалисты компаний и предприятий, занимающиеся научно-техническими и прикладными исследованиями, опытно-конструкторскими и проектными работами по тематическим направлениям Конференции. 
                            А также заведующие и преподаватели кафедр вузов, эксперты, специалисты ведущих российских энергетических компаний с государственным 
                            участием и иных энергетических организаций в возрасте не старше 35 лет. </span>
                            <span className="olimpiad-first-steps__main-block-column-text">По результатам конференции планируется издание электронного сборника материалов докладов семинара в авторской редакции с присвоением ISBN. Сборник будет размещен 
                            в Научной электронной библиотеке (eLibrary.ru) и проиндексирован в РИНЦ.</span>
                            <span className="olimpiad-first-steps__main-block-column-text">По рекомендации научного комитета лучшие материалы докладов Конференции будут опубликованы в журналах списка ВАК и Scopus 
                            (журналы будут подобраны в соответствии с тематикой доклада).</span>
                            <span className="olimpiad-first-steps__main-block-column-title one">Оргкомитет и редакционная группа оставляет за собой право не включать в сборник материалы докладов:</span>
                            <span className="olimpiad-first-steps__main-block-column-text">• в которых не представлены (не ясны) указанные выше позиции
                                <br />• материалы докладов не соответствуют требованиям к оформлению
                            </span>
                        </div>
                        <div className="olimpiad-first-steps__main-block-column">
                            <div className="question-content__item-link-block one">
                                <Link to="//cloud.mail.ru/public/Dr9M/Mnq2qeWVF" className="question-content__link-button" target="_blank">
                                    Положение
                                </Link>
                                <Link to="//cloud.mail.ru/public/Vqvx/Wa2w7CEMY" className="question-content__link-button" target="_blank">
                                    Информационное письмо
                                </Link>
                                <Link to={ocenka} className="question-content__link-button" target="_blank">
                                    Оценка работ
                                </Link>
                                <Link to={proverka} className="question-content__link-button" target="_blank">
                                    Критерии проверки
                                </Link>
                                <Link to="//kgeu.ru/Home/Page/122?idShablonMenu=562" className="question-content__link-button" target="_blank">
                                    Результаты
                                </Link>
                            </div>
                            <div className="olimpiad-first-steps__main-block-img">
                                <img src={qr} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment>
    );
}

export default OlimpiadTinchurin;
