import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";

import ListOfDocuments from "../../../components/ComponentsUndergraduatePage/ListOfDocuments";


// import StepsProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import Container from "../../../components/Container";

import { stepsUndergraduateMagistracy } from "../../../constants";

import "./UndergraduateMagistracyDocumentsPage.css";

export default function UndergraduateMagistracyDocumentsPage(props) {
  return (
    <React.Fragment>
      <TopBar />
      <TopBarProspectiveStudent />
      <Container>
        <div className="step-prospective-student__top abiturient">
          <span className="etap-postupleniya__title">Магистратура</span>
        </div>
        {/* <div className="step-prospective-student__top undergraduate-ICTE-page">
                    <Link to="/undergraduate-magistracy-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-magistracy-ite" className="etap-postupleniya__title">Институт теплоэнергетики</Link>
                    <Link to="/undergraduate-magistracy-icte" className="etap-postupleniya__title">Институт цифровых технологий и экономики</Link>
                    <span className="etap-postupleniya__title one">Институт цифровых технологий и экономики</span>
                </div> */}
      </Container>
      <Container>
        <div className="undergraduate-ICTE-page__main abiturient magistracy">
          <div className="etap-postupleniya__content-col">
            <div className="etap-postupleniya__col magistracy">
              <div className="progress__item">
                <a href="/undergraduate-magistracy" className="progress__item-title">
                  Вступительные испытания
                </a>
              </div>
              <div className="progress__item">
                <a href="/undergraduate-magistracy-main-date"
                  className="progress__item-title"
                >
                 Основные даты
                </a>
              </div>
              <div className="progress__item--active">
                <span
                  className="progress__item-title"
                >
                  Перечень документов
                </span>
              </div>
              <div className="progress__item">
                <a href="/undergraduate-magistracy-concurs-portfolio" className="progress__item-title">
                  Конкурс портфолио
                </a>
              </div>
              <div className="progress__item">
                <a href="//kgeu.ru/Document/GetDocument/2904ccb5-0455-4e15-abea-f30ba77ea7ba" target="_blank" className="progress__item-title">
                  Расписание экзаменов на бюджетную форму
                </a>
              </div>
              <div className="progress__item">
                <a href="//kgeu.ru/Document/GetDocument/ebacb432-e627-4feb-8d04-dfa4a0734f29" target="_blank" className="progress__item-title">
                  Расписание экзаменов на платную форму
                </a>
              </div>
            </div>
            <div className="step-prospective-student etap-postupleniya__col col__two">
              <div className="etap-postupleniya__content-tab--active">
                <span className="etap-postupleniya__text">
                <ListOfDocuments />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
