import React from "react";
import { Link } from "react-router-dom";

import "./EntranceTestsMagistracy.css";

export default function EntranceTestsMagistracy() {
    return (
        <div className="entrance-tests magistracy">
                <span className="individual-achivments__title">Вступительные испытания</span>
            <table className="scroll-table">
                <tr className="acceptance-checksum__table-title-block">
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Код и наименование направления</span>
                    </div>
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Вступительные испытания</span>
                    </div>
                    <div className="entrance-tests__column">
                        <span className="acceptance-checksum__table-title-text">Форма проведения вступительных испытаний</span>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">08.04.01</span>
                            <Link to="/construction" className="acceptance-checksum__table-text">Строительство</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">09.04.01</span>
                            <Link to="/computerm-science-and-engineering" className="acceptance-checksum__table-text">Информатика<br />и вычислительная техника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">11.04.04</span>
                            <Link to="/electronicsm-and-nanoelectronics" className="acceptance-checksum__table-text">Электроника<br />и наноэлектроника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">12.04.01</span>
                            <Link to="/instrumentationm" className="acceptance-checksum__table-text">Приборостроение</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.04.01</span>
                            <Link to="/undergraduate-magistracy-heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика<br />и теплотехника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">13.04.02</span>
                            <Link to="/electricm-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика<br />и электротехника</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">15.04.04</span>
                            <Link to="/utomationm-of-technological-processes-and-productions" className="acceptance-checksum__table-text">Автоматизация технологических <br />процессов и производств</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">16.04.01</span>
                            <Link to="/technicalm-physics" className="acceptance-checksum__table-text">Техническая<br />физика</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">20.04.01</span>
                            <Link to="/technospherem-safety" className="acceptance-checksum__table-text">Техносферная безопасность</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">27.04.01</span>
                            <Link to="/managementm-in-technical-systems" className="acceptance-checksum__table-text">Управление<br />в технических системах</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block two">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">35.04.08</span>
                            <Link to="/magistracy-quatic-bioresources-and-aquaculture" className="acceptance-checksum__table-text">Водные биоресурсы<br />и аквакульутра</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
                <tr className="acceptance-checksum__table-title-block one">
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__table-text">38.04.02</span>
                            <Link to="/managementm" className="acceptance-checksum__table-text">Менеджмент</Link>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Междисциплинарный экзамен</span>
                        </div>
                    </div>
                    <div className="entrance-tests__column">
                        <div className="acceptance-checksum__table-title">
                            <span className="acceptance-checksum__subjects">Тестирование, <br />Письменная форма</span>
                        </div>
                    </div>
                </tr>
            </table>
        </div>
    );
}
