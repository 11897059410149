import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../components/TopBar";
import TopBarProspectiveStudent from "../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../components/Footer";
import EducationalProgramsTeplotech from "../../../components/ComponentsUndergraduatePage/ITE/ComponentsEducationalProgramsITE/EducationalProgramsTeplotech";

import Container from "../../../components/Container";

import "./TeplotechPage.css";

export default function TeplotechPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top abiturient">
                    <span className="etap-postupleniya__title">Бакалавриат, специалитет</span>
                </div>
                <div className="step-prospective-student__top undergraduate-ICTE-page educational-program abiturient">
                    {/* <Link to="/undergraduate-iee" className="etap-postupleniya__title">Институт электроэнергетики и электроники</Link>
                    <Link to="/undergraduate-ite" className="etap-postupleniya__title one">Институт теплоэнергетики</Link>
                    <Link to="/undergraduate-icte" className="etap-postupleniya__title">Институт цифровых технологий и экономики</Link> */}
                </div>
                <EducationalProgramsTeplotech />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
