import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
// import QuestionPM from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPM";
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/torkunova.jpg";
import doc from "../../../../assets/documents/uch-plan/magistratura/ivt.pdf";


import img2 from "../../../../assets/images/New/MagTRSITeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MagTRSITeacher/info-block/info_block_img3.png";
import img1 from "../../../../assets/images/New/MagTRSITeacher/info-block/info_block_img1.png";

import img_company1 from "../../../../assets/images/New/MagIIITeacher/companies/img_company1.png";
import img_company2 from "../../../../assets/images/New/MagIIITeacher/companies/img_company2.png";
import img_company3 from "../../../../assets/images/New/MagIIITeacher/companies/img_company3.png";

import "./IIITeacherPage.css";

export default function IIITeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program magistracy teacher iii">
                    <span className="teacher__title">Инженерия искусственного интеллекта
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__main-block-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="teacher__main-block-text">
                            <span className="teacher__text">Руководитель образовательной программы</span>
                            <span className="teacher__title">Торкунова Юлия Владимировна</span>
                            <span className="teacher__text">д.п.н., доцент кафедры <br />
                                «Информационные технологии и <br />
                                интеллектуальные системы» (ИТИС)</span>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">e-mail:</span>
                                <span className="teacher__text">torkynova@mail.ru</span>
                            </div>
                            <div className="teacher__main-block-text-row">
                                <span className="teacher__text">тел.:</span>
                                <span className="teacher__text"> 8 (843) 519-43-81</span>
                            </div>
                            <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. В-604

                            </span>
                            <div className="teacher__main-block-text-row">
                                <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>
                                {/* {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                <a href={doc} target="_blank" className="teacher__button">Учебный план </a>

                            </div>
                        </div>
                        <div className="magpage_about_frame" >
                            <span className="magpage_about_programm_title">О программе</span>
                            <span className="magpage_about_programm_text">
                                Программа направлена на подготовку высококвалифицированных специалистов в области цифровых технологий в сфере инженерии искусственного интеллекта, соответствующих современным требованиям к выпускникам вуза с учетом потребностей рынка труда.
                            </span>
                        </div>
                    </div>

                    <div className="teacher__info-block">
                        <div className="info-block-frame" >
                            <span className="info_block-title">Учебные дисциплины</span>
                            <div className="info_block-text_area">
                                <span className="info_block-title2">Базовые</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Автоматизация машинного обучения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Управление проектами искусственного интеллекта</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Машинное обучение</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Компьютерное зрение</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Обработка естественного языка</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">06</span>
                                    <span className="text-line">Программная инженерия</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">07</span>
                                    <span className="text-line">Инжиниринг данных</span>
                                </div>
                                <span className="info_block-title2">Вариативные</span>
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Глубокие нейронные сети на Python</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Искусственный интеллект для информационной безопасности</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Цифровое образовательное пространство: проблемы и решения</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Цифровое моделирование в промышленности и энергетике</span>
                                </div>
                            </div>
                        </div>
                        <div className="info-block-stack">
                            <div className="info-block-frame2">
                                <span className="info_block-title">Кем я смогу работать?</span>

                                <div className="info_block-text_area">
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img1} /></div>
                                        <span className="text-line">Разработчиком бизнес-архитектуры</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img2} /></div>
                                        <span className="text-line">Разработчиком мобильных<br /> приложений</span>
                                    </div>
                                    <div className="info_block-text-row">
                                        <div className="info_block-text-row_img"><img src={img3} /></div>
                                        <span className="text-line">Руководителем IT-проектов</span>
                                    </div>
                                </div>
                            </div>

                            <div className="info-block-frame3">
                                <span className="info_block-title">Трудоустройство</span>
                                <div className="info_block-text-row">
                                    <img src={img_company1} />
                                    <img src={img_company2} />
                                </div>
                                <div className="info_block-text-row">
                                    <img src={img_company3} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <QuestionPM /> */}
                </div>
            </Container>
            <Footer />
        </React.Fragment>

    );
}