import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";

import Container from "../../components/Container";

import "./ExcursionPage.css";

export default function ExcursionPage() {
    return (
        <div className="excursion-page">
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top">
                    <span className="etap-postupleniya__title">Экскурсии в КГЭУ</span>
                </div>
                <div className="excursion-page__info-block one">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Кампус КГЭУ</span>
                        <Link to="//www.youtube.com/watch?v=DA03DUG0ZRM" className="excursion-page__info-link" target="_blank">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Научно-образовательный центр «ЭВАН»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Музей КГЭУ</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
                <div className="excursion-page__info-block">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Центр прикладных компетенций «ELECTROSKILLS»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Центр компетенции и технологии в области энергосбережения</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Лаборатория кафедры «ВОДНЫЕ БИОРЕСУРСЫ И АКВАКУЛЬТУРА»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
                <div className="excursion-page__info-block one">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Учебно-исследовательская лаборатоория BAUR</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Лаборатория отопительного оборудования «BOSCH»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Учебный полигон «ПОДСТАНЦИЯ 110/10 КВ»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
                <div className="excursion-page__info-block">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Физико-химическая лаборатория</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Оперативный пункт управления подстанцией</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Теплоснабжающий полигон ЖКХ</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
                <div className="excursion-page__info-block one">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Центр компетенций «SCHNEIDER–КГЭУ»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Лаборатория «REHAU»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                    <div className="excursion-page__info-row one">
                        <span className="excursion-page__info-text">Научно-технический центр «ДАНФОСС»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
                <div className="excursion-page__info-block">
                    <div className="excursion-page__info-row">
                        <span className="excursion-page__info-text">Научно-технический центр «АКУФЕРТРИБ»</span>
                        <Link to="/" className="excursion-page__info-link">Смотреть</Link>
                    </div>
                </div>
            </Container>

            <Footer />
        </div>

    );
}

