import React from "react";
import { Link } from "react-router-dom";

import img from "../../../assets/images/ProspectiveStudent/document.png";
import obch1 from "../../../assets/documents/Obshechitie/kvitanciya1.xls";
import obch2 from "../../../assets/documents/Obshechitie/kvitanciya2.xls";
import obch3 from "../../../assets/documents/Obshechitie/kvitanciya3.xls";
import obch4 from "../../../assets/documents/Obshechitie/kvitanciya4.xls";


import "./Dormitory.css";

export default function Dormitory() {
    return (
        <div className="dormitory">
            <span className="individual-achivments__title">Заселение в общежитие</span>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Местоположение</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">• Общежитие №1 — ул. 2-ая Юго-Западная, д. 26</span>
                    <span className="tuition-fees__text">• Общежитие №2 — ул. 2-ая Юго-Западная, д. 26а</span>
                    <span className="tuition-fees__text">• Общежитие №3 — ул. Яруллина, д. 6</span>
                    <span className="tuition-fees__text">• Общежитие №4 — ул. Красносельская, д. 51к4</span>
                </div>
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Необходимые документы для заселения первокурсников :</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">• Копии паспорта, мед. полиса, СНИЛС (для несовершеннолетних также нужна копия паспорта родителей и заявление от них);</span>
                    <span className="tuition-fees__text">• Копии мед. справки 068у, сертификата о прививках (для 1 курса);</span>
                    <span className="tuition-fees__text">• Оплаченную квитанцию за проживание (за 10 месяцев - 6 700 рублей);</span>
                    <span className="tuition-fees__text">• Оплаченную квитанцию за дополнительные услуги - коммунальные услуги (за 10 месяцев: общ. №1 и №2 -  3 800 рублей, общ. №3 и №4 - 4 800 рублей);</span>
                    <span className="tuition-fees__text">• Фотографии (3х4) - 4 штуки;</span>
                    <span className="tuition-fees__text">• Флюрография;</span>
                    <span className="tuition-fees__text">• Личные вещи (посуда и др.), постельное белье выдается в общежитии</span>
                </div>
                <img src={img} alt="" className="tuition-fees__text-block-img"/> 
            </div>
            <div className="tuition-fees__title-block">
                <span className="tuition-fees__title">Необходимые документы для заселения первокурсников иностранцев :</span>
            </div>
            <div className="tuition-fees__text-block one">
                <div className="tuition-fees__text-block-title">
                    <span className="tuition-fees__text">• копия паспорта или удостоверение личности с заверенным переводом;</span>
                    <span className="tuition-fees__text">• Копии мед. страхования и справки 068у, сертификата о прививках (для 1 курса);</span>
                    <span className="tuition-fees__text">• Оплаченную квитанцию за проживание (за 10 месяцев - 6 700 рублей);</span>
                    <span className="tuition-fees__text">• Оплаченную квитанцию за дополнительные услуги - коммунальные услуги (за 10 месяцев: общ. №1 и №2 -  3 800 рублей, общ. №3 и №4 - 4 800 рублей);</span>
                    <span className="tuition-fees__text">• Фотографии (3х4) - 4 штуки;</span>
                    <span className="tuition-fees__text">• Флюрография;</span>
                    <span className="tuition-fees__text">• Личные вещи (посуда и др.), постельное белье выдается в общежитии</span>
                </div>
            </div>
            <div className="question-content__item-text">
                <Link to={obch1} className="question-content__link-button" target="_blank">Квитанция за проживание и доп.услуги в общ. № 1</Link>
                <Link to={obch2} className="question-content__link-button" target="_blank">Квитанция за проживание и доп.услуги в общ. № 2</Link>
                <Link to={obch3} className="question-content__link-button" target="_blank">Квитанция за проживание и доп.услуги в общ. № 3</Link>
                <Link to={obch4} className="question-content__link-button" target="_blank">Квитанция за проживание и доп.услуги в общ. № 4</Link>
            </div>
        </div>
    );
}
