import React from "react";
import { Link } from "react-router-dom";

import Container from "../../Container";

// import { menuItems } from "../../Constants";

import "./LevelLearning.css";

function LevelLearning() {

    return (

        <Container>
            <div className="level-learning">
                <a href="/olimpiads" className="level-learning__link one">
                    <span className="level-learning__text">Олимпиады</span>
                </a>
                <a href="/preparatory-courses" className="level-learning__link one">
                    <span className="level-learning__text">Школьникам</span>
                </a>
                <a href="//kgeu.ru/News/Item/211/11042" className="level-learning__link" target="_blank">
                    <span className="level-learning__text">Экскурсии</span>
                </a>
                <a href="/contacts" className="level-learning__link">
                    <span className="level-learning__text">Контакты</span>
                </a>
                {/* <a href="//kgeu.ru/Section/News?idSection=1&pageNum=0" className="level-learning__link" target="_blank">
                    <span className="level-learning__text">О нас</span>
                </a> */}
                {/* <a href="//rating.kgeu.ru/" className="level-learning__link konkurs">
                    <span className="level-learning__text one">Конкурсные списки</span>
                </a> */}
            </div >

        </Container >

    );
}

export default LevelLearning;
