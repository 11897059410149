export const initialState = { visible: false };

export function modalReducer(state = initialState, action) {
    switch (action.type) {
        case 'showModal':
            return {...state, visible: true };
        case 'hideModal':
            return {...state, visible: false };
        default:
            return {...state };
    }

}