import React from "react";
import { Link } from "react-router-dom";

import "./KCP.css";

export default function KCP() {
    return (
        <div className="acceptance-checksum">
            <span className="individual-achivments__title">Контрольные цифры приема</span>
            <span className="individual-achivments__title">Бюджетная основа очная форма</span>
            <table className="scroll-table ochka">
                <tr className="acceptance-checksum__table-title-block">
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Код и наименование направления</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Основные места</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Особая квота</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Целевая квота</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Отдельная квота</span>
                    </th>
                </tr>
                {/* Прикладная математика */}
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">01.03.04</span>
                        <Link to="/applied-mathematics" className="acceptance-checksum__table-text">Прикладная математика</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">47</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">8</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">7</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">09.03.01</span>
                        <Link to="/computer-science-and-engineering" className="acceptance-checksum__table-text">Информатика<br />и вычислительная техника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">78</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">18</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">09.03.03</span>
                        <Link to="/applied-computer-science" className="acceptance-checksum__table-text">Прикладная информатика</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">52</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">8</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">8</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">7</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">11.03.04</span>
                        <Link to="/electronics-and-nanoelectronics" className="acceptance-checksum__table-text">Электроника<br />и наноэлектроника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">93</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">14</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">14</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13</span>
                    </td>
                </tr>
                {/* Приборостроение */}
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12.03.01</span>
                        <Link to="/instrumentation" className="acceptance-checksum__table-text">Приборостроение</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.01</span>
                        <Link to="/heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика<br />и теплотехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">145</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">67</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">26</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.02</span>
                        <Link to="/electric-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика<br />и электротехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">256</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">43</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">86</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">42</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.03</span>
                        <Link to="/energy-power-engineering" className="acceptance-checksum__table-text">Энергетическое машиностроение</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">14.05.02</span>
                        <Link to="/nuclear-power-plants-design-operation" className="acceptance-checksum__table-text">Атомные станции: проектирование, эксплуатация и инжиниринг</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block  two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15.03.04</span>
                        <Link to="/automation-of-technological-processes-and-productions" className="acceptance-checksum__table-text">Автоматизация технологических процессов и производств</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">36</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">6</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">6</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15.03.06</span>
                        <Link to="/mechatronics-and-robotics" className="acceptance-checksum__table-text">Мехатроника<br />и робототехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">34</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">6</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">6</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">20.03.01</span>
                        <Link to="/technosphere-safety" className="acceptance-checksum__table-text">Техносферная безопасность</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">59</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">9</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">9</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">8</span>
                    </td>
                </tr>
                {/* Материаловеденье */}
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.01</span>
                        <Link to="/materials-science-and-technology" className="acceptance-checksum__table-text">Материаловедение и<br /> технологии материалов</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">17</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27.04.04</span>
                        <Link to="/management-and-computer-science-in-the-field-of-technical-sciences" className="acceptance-checksum__table-text">Управление и информатика в технических системах</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">17</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">35.03.08</span>
                        <Link to="/aquatic-bioresources-and-aquaculture" className="acceptance-checksum__table-text">Водные биоресурсы<br />и аквакульутра</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">21</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">3</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">39.03.01</span>
                        <Link to="/sociology" className="acceptance-checksum__table-text">Социология</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">1</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">2</span>
                    </td>
                </tr>
            </table>
            <br /><br /><span className="individual-achivments__title">Бюджетная основа заочная форма</span>
            <table className="scroll-table zaoch">
                <tr className="acceptance-checksum__table-title-block">
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Код и наименование направления</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Основные места</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Особая квота</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Целевая квота</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Отдельная квота</span>
                    </th>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.01</span>
                        <Link to="/heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика<br />и теплотехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                </tr>

                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.02</span>
                        <Link to="/electric-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика<br />и электротехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">42</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">7</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">14</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">7</span>
                    </td>
                </tr>
            </table>

            <br /><br /><span className="individual-achivments__title">Платная основа</span>
            <table className="scroll-table platka">
                <tr className="acceptance-checksum__table-title-block">
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Код и наименование направления</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Очная форма</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Заочная форма</span>
                    </th>
                    <th className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-title-text">Очно-заочная форма</span>
                    </th>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">01.03.04</span>
                        <Link to="/applied-mathematics" className="acceptance-checksum__table-text">Прикладная математика</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">09.03.01</span>
                        <Link to="/computer-science-and-engineering" className="acceptance-checksum__table-text">Информатика<br />и вычислительная техника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">25</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">80</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">09.03.03</span>
                        <Link to="/applied-computer-science" className="acceptance-checksum__table-text">Прикладная информатика</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">30</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">11.03.04</span>
                        <Link to="/electronics-and-nanoelectronics" className="acceptance-checksum__table-text">Электроника<br />и наноэлектроника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">12.03.01 </span>
                        <Link to="/instrumentation" className="acceptance-checksum__table-text">Приборостроение</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.01</span>
                        <Link to="/heat-power-engineering-and-heat-engineering" className="acceptance-checksum__table-text">Теплоэнергетика<br />и теплотехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">60</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.02</span>
                        <Link to="/electric-power-and-electrical-engineering" className="acceptance-checksum__table-text">Электроэнергетика<br />и электротехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">25</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">250</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">13.03.03</span>
                        <Link to="/energy-power-engineering" className="acceptance-checksum__table-text">Энергетическое машиностроение</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">14.05.02</span>
                        <Link to="/nuclear-power-plants-design-operation" className="acceptance-checksum__table-text">Атомные станции: проектирование, эксплуатация и инжиниринг</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15.03.04</span>
                        <Link to="/automation-of-technological-processes-and-productions" className="acceptance-checksum__table-text">Автоматизация технологических процессов и производств</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">10</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">100</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15.03.06</span>
                        <Link to="/mechatronics-and-robotics" className="acceptance-checksum__table-text">Мехатроника<br />и робототехника</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">20.03.01</span>
                        <Link to="/technosphere-safety" className="acceptance-checksum__table-text">Техносферная безопасность</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">22.03.01</span>
                        <Link to="/materials-science-and-technology" className="acceptance-checksum__table-text">Материаловедение и<br /> технологии материалов</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">27.03.01</span>
                        <Link to="/management-and-computer-science-in-the-field-of-technical-sciences" 
                        className="acceptance-checksum__table-text">Управление в технических системах</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">35.03.08</span>
                        <Link to="/aquatic-bioresources-and-aquaculture" className="acceptance-checksum__table-text">Водные биоресурсы<br />и аквакульутра</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">38.03.01</span>
                        <Link to="/economy" className="acceptance-checksum__table-text">Экономика</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">130</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">75</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">38.03.02</span>
                        <Link to="/management" className="acceptance-checksum__table-text">Менеджмент</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">50</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">15</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">50</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block two">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">39.03.01</span>
                        <Link to="/sociology" className="acceptance-checksum__table-text">Социология</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">5</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">30</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
                <tr className="acceptance-checksum__table-text-block">
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">42.03.01</span>
                        <Link to="/advertising-and-public-relations" className="acceptance-checksum__table-text">Реклама и связи<br />с общественностью</Link>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">100</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">100</span>
                    </td>
                    <td className="acceptance-checksum__table-title">
                        <span className="acceptance-checksum__table-text">—</span>
                    </td>
                </tr>
            </table>
        </div>
    );
}
