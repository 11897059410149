import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import QuestionPribor from "../../../../components/ComponentsUndergraduatePage/ICTE/QuestionPribor"; //tochange questions
import Footer from "../../../../components/Footer";
import Container from "../../../../components/Container";

import img from "../../../../assets/images/EducationalPrograms/EducationalProgramsICTE/kozelkov.jpg";
import doc from "../../../../assets/documents/uch-plan/prib.pdf";

import img1 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img1.png";
import img2 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img2.png";
import img3 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img3.png";
import img4 from "../../../../assets/images/New/MechTeacher/info-block/info_block_img4.png";
import bg_left from "../../../../assets/images/New/IISTeacher/large-frame-bg1.svg";
import bg_right from "../../../../assets/images/New/IISTeacher/large-frame-bg2.svg";
import "./IISTeacherPage.css";

export default function IISTeacherPage() {

    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="educational-program teacher iis">
                    <span className="teacher__title">Интеллектуальные приборные комплексы и промышленный интернет
                    </span>
                    <div className="teacher__main-block">
                        <div className="teacher__block-title">
                            <div className="teacher__main-block-img">
                                <img src={img} alt="" />
                            </div>
                            <div className="teacher__main-block-text">
                                <span className="teacher__text">Руководитель образовательной программы</span>
                                <span className="teacher__title">Козелков Олег Владимирович</span>
                                <span className="teacher__text">к.т.н., доцент кафедры<br /> «Приборостроение и механика» (ПМ)</span>
                                <div className="teacher__main-block-text-row">
                                    <span className="teacher__text">e-mail:</span>
                                    <span className="teacher__text">ok.1972@list.ru</span>
                                </div>
                                <div className="teacher__main-block-text-row">
                                    <span className="teacher__text">тел.:</span>
                                    <span className="teacher__text">8 (843) 519-43-19</span>
                                </div>
                                <span className="teacher__text">420066, г. Казань, ул. Красносельская, 51, каб. А-314а</span>
                                <div className="teacher__main-block-text-row">
                                    <Link to="//priem.kgeu.ru/user/sign-in/login" className="teacher__button">Подать документы</Link>

                                    {/* <Link to={doc} className="teacher__button" target="_blank">Узнать подробнее</Link> */}
                                    <a href={doc} target="_blank" className="teacher__button">Учебный план </a>
                                </div>
                            </div>
                        </div>
                        {/* Блок экзамены */}
                        <div className="teacher_exams" >
                            <div className="teacher_exams-column">

                                <span className="teacher__title" >Вступительные испытания</span>

                                {/* Обязательные предметы */}
                                <div className="teacher_exams-row">
                                    <div className="teacher_exams-column">
                                        <span className="teacher__text">Обязательные предметы</span>

                                        <div className="subjects_group">
                                            <div className="subject">
                                                <span className="Math" >М</span>
                                            </div>
                                            <div className="subject">
                                                <span className="Rus">Р</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Предметы по выбору */}
                                    <div className="teacher_exams-column">
                                        <span className="teacher__text">Предметы по выбору</span>

                                        <div className="subjects_group">
                                            <div className="subject">
                                                <span className="Phys">Ф</span>
                                            </div>
                                            <div className="subject">
                                                <span className="Chem">Х</span>
                                            </div>
                                            <div className="subject">
                                                <span className="Eng">А</span>
                                            </div>
                                            <div className="subject">
                                                <span className="IT">И</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="teacher_exams-column">
                                <span className="teacher__title">Проходной балл на 2023 г.</span>
                                <span className="passing_score">—</span>
                            </div>
                        </div>
                    </div>
                    <div className="teacher__info-block">

                        {/* Что я буду изучать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Что я буду изучать?</span>

                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Математическое моделирование в приборных системах</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Анализ и синтез микропроцессорных измерительных систем</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">ПИнформационные технологии в приборостроении</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Интеллектуальные средства измерений</span>
                                </div>
                                <div className="info_block-text-row">
                                    <span className="number">05</span>
                                    <span className="text-line">Компьютерный инжиниринг</span>
                                </div>
                            </div>
                        </div>

                        {/* Кем я смогу работать? */}
                        <div className="info-block-frame" >
                            <span className="info_block-title">Кем я смогу работать?</span>
                            <div className="info_block-text_area">

                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img1} /></div>
                                    <span className="text-line">Начальником бюро технического контроля в отделах контроля качества</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img2} /></div>
                                    <span className="text-line">Робототехником</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img3} /></div>
                                    <span className="text-line">Разработчиком автоматизированных систем и средств управления</span>
                                </div>
                                <div className="info_block-text-row">
                                    <div className="info_block-text-row_img"><img src={img4} /></div>
                                    <span className="text-line">Разработчиков новых способов и методов контроля</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* Какие навыки я смогу получить? */}
                    <div className="teacher__info-block-frame-large">

                        <div className="wrap">
                            <img src={bg_left} />
                            <img src={bg_right} />
                        </div>
                        <span className="info_block-title">Какие навыки я смогу получить?</span>

                        <div className="frame-large-text-area">
                            <div className="frame-large-text-row">
                                <div className="info_block-text-row">
                                    <span className="number">01</span>
                                    <span className="text-line">Организация работы по контролю состояния оборудования технической оснастки</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">02</span>
                                    <span className="text-line">Организация и контроль работы по предотвращению выпуска бракованной продукции</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">03</span>
                                    <span className="text-line">Осуществление функционального руководства работниками бюро технического контроля</span>
                                </div>

                                <div className="info_block-text-row">
                                    <span className="number">04</span>
                                    <span className="text-line">Разработка, внедрение и контроль системы управления качеством продукции в организации</span>
                                </div>
                                <div className="info_block-text-row last">
                                    <span className="number">05</span>
                                    <span className="text-line">Производство электрооборудования, электронного и оптического оборудования (в сфере научных исследований передачи, обработки, детектирования и измерения сигналов..)</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <QuestionPribor /> */}
                </div>
            </Container >
            <Footer />
        </React.Fragment >

    );
}