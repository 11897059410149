import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../components/TopBar";
import TopBarProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../components/Footer";
import StepsProspectiveStudent from "../../components/ComponentsProspeciveStudentPage/StepsProspectiveStudent";

import Container from "../../components/Container";

import { stepsProcpectiveStudent } from "../../constants";

import "./ProspectiveStudentPage.css";

function ProspectiveStudentPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top prospective-student">
                    <span className="etap-postupleniya__title">Абитуриенту</span>
                </div>
                <div className="step-prospective-student__top">
                    <span className="etap-postupleniya__title one">Бакалавриат, специалитет</span>
                    <Link to="/prospective-student-magistracy" className="etap-postupleniya__title two">Магистратура</Link>
                </div>
            </Container>
            <StepsProspectiveStudent items={stepsProcpectiveStudent}/>
            <Footer />
        </React.Fragment>
    );
}

export default ProspectiveStudentPage;
