import React from "react";
import { Link } from "react-router-dom";

import Container from "../../Container";

// import { menuItems } from "../../Constants";

import "./TopBarProspectiveStudent.css";

export default function TopBarProspectiveStudent() {
    return (
        <div className="top-bar-prospective-student">
            <Container>
                <nav className="top-bar-prospective-student__navbar">
                    <ul className="top-bar-prospective-student__topmenu">
                        <li className="top-bar-prospective-student__menu"><Link to="/olimpiads">Олимпиады</Link></li>
                        {/* <li className="top-bar-prospective-student__menu"><Link to="/excursion">Экскурсии в КГЭУ</Link></li> */}
                        <li className="top-bar-prospective-student__menu"><Link to="/preparatory-courses">Подготовительные курсы</Link></li>
                        <li className="top-bar-prospective-student__menu"><Link to="/special-admission-conditions">Особые условия поступления</Link></li>
                        <li className="top-bar-prospective-student__menu"><Link to="/categories-of-applicants">Категории поступающих</Link></li>
                        <li className="top-bar-prospective-student__menu"><Link to="//kgeu.ru/Home/Page/211?idShablonMenu=34" target="_blank">Общежития</Link></li>
                    </ul>
                </nav>
            </Container>
        </div>

    );
}

