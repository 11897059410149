import React from "react";
import { Link } from "react-router-dom";

import TopBar from "../../../../components/TopBar";
import TopBarProspectiveStudent from "../../../../components/ComponentsProspeciveStudentPage/TopBarProspectiveStudent";
import Footer from "../../../../components/Footer";
import EducationalProgramsPE from "../../../../components/ComponentsMagistracyPage/IEE/EducationalProgramsPE";

import Container from "../../../../components/Container";

import "./PEPage.css";

export default function PEPage() {
    return (
        <React.Fragment>
            <TopBar />
            <TopBarProspectiveStudent />
            <Container>
                <div className="step-prospective-student__top magistracy abiturient">
                    <span className="etap-postupleniya__title">Магистратура</span>
                </div>
                <EducationalProgramsPE />
            </Container>
            <Footer />
        </React.Fragment>
    );
}
